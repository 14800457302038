import template from './complete-sign-up.html';
import controller from './complete-sign-up.controller';
import './complete-sign-up.scss';

const completeSignUpComponent = {
  bindings: {
    modalInstance: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$rootScope', '$window', '$timeout', 'request', 'profileService', 'utils'];

export default completeSignUpComponent;
