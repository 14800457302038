import template from './nadlan-list.html';
import controller from './nadlan-list.controller';
import './nadlan-list.scss';

const nadlanListComponent = {
  bindings: {
    type: '<?',
    filters: '<?',
    title: '<?',
    hideProjectAds: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', '$sce', 'propertyService', '$window'];

export default nadlanListComponent;
