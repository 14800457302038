import template from './payments-cards.html';
import controller from './payments-cards.controller';
import './payments-cards.scss';

const paymentsCardsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$window', '$timeout', '$uibModal', 'utils', 'paymentsService'];

export default paymentsCardsComponent;
