import _ from 'lodash';

let $rootScope;
let request;

class manageService {
  constructor (_$rootScope, _request) {
    $rootScope = _$rootScope;
    request = _request;
  }

  getAllStreets (search) {
    return request('/address/allStreets', { search: search }, 'POST')
      .then((res) => res.data.data);
  }

  getAllCities (search) {
    return request('/address/allCities', { search: search }, 'POST')
      .then((res) => res.data.data);
  }

  deleteAddress (messageId, table) {
    return request('/address/delete-address', { messageId, table }, 'POST')
      .then((res) => res.data.data);
  }

  getOrgCards (orgId) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

      return request(`/payment/get-cards-admin/${orgId}`)
      .then((res) => res.data.data);
  }

  test () {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/test')
      .then((res) => res.data.data);
  }

  deleteLockFile () {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/payment/delete-lock-file', {}, 'DELETE')
    .then((res) => res.data.data);
  }

  cLockFile () {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/payment/c-lock-file', {}, 'POST')
    .then((res) => res.data.data);
  }

  // test (Id) {
  //   if (!$rootScope.isAdmin()) {
  //     return Promise.reject(new Error('No permission'));
  //   }
  //   let body = { Id }
  //   return request('/manage/test', { body }, 'POST')
  //     .then((res) => res.data.data);
  // }

  collectDeals (propertyId) {
    return request(`/manage/property/${propertyId}/collect-ndalan-data`, null, 'POST')
      .then((res) => res.data.data);
  }

  updateEmailUser (userId, body) {
    return request('/user/email/' + userId, body, 'PUT')
      .then((res) => res.data.data);
  }

  activeCard (orgId) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/activeCard', { orgId }, 'POST')
      .then((res) => res.data.data);
  }

  blockingAlert (orgId, bool) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    let body = { orgId, bool }
    return request('/manage/blockingAlert', { body }, 'POST')
      .then((res) => res.data.data);
  }
  blocking (orgId, bool, num) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    let body = { orgId, bool, num }
    return request('/manage/blocking', { body }, 'POST')
      // .then((res) => res.data.data); 
      .then((res) => { res.data.data; });
  }

  breakEmail (orgId) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

    let body = { orgId }
    return request('/manage/breakEmail', { body }, 'POST')
      .then((res) => { res.data.data; });
  }

  endBenefit (orgId) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

    let body = { orgId }
    return request('/manage/endBenefit', { body }, 'POST')
      .then((res) => { res.data.data; });
  }
  
  ChangeVATexempt (orgId, bool) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    let body = { orgId, bool }
    return request('/manage/VAT', { body }, 'POST')
      .then((res) => res.data.data);
  }

  getManageProperties (statusFilter, searchValue, userSearchValue, queryParams) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

    return request('/manage/property?' + jQuery.param(queryParams) + (statusFilter ? '&status=' + statusFilter : '') + (searchValue ? '&q=' + searchValue : '') + (userSearchValue ? '&userSearchValue=' + userSearchValue : ''))
      .then((res) => res.data.data);
  }

  getAllManageProperties (statusFilter, searchValue, userSearchValue, queryParams) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/allProperty?' + jQuery.param(queryParams) + (statusFilter ? '&status=' + statusFilter : '') + (searchValue ? '&q=' + searchValue : '') + (userSearchValue ? '&userSearchValue=' + userSearchValue : ''))
      .then((res) => res.data.data);
  }

  getManagePropertiesCounts () {
    return request('/manage/property-counts')
      .then((res) => res.data.data);
  }

  togglePropertyPromotingBlocked (property, blocked) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/property/' + property.id + '/promoting-blocked', { blocked }, 'POST')
      .then((res) => res.data.data);
  }

  getManageProjects (statusFilter, searchValue, userSearchValue, queryParams, type) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

    return request('/manage/project?' + jQuery.param(queryParams) + ('&type=' + type) + (statusFilter ? '&status=' + statusFilter : '') + (searchValue ? '&q=' + searchValue : '') + (userSearchValue ? '&userSearchValue=' + userSearchValue : ''))
      .then((res) => res.data.data);
  }

  getManageProjectsCounts (type) {
    return request('/manage/project-counts/' + type)
      .then((res) => res.data.data);
  }

  getUserList (searchValue, queryParams = {}) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/user?' + jQuery.param(queryParams) + (searchValue ? '&q=' + searchValue : ''))
      .then((res) => res.data.data);
  }

  getProjectsLeads (filter = {}) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/projectsEvents', filter, 'POST')
      .then((res) => res.data.data);
  }

  getPlugins (searchValue, queryParams = {}, reload) {
    if (this.plugins && !reload) {
      return Promise.resolve(this.plugins);
    }

    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/plugin?' + jQuery.param(queryParams) + (searchValue ? '&q=' + searchValue : ''))
      .then((res) => {
        this.plugins = res.data.data;
        return res.data.data;
      });
  }

  setPlugin (pluginDetails = {}) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/plugin/' + (pluginDetails.id || ''), pluginDetails, (pluginDetails.id ? 'POST' : 'PUT'))
      .then((res) => res.data.data);
  }

  toggleUserPhoneBlocked (user, blocked) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/user/' + user.id + '/phone-blocked', { blocked }, 'POST')
      .then((res) => res.data.data);
  }

  toggleUserBlockingMessages (user, blocked) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/user/' + user.id + '/blocking-sending-messages', { blocked }, 'POST')
      .then((res) => res.data.data);
  }

  getMailingList () {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/mailing-list')
      .then((res) => res.data.data);
  }

  getPosts () {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/post')
      .then((res) => res.data.data);
  }

  getPluginsSettings () {
    if (this.pluginsSetting) {
      return Promise.resolve(this.pluginsSetting);
    }
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }
    return request('/manage/pluginsSettings')
      .then((res) => {
        this.pluginsSetting = res.data.data;
        return res.data.data;
      });
  }
}

manageService.$inject = ['$rootScope', 'request'];

const manageServiceModule = angular.module('manageService', [])
  .service('manageService', manageService)
  .name;

export default manageServiceModule;
