import template from './new-password.html';
import controller from './new-password.controller';
import './new-password.scss';

const newPasswordComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', 'loginService', 'utils'];

export default newPasswordComponent;
