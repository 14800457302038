import _ from 'lodash';

let request;
let $rootScope;

class planService {
  constructor (_request, _$rootScope) {
    request = _request;
    $rootScope = _$rootScope;
  }

  SendEmailJoinTrack (planDescription) {
    return request('/org/SendEmailJoinTrack', planDescription, 'POST')
      .then((res) => res.data.data);
  }

  getOrgCards () {
    return request('/payment/get-credit-cards')
      .then((res) => res.data.data);
  }
  
  setPlan (orgId, planDetails) {
    return request(`/org/${orgId}/update-plan`, { planDetails }, 'POST')
      .then((res) => {
        const orgRes = _.get(res, 'data.data');
        if (res.data.status === 'success') {
          if (orgId === $rootScope.org.id) {
            Object.assign($rootScope.org, res.data.data);
          }
          $rootScope.$broadcast('plan-changed', {
            orgId,
            planDetails: {
              name: orgRes.planName,
              description: orgRes.planDescription,
              type: orgRes.planType,
              price: orgRes.planPrice,
              maxProperties: orgRes.planMaxProperties,
              maxAllProperties:orgRes.planMaxAllProperties,
              expiry: orgRes.planExpiry
            }
          });
        }
        return res.data;
      })
      .catch(console.error);
  }
}

planService.$inject = ['request', '$rootScope'];

const planServiceModule = angular.module('planService', [])
  .service('planService', planService)
  .name;

export default planServiceModule;
