import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';

export default function ($scope, $rootScope, $state, mailingListService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class MailingListFormCtrl {
    constructor () {
      vm = this;

      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      };
    }

    $onInit () {
      vm.mailingListModel = {
        propertyCategory: [],
        frequency: 'daily'
      };
      if ($rootScope.user) {
        vm.mailingListModel.name = [$rootScope.user.firstName, $rootScope.user.lastName].join(' ');
        vm.mailingListModel.email = $rootScope.user.email;
        vm.mailingListModel.phone = $rootScope.user.phone;
        vm.mailingListModel.role = $rootScope.org.kind;
      }
      $scope.$watch('vm.mailingListModel.email', (newVal, oldVal) => {
        if (vm.mailingListForm && vm.mailingListForm.email) {
          vm.mailingListForm.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.mailingListModel.email || '');
          vm.mailingListForm.email.$setValidity('email', isValidEmail);
          $('#mailing-list-email')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });

      vm.modalInstance.closed
        .then(() => {
          if ($state.current.name === 'mailingListForm') {
            $state.go('home');
          }
        });
    }

    register () {
      utils.setFormFieldsDirty(vm.mailingListForm);

      if (vm.mailingListForm.$invalid) {
        $('#mailing-list-form')[0].reportValidity();
        return;
      }
      vm.sendProgress = true;
      const modelObject = angular.copy(vm.mailingListModel);
      modelObject.propertyCategory = (modelObject.propertyCategory || []).join(',');
      return mailingListService.register(modelObject)
        .then(() => {
          vm.mailingListModel = {
            propertyCategory: [],
            frequency: 'daily'
          };
          vm.mailingListForm.$setPristine();
          vm.modalInstance.close();
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#mailing-list-form'))
        .bind('keydown keypress', function (event) {
          if (event.which === KEY_EVENT_ENTER) {
            vm.register();
            event.preventDefault();
          }
        });
    }
  }

  return new MailingListFormCtrl();
}
