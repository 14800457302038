import angular from 'angular';
import propertiesMapComponent from './properties-map.component';
import ngMap from 'ngmap';

const propertiesMapModule = angular.module('propertiesMap', [
  ngMap
])
  .component('propertiesMap', propertiesMapComponent)
  .name;

export default propertiesMapModule;
