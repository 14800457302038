import template from './login.html';
import controller from './login.controller';
import './login.scss';

const loginComponent = {
  bindings: {},
  require: {
    loginContainerCtrl: '^loginContainer'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', 'loginService', 'utils'];

export default loginComponent;
