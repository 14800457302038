import angular from 'angular';
import mailingListFormComponent from './mailing-list-form.component';
import mailingListService from './mailing-list.service';

const mailingListFormModule = angular.module('mailingListForm', [
  mailingListService
])
  .component('mailingListForm', mailingListFormComponent)
  .name;

export default mailingListFormModule;
