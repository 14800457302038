import _ from 'lodash';
import moment from 'moment';
moment.locale('he');
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $timeout, projectService, userService, utils, orgService) {
  let vm;

  class ProjectEditController {
    constructor () {
      vm = this;

      vm.modelObject = {
        userId: _.get($rootScope, 'user.id') || '',
        publishedAt: moment().toISOString(),
        paymentExpiresAt: moment().add(3, 'months')
      };

      vm.statusOptions = options.projectStatusOptions;
      vm.address = {
        text: ''
      };

      // get orgs
      vm.getOrgs();
    }

    $onInit () {
      if (vm.project) {
        vm.modelObject = angular.copy(vm.project);
      }
      vm.address.text = vm.modelObject.address;

      if (vm.isAdmin()) {
        vm.userList = [];
        userService.getUserList()
          .then((list) => {
            vm.userList = list;
          });
      }
    }

    initAddress () {
      if (!_.get(window, 'google.maps')) { return; }

      const address = document.getElementById('address');
      const searchBox = new google.maps.places.SearchBox(address);
      searchBox.addListener('places_changed', () => {
        try {
          vm.modelObject.address = searchBox.getPlaces()[0].formatted_address;
          vm.modelObject.lat = searchBox.getPlaces()[0].geometry.location.lat();
          vm.modelObject.lon = searchBox.getPlaces()[0].geometry.location.lng();
        } catch (error) { }
        $scope.$apply();
      });
    }

    onAddressBlur () {
      if (!_.get(window, 'google.maps')) {
        vm.modelObject.address = vm.address.text;
        return;
      }

      vm.address.text = vm.modelObject.address;
    }

    saveProject () {
      if (vm.saveProgress) {
        return;
      }

      utils.setFormFieldsDirty(vm.projectEditForm);
      if (vm.projectEditForm.$invalid) {
        $('#project-edit-form')[0].reportValidity();
        return;
      }

      vm.saveProgress = true;
      const deleteFileList = _.filter(vm.modelObject.files, { deleted: true });
      if (!vm.project) {
        vm.modelObject.type = $state.params.type
      }
      const query = {
        project: JSON.stringify(vm.modelObject),
        filesToUpload: vm.filesToUpload,
        filesToDelete: JSON.stringify(deleteFileList)
      };

      return projectService.saveProject(vm.modelObject.id, query)
        .then((response) => {
          $rootScope.$broadcast('projectSaved');
          if (!$rootScope.org.planName && vm.modelObject.status === 'publish') {
            $state.go('choosePlan');
          } else {
            $state.go('manageProjects');
          }
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    publishProject () {
      vm.modelObject.status = 'publish';
      return vm.saveProject();
    }

    isAdmin () {
      return $rootScope.user.permission === 'admin';
    }

    getOrgs () {
      orgService.getOrgs()
        .then((orgs) => {
          vm.orgs = orgs.data;
        });
    }
  }

  return new ProjectEditController();
}
