import _ from 'lodash';

export default function ($scope, $state, $rootScope, $window, $timeout, request, profileService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class CompleteSignUpCtrl {
    constructor () {
      vm = this;

      vm.orgKindOptions = [
        { value: 'broker', title: 'מתווך' },
        { value: 'lender', title: 'בעל נכס' },
        { value: 'investor', title: 'משקיע' },
        { value: 'buyer', title: 'רוכש' },
        { value: 'other', title: 'אחר' }
      ];
      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      };
    }

    $onInit () {
      vm.$window = $window;
      vm.signUpFields = {};
      vm.sendProgress = false;
      $('#sign-up-phone').focus();
      $('#sign-up-form').on('keydown keypress', (event) => {
        if (event.which === KEY_EVENT_ENTER) {
          vm.send();
          event.preventDefault();
        }
      });
    }

    async send () {
      if (vm.sendProgress || !$rootScope.user.id) { return; }
      if (vm.signUpForm.$invalid) {
        $('#sign-up-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.signUpForm);
        return;
      }

      vm.sendProgress = true;
      return profileService.saveProfile($rootScope.user.id, { user: JSON.stringify(vm.signUpFields) })
        .then((result) => {
          vm.modalInstance.close();
        })
        .catch((error) => {
          // utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    logout () {
      vm.modalInstance.dismiss('cancel');
      $state.go('logout');
    }
  }

  return new CompleteSignUpCtrl();
}
