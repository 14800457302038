import _ from 'lodash';

// let $rootScope;
let request;
let fileRequest;

class projectService {
  constructor (_$rootScope, _request, _fileRequest) {
    // $rootScope = _$rootScope;
    request = _request;
    fileRequest = _fileRequest;
  }

  getProjectList (args = {}) {
    // return request('/project/' + '/?' + jQuery.param(args))
    return request('/project/')
      .then((res) => res.data.data);
  }

  getProjectHomeList () {
    return request('/project/home-list')
      .then((res) => res.data.data);
  }

  getProject (projectId) {
    return request('/project/' + projectId)
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetProject failed', error);
        return Promise.reject(error);
      });
  }

  saveProject (projectId, projectObject, publishOneSignal) {
    return fileRequest('/project/' + (projectId || '') + (publishOneSignal ? '?publishOneSignal=true' : ''), projectObject, (projectId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteProject (projectId) {
    return request('/project/' + projectId || '', null, 'DELETE')
      .then((res) => res.data.data);
  }

  phoneNumberShown (projectId) {
    return request('/project/phone-shown/' + projectId, null, 'GET', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  getMyProjects () {
    return request('/project/my')
      .then((res) => res.data.data);
  }
}

projectService.$inject = ['$rootScope', 'request', 'fileRequest'];

const projectServiceModule = angular.module('projectService', [])
  .service('projectService', projectService)
  .name;

export default projectServiceModule;
