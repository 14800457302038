import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $timeout, bannerService, userService, utils, $uibModal) {
  let vm;

  class ManageProjectsController {
    constructor () {
      vm = this;

      vm.orderByOptions = [
        { name: 'publishedAt', desc: true, label: 'פורסם לאחרונה' },
        { name: 'price', desc: false, label: 'מחיר (מהנמוך לגבוה)' },
        { name: 'price', desc: true, label: 'מחיר (מהגבוה לנמוך)' }
      ];

      vm.statusOptions = options.bannerStatusOptions;
      vm.tabsOptions = {
        active: 'באנרים פעילים',
        inactive: 'באנרים לא פעילים'
      };
    }

    $onInit () {
      vm.paging = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.searchType = 'banner';
      vm.selectedTab = $state.params.status || localStorage.getItem('manageSelectedTab');
      if (!vm.selectedTab || !Object.keys(vm.tabsOptions).includes(vm.selectedTab)) {
        vm.selectedTab = 'active';
      }
      localStorage.setItem('manageSelectedTab', vm.selectedTab);

      if (vm.selectedTab === 'active') {
        vm.statusFilter = 'published';
      } else {
        vm.statusFilter = 'draft|canceled';
      }
      vm.getManageBanners();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getManageProjects();
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getManageBanners () {
      vm.loadProgress = true;

      return Promise.all([
        bannerService.getBanners(vm.statusFilter, {
          limit: vm.paging.limit,
          offset: vm.paging.offset,
          order: vm.paging.orderBy,
          desc: vm.paging.orderDesc
        })
          .then((res) => {
            vm.list = res;
            vm.setPagingFlags();
          })
      ])
        .finally(() => {
          vm.loadProgress = false;
        });
    }

    changeSearchType (type) {
      vm.searchType = type || 'banner';
      if (vm.searchValue.length) {
        vm.doPaging(1);
      }
    }
  }

  return new ManageProjectsController();
}
