import angular from 'angular';
import OrgService from './org.service';
import OrgShow from './org-show/org-show';

const OrgModule = angular.module('org', [
  OrgService,
  OrgShow
])
  .name;

export default OrgModule;
