import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $state, $timeout, $sce, propertyService, utils) {
  let vm;

  class PropertiesHomeListController {
    constructor () {
      vm = this;
      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };
      vm.social = "עקבו אחרינו כדי להתעדכן\n על עסקאות חדשות בזמן אמת:";
      vm.listsDictionary = {
        forSale: [
          {
            title: 'נכסים מניבים למכירה',
            listName: 'producing',
            viewAllLink: 'producingList()',
            viewAllText: 'צפה בכל המניבים'
          },
          {
            title: 'נדל"ן מסחרי למכירה',
            listName: 'commerce',
            viewAllLink: 'forSaleList()',
            viewAllText: 'צפה בכל הנכסים המסחריים'
          },
          {
            title: 'פרויקטים חדשים למכירה',
            listName: 'projectsSale',
            viewAllLink: 'projectsforSaleList()',
            viewAllText: 'צפה בכל הפרויקטים החדשים '
          },
          {
            title: 'מגרשים למכירה',
            listName: 'plot',
            viewAllLink: 'forSaleList({category: "constructionPlot,agriculturalPlot"})',
            viewAllText: 'צפה בכל המגרשים'
          }
        ],
        forRent: [
          {
            title: 'נכסים מסחריים להשכרה',
            listName: 'forRent',
            viewAllLink: 'forRentList()',
            viewAllText: 'צפה בכל הנכסים להשכרה'
          },
          {
            title: 'משרדים להשכרה',
            listName: 'rentOffice',
            viewAllLink: 'forRentList({category: "office"})',
            viewAllText: 'צפה בכל המשרדים להשכרה'
          },
          {
            title: 'פרויקטים חדשים',
            listName: 'projectsRent',
            viewAllLink: null,
            viewAllText: null
          },
          {
            title: 'תעשייה להשכרה',
            listName: 'rentIndustry',
            viewAllLink: 'forRentList({category: "industry,hall,storage"})',
            viewAllText: 'לכל מבני התעשייה להשכרה'
          }
        ]
      };

      vm.homeCategoryList = options.homeCategoryList;

      vm.placeholder = $sce.trustAsHtml(createPlaceholderContent());
    }

    $onInit () {
      window.onListImageLoaded = function (img) {
        $(img).addClass('loaded');
        vm.imageListLoaded = vm.imageLoadedTimeout || $('.property img.property-image.loaded').length === $('.property img.property-image').length;
        $timeout(() => $scope.$apply());
      };
      $scope.$watch('vm.homeCtrl.type', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          vm.imageLoadedTimeout = false;
          vm.imageListLoaded = false;
          $timeout.cancel(vm.progressBlocksTimeout); // cancel the last timeout
          vm.progressBlocksTimeout = $timeout(() => {
            $scope.$apply(() => {
              vm.imageListLoaded = true;
              vm.imageLoadedTimeout = true;
            });
          }, 5000);
        }
      });

      vm.getList();
    }

    $onDestroy () {
      window.onListImageLoaded = undefined;
    }

    getList () {
      vm.getListProgress = true;
      vm.imageLoadedTimeout = false;
      vm.imageListLoaded = false;

      propertyService.getPropertyHomeList()
        .then((list) => {
          vm.list = list;
          return vm.list;
        })
        .finally(() => {
          vm.getListProgress = false;

          $timeout.cancel(vm.progressBlocksTimeout); // cancel the last timeout
          vm.progressBlocksTimeout = $timeout(() => {
            $scope.$apply(() => {
              vm.imageListLoaded = true;
              vm.imageLoadedTimeout = true;
            });
          }, 6000);
        });
    }

    getFileUrl (property, size, type) {
      if (_.get(property, 'files.length')) {
        return `${window.SERVER_URL}/file/${type}/${property.id}/${property.files[0].name}${size ? '_' + size : ''}${property.files[0].ext}`;
      }
      return 'assets/images/default.jpg';
    }

    getUserImage (userImage) {
      if (!userImage) {
        return '';
      }
      return window.SERVER_URL + userImage;
    }

    // gapList (type) {
    //   return _.times(6 - (_.get(vm, `list.${type}.length`) || 6));
    // }

    categoryLink (category, key) {
      //https://menivim.net/category/industry/
      // return $state.href(`${vm.homeCtrl.type || 'forSale'}List`, { category: category.linkParam || key });
      return $state.href('forSaleList', { category: category.linkParam || key });
    }
  }

  return new PropertiesHomeListController();
}

function createPlaceholderContent () {
  let placeholder = '';
  const placeholderContent = `
  <a class="property property-placeholder">
    <svg class="placeholder-svgstyled__PlaceholderSVG-tcbxga-0 bixdtb" viewBox="0 0 349 275" width="100%" height="100%" preserveAspectRatio="none" style="border: solid 1px #e0e0e0;">
      <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="77%"></rect>
      <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" clip-path="url(#placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="100%"></rect>
      <defs>
        <clipPath id="placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
          <rect class="placeholder-textstyled__PlaceholderTextItem-s1aiy53q-1 cvdKqV" x="138" y="231" width="181" height="24"></rect>
        </clipPath>
        <linearGradient id="placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
          <stop offset="-1.89007" stop-color="#F5F7F9" stop-opacity="1">
            <animate attributeName="offset" values="-3; 1" dur="1s" repeatCount="indefinite"></animate>
          </stop>
          <stop offset="-0.667554" stop-color="#fcfcfc" stop-opacity="1">
            <animate attributeName="offset" values="-1.5; 1.5" dur="1s" repeatCount="indefinite"></animate>
          </stop>
          <stop offset="0.109928" stop-color="#F5F7F9" stop-opacity="1">
            <animate attributeName="offset" values="-1; 3" dur="1s" repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  </a>`;
  for (let i = 0; i < 6; i++) {
    placeholder += placeholderContent;
  }

  return placeholder;
}
