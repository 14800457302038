import _ from 'lodash';

// let $rootScope;
let request;

class eventsService {
  constructor (_$rootScope, _request) {
    // $rootScope = _$rootScope;
    request = _request;
  }

  create (type, value, data = '') {
    if (!type || !value) {
      return false;
    }

    if (typeof data === 'object') {
      data = JSON.stringify(data);
    }

    const allData = {
      type, value, data
    };

    if (_.get(window, 'ga.getAll()') && window.ga.getAll()[0]) {
      allData.clientId = window.ga.getAll()[0].get('clientId');
    }

    return request('/events/create', { data: allData }, 'POST', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  getLeadsUsers (filter) {
    return request('/events/getLeadsUsers', filter, 'POST')
      .then((res) => res.data.data);
  }

  getUserLeads (userLead, properties = null, type = 'all') {
    const allData = {
      userLead,
      properties,
      type
    };

    return request('/events/getUserLeads', { data: allData }, 'POST', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }
}

eventsService.$inject = ['$rootScope', 'request'];

const eventsServiceModule = angular.module('eventsService', [])
  .service('eventsService', eventsService)
  .name;

export default eventsServiceModule;
