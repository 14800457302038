import template from './property-share.html';
import controller from './property-share.controller';
import './property-share.scss';

const propertyShareComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout'];

export default propertyShareComponent;
