function cityService () {
  const defaultTitle = '{%h1} זה מניבים ישראל – אינדקס נכסים (מתעדכן)';
  const defaultDescription = 'מאגר {%h1} בו תוכלו למצוא בקלות ובנוחות את הנכס המניב שלכם. מבחר בניינים, דירות מושכרות, חניונים, קניונים ונכסים מניבים נוספים בעיר מחכה לכם באתר, ובאמצעות חיפוש פשוט במנוע החיפוש תוכלו לאתר את אלה שמתאימים לכם. היכנסו עכשיו לאתר ומצאו את אותם נכסים מניבים ב{%city} שמתאימים לצרכים ולהעדפות שלכם >>>';

  const list = {
    'or-yehuda': { label: 'אור יהודה' },
    ashdod: { label: 'אשדוד' },
    ashkelon: { label: 'אשקלון' },
    'beer-sheva': { label: 'באר שבע' },
    'bet-shemesh': { label: 'בית שמש' },
    'bnei-brak': { label: 'בני ברק' },
    'bat-yam': { label: 'בת ים' },
    'zichron-yaakov': { label: 'זכרון יעקב' },
    hadera: { label: 'חדרה' },
    holon: { label: 'חולון' },
    haifa: { label: 'חיפה' },
    'hazor-haglilit': { label: 'חצור הגלילית' },
    tiberias: { label: 'טבריה' },
    yavne: { label: 'יבנה' },
    yuval: { label: 'יובל' },
    'yokneam-illit': { label: 'יוקנעם עילית' },
    jerusalem: { label: 'ירושלים' },
    'kfar-saba': { label: 'כפר סבא' },
    lod: { label: 'לוד' },
    'modiin-maccabim-reut': { label: 'מודיעין מכבים רעות' },
    nesher: { label: 'נשר' },
    netanya: { label: 'נתניה' },
    akko: { label: 'עכו' },
    afula: { label: 'עפולה' },
    'petah-tikva': { label: 'פתח תקווה' },
    tzfat: { label: 'צפת' },
    'kiryat-ata': { label: 'קרית אתא' },
    'kiryat-motzkin': { label: 'קרית מוצקין' },
    'rosh-pina': { label: 'ראש פינה' },
    'ramat-gan': { label: 'רמת גן' },
    'tel-aviv': {
      label: 'תל אביב'
      // title: 'נכסים מניבים בתל אביב יפו זה מניבים ישראל – אינדקס נכסים (מתעדכן)',
      // description: 'מאגר נכסים מניבים בתל אביב יפו בו תוכלו למצוא בקלות ובנוחות את הנכס המניב שלכם. מבחר בניינים, דירות מושכרות, חניונים, קניונים ונכסים מניבים נוספים בעיר מחכה לכם באתר, ובאמצעות חיפוש פשוט במנוע החיפוש תוכלו לאתר את אלה שמתאימים לכם. היכנסו עכשיו לאתר ומצאו את אותם נכסים מניבים בתל אביב יפו שמתאימים לצרכים שלכם >>>'
    }
  };

  Object.keys(list).forEach((key) => {
    if (!list[key].title) {
      list[key].title = defaultTitle.replace(/{%city}/g, list[key].label);
    }
    if (!list[key].description) {
      list[key].description = defaultDescription.replace(/{%city}/g, list[key].label);
    }
  });

  return {
    list
  };
}

const cityServiceModule = angular.module('cityService', [])
  .service('cityService', cityService)
  .name;

export default cityServiceModule;
