export default {
  propertyTypeOptions: {
    forSale: 'מכירה',
    forRent: 'השכרה'
  },
  propertyListTitleOptions: {
    forSaleList: 'מסחרי למכירה',
    forRentList: 'מסחרי להשכרה',
    producingList: 'מניבים למכירה'
  },
  propertyStatusOptions: {
    draft: { title: 'טיוטה', className: 'label-default', icon: 'fa fa-pencil-square-o' },
    pending: { title: 'ממתין לאישור', className: 'label-primary', icon: 'fa fa-clock-o' },
    publish: { title: 'פורסם', className: 'label-success', icon: 'fa fa-bullhorn fa-flip-horizontal' },
    paused: { title: 'מושהה', className: 'label-danger', icon: 'fa fa-pause-circle-o' },
    closed: { title: 'נמכר', className: 'label-info', icon: 'fa fa-lock' },
    canceled: { title: 'מבוטל', className: 'label-default', icon: 'fa fa-times-circle' },
    expired: { title: 'פג תוקף תשלום', className: 'label-danger', icon: 'fa fa-exclamation-circle' }
  },
  propertyBusinessesForSaleOptions: {
    Stores: { title: 'חנויות' },
    fashionAndClothing: { title: 'אופנה וביגוד' },
    salesBooths: { title: 'דוכני מכירה' },
    hallsAndCatering: { title: 'אולמות וקייטרינג' },
    hotelsAndZimmers: { title: 'בתי מלון וצימרים' },
    businessOffers: { title: 'הצעות עסקיות' },
    garagesAndCars: { title: 'מוסכים ורכב' },
    industryAndManufacturing: { title: 'תעשיה וייצור' },
    cafesAndRestaurants: { title: 'בתי קפה ומסעדות' },
    franchising: { title: 'זכיינות' },
    clubsAndBars: { title: 'מועדונים וברים' },
    internet: { title: 'אינטרנט' },
    entertainmentAndMusic: { title: 'בידור ומוסיקה' },
    publicRightToTaxi: { title: 'זכות ציבורית למונית' },
    beautyAndCosmetics: { title: 'יופי וקוסמטיקה' },
    minimarketSupermarket: { title: 'מינימרקט/סופרמרקט' },
    partnershipAndInvestors: { title: 'שותפות ומשקיעים' },
    divisionLines: { title: 'קווי חלוקה' },
    companiesForSale: { title: 'חברות למכירה' },
    Clinics: { title: 'קליניקות ומרפאות' },
    otherBusinesses: { title: 'עסקים אחרים' }
  },
  propertySaleCategoryOptions: {
    office: {
      title: 'משרדים',
      googleTitle: 'משרדים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש משרד למכירה? במניבים תמצא את כל סוגי המשרדים למכירה מכל רחבי הארץ. משרדים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המשרד הטוב ביותר עבורך.',
      cityTitle: 'משרדים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש משרד למכירה ב$city$? במניבים תמצא את כל סוגי המשרדים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המשרד הטוב ביותר עבורך.'
    },
    commerce: {
      title: 'מסחר',
      googleTitle: 'נדל"ן מסחרי למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש נדלן מסחרי למכירה? במניבים תמצא נדל"ן מסחרי מכל רחבי הארץ. נכסים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
      , cityTitle: 'נדל"ן מסחרי למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש נדלן מסחרי למכירה ב$city$? במניבים תמצא את כל סוגי הנדלן המסחרי למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
    },
    store: {
      title: 'חנויות',
      googleTitle: 'חנויות למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש חנות למכירה? במניבים תמצא את כל סוגי החנויות למכירה מכל רחבי הארץ. חנויות אקטואליות למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את החנות הטובה ביותר עבורך.'
      , cityTitle: 'חנויות למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש חנות למכירה ב$city$? במניבים תמצא את כל סוגי החנויות למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את החנות הטובה ביותר עבורך.'
    },
    industry: {
      title: 'מבני תעשיה',
      googleTitle: 'מבני תעשיה למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מבנה תעשיה למכירה? רוצה למצוא מרלוג למכירה? במניבים תמצא מבני תעשיה למכירה ומבחר מרלוגים למכירה מכל הארץ. מבני תעשיה אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
      , cityTitle: 'מבני תעשיה למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש מבנה תעשיה למכירה ב$city$? במניבים תמצא את כל סוגי מבני התעשיה למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
    },
    storage: {
      title: 'מחסנים',
      googleTitle: 'מחסנים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מחסן למכירה? במניבים תמצא את כל סוגי המחסנים למכירה מכל רחבי הארץ. מרלוגים למכירה ומבחר מחסנים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המחסן הטוב ביותר עבורך.'
      , cityTitle: 'מחסנים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש מחסן למכירה ב$city$? במניבים תמצא את כל סוגי המחסנים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המחסן הטוב ביותר עבורך.'
    },
    constructionPlot: {
      title: 'מגרשים לבניה',
      googleTitle: 'מגרשים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מגרש למכירה? במניבים תמצא את כל סוגי המגרשים למכירה מכל רחבי הארץ. מגרשים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.'
      , cityTitle: 'מגרשים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש מגרש למכירה ב$city$? במניבים תמצא את כל סוגי המגרשים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.'
    },
    agriculturalPlot: {
      title: 'מגרשים חקלאיים',
      googleTitle: 'מגרשים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מגרש למכירה? במניבים תמצא את כל סוגי המגרשים למכירה מכל רחבי הארץ. מגרשים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.'
      , cityTitle: 'מגרשים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש מגרש למכירה ב$city$? במניבים תמצא את כל סוגי המגרשים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.'
    },
    clinics: {
      title: 'קליניקות',
      googleTitle: 'קליניקות למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש קליניקה למכירה? במניבים תמצא את כל סוגי הקליניקות למכירה מכל רחבי הארץ. קלינקות אקטואליות למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הקליניקה הטובה ביותר עבורך.',
      cityTitle: 'קליניקות למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש קליניקה למכירה ב$city$? במניבים תמצא את כל סוגי הקליניקות למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הקליניקה הטובה ביותר עבורך.'
    },
    residentialBuilding: {
      title: 'בנין מגורים',
      googleTitle: 'בנייני מגורים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש בניין מגורים למכירה? במניבים תמצא בנייני מגורים מכל רחבי הארץ. מבנים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המבנה הטוב ביותר עבורך.',
      cityTitle: 'בנייני מגורים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש בניין מגורים למכירה ב$city$? במניבים תמצא מגוון בנייני מגורים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את בניין המגורים הטוב ביותר עבורך.'
    },
    apartmentsInvestment: {
      title: 'דירות להשקעה',
      googleTitle: 'דירות להשקעה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש דירה להשקעה? במניבים תמצא את כל סוגי הדירות להשקעה מכל רחבי הארץ. דירות השקעה אקטואליות למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את ההשקעה הטובה ביותר עבורך.',
      cityTitle: 'דירות להשקעה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש דירה להשקעה ב$city$? במניבים תמצא את כל סוגי הדירות להשקעה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את ההשקעה הטובה ביותר עבורך.'
    },
    hotel: {
      title: 'בתי מלון',
      googleTitle: 'בתי מלון למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש בית מלון למכירה? במניבים תמצא את כל סוגי המלונות למכירה מכל רחבי הארץ. בתי מלון אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.',
      cityTitle: 'בתי מלון למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש בית מלון למכירה ב$city$? במניבים תמצא את כל סוגי בתי המלון למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את בית המלון הטוב ביותר עבורך.'
    },
    parking: {
      title: 'חניון',
      googleTitle: 'חניונים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש חניון למכירה? במניבים תמצא את כל סוגי החניונים למכירה מכל רחבי הארץ. חניונים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את החניון הטוב ביותר עבורך.',
      cityTitle: 'חניונים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש חניון למכירה ב$city$? במניבים תמצא את כל סוגי החניונים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את החניון הטוב ביותר עבורך.'
    },
    vacation: {
      title: 'צימרים',
      googleTitle: 'צימרים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש צימר למכירה? במניבים תמצא את כל סוגי הצימרים למכירה מכל רחבי הארץ. צימרים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הצימר הטוב ביותר עבורך.'
      , cityTitle: 'צימרים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש צימר למכירה ב$city$? במניבים תמצא את כל סוגי הצימרים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הצימר הטוב ביותר עבורך.'
    },
    gasStation: {
      title: 'תחנות דלק',
      googleTitle: 'תחנות דלק למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש תחנת דלק למכירה? במניבים תמצא את כל סוגי תחנות הדלק למכירה מכל רחבי הארץ. תחנות דלק אקטואליות למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את התחחנת דלק הטובה ביותר עבורך.',
      cityTitle: 'תחנות דלק למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש תחנת דלק למכירה ב$city$? במניבים תמצא את כל סוגי תחנות הדלק למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את תחנת הדלק הטובה ביותר עבורך.'
    },
    nursingHome: {
      title: 'בית אבות/דיור מוגן',
      googleTitle: 'בתי אבות למכירה, דיור מוגן למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש בית אבות למכירה? דיור מוגן למכירה? במניבים תמצא בתי אבות למכירה, דיור מוגן מכל רחבי הארץ. בתי אבות, דיור מוגן אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הבית אבות והדיור מוגן הטוב ביותר עבורך.',
      cityTitle: 'בתי אבות למכירה, דיור מוגן למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש בית אבות למכירה, דיור מוגן ב$city$? במניבים תמצא את כל סוגי בתי האבות, דיור מוגן למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את בית האבות, והדיור מוגן הטוב ביותר עבורך.'
    },
    businessesForSale: {
      title: 'עסקים למכירה',
      googleTitle: 'עסקים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש עסק למכירה? במניבים תמצא את כל סוגי העסקים למכירה מכל רחבי הארץ. עסקים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את העסק הטוב ביותר עבורך.'
      , cityTitle: 'עסקים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      , cityDescription: 'מחפש עסק למכירה ב$city$? במניבים תמצא את כל סוגי העסקים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
    },
    constructionPlot: {
      title: 'מגרשים לבניה',
      googleTitle: 'מגרשים לבניה למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מגרש לבניה למכירה? במניבים תמצא את כל סוגי המגרשים לבנייה למכירה מכל רחבי הארץ. מגרשים לבניה אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המגרש לבניה הטוב ביותר עבורך.',
      cityTitle: 'מגרשים לבניה למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מגרש לבניה למכירה ב$city$? במניבים תמצא את כל סוגי המגרשים לבניה למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המגש לבניה הטוב ביותר עבורך.'
    },
    agriculturalPlot: {
      title: 'מגרשים חקלאיים',
      googleTitle: 'מגרשים חקלאיים למכירה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מגרש חקלאי למכירה? במניבים תמצא מבחר מגרשים חקלאיים למכירה מכל רחבי הארץ. מגרשים חקלאיים אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המגרש החקלאי הטוב ביותר עבורך.',
      cityTitle: 'מגשרים חקלאיים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מגרש חקלאי למכירה ב$city$? במניבים תמצא את כל סוגי המגרשים החלקאיים למכירה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המגרש החקלאי הטוב ביותר עבורך.'
    }
  },
  propertyRentCategoryOptions: {
    office: {
      title: 'משרדים',
      googleTitle: 'משרדים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש משרד להשכרה? במניבים תמצא את כל סוגי המשרדים להשכרה מכל רחבי הארץ. משרדים אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המשרד הטוב ביותר עבורך.',
      cityTitle: 'משרדים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש משרד להשכרה ב$city$? במניבים תמצא את כל סוגי המשרדים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המשרד הטוב ביותר עבורך.'
    },
    workSpace: {
      title: 'חלל עבודה משותף',
      googleTitle: 'חללי עבודה משותפים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש חלל עבודה משותף להשכרה? במניבים תמצא את כל סוגי חללי העבודה המשותפים להשכרה מכל רחבי הארץ. חללי עבודה אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את החלל עבודה הטוב ביותר עבורך.',
      cityTitle: 'חללי עבודה משותפים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש חלל עבודה משותף להשכרה ב$city$? במניבים תמצא את כל סוגי חללי העבודה המשותפים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את החלל עבודה הטוב ביותר עבורך.'
    },
    commerce: {
      title: 'מסחר',
      googleTitle: 'נדל"ן מסחרי להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש נדלן מסחרי להשכרה? במניבים תמצא נדלן מסחרי להשכרה מכל הארץ. נדלן מסחרי להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.',
      cityTitle: 'נדל"ן מסחרי להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש נדלן מסחרי להשכרה ב$city$? במניבים תמצא את כל סוגי הנדלן המסחרי להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
    },
    store: {
      title: 'חנויות',
      googleTitle: 'חנויות להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש חנות להשכרה? במניבים תמצא את כל סוגי החנויות להשכרה מכל רחבי הארץ. חנויות אקטואליות להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את החנות הטובה ביותר עבורך.',
      cityTitle: 'חנויות להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש חנות להשכרה ב$city$? במניבים תמצא את כל סוגי החנויות להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את החנות הטובה ביותר עבורך.'
    },
    industry: {
      title: 'מבני תעשיה',
      googleTitle: 'מבני תעשיה להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מבנה תעשיה להשכרה? במניבים תמצא את כמבני תעשיה להשכרה מכל הארץ. מבני תעשיה להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המבנה הטוב ביותר עבורך.',
      cityTitle: 'מבני תעשיה להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מבנה תעשיה להשכרה ב$city$? במניבים תמצא את כל סוגי מבני התעשיה להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.'
    },
    storage: {
      title: 'מחסנים',
      googleTitle: 'מחסנים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מחסן להשכרה? במניבים תמצא את כל סוגי המחסנים להשכרה מכל רחבי הארץ. מחסנים אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המחסן הטוב ביותר עבורך.',
      cityTitle: 'מחסנים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מחסן להשכרה ב$city$? במניבים תמצא את כל סוגי המחסנים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המחסן הטוב ביותר עבורך.'
    },
    hall: {
      title: 'אולמות',
      googleTitle: 'אולמות להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש אולם להשכרה? במניבים תמצא את כל סוגי האולמות להשכרה מכל רחבי הארץ. אולמות אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את האולם הטוב ביותר עבורך.',
      cityTitle: 'אולמות להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש אולם להשכרה ב$city$? במניבים תמצא את כל סוגי האולמות להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את האולם הטוב ביותר עבורך.'
    },
    clinics: {
      title: 'קליניקות',
      googleTitle: 'קליניקות להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש קליניקה להשכרה? במניבים תמצא קליניקות להשכרה מכל רחבי הארץ. קליניקות אקטואליות להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הקליניקה הטובה ביותר עבורך.',
      cityTitle: 'קליניקות להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש קליניקה להשכרה ב$city$? במניבים תמצא את כל סוגי הקליניקות להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את הקליניקה הטוב ביותר עבורך.'
    },
    parking: {
      title: 'חניון',
      googleTitle: 'חניונים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש חניון להשכרה? במניבים תמצא את כל סוגי החניונים להשכרה מכל רחבי הארץ. חניונים אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את החניון הטוב ביותר עבורך.',
      cityTitle: 'חניונים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש חניון להשכרה ב$city$? במניבים תמצא את כל סוגי החניונים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את החניון הטוב ביותר עבורך.'
    },
    gasStation: {
      title: 'תחנות דלק',
      googleTitle: 'תחנות דלק להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש תחנת דלק להשכרה? במניבים תמצא את כל סוגי תחנות הדלק להשכרה מכל רחבי הארץ. תחנות דלק אקטואליות להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את תחנת הדלק הטובה ביותר עבורך.',
      cityTitle: 'תחנות דלק להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש תחנת דלק להשכרה ב$city$? במניבים תמצא את כל סוגי תחנות הדלק להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את תחנת הדלק הטוב ביותר עבורך.'
    },
    vacation: {
      title: 'בתי מלון',
      googleTitle: 'בתי מלון להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש בית מלון להשכרה? במניבים תמצא את כל סוגי המלונות להשכרה מכל רחבי הארץ. בתי מלון אקטואליים למכירה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את הנכס הטוב ביותר עבורך.',
      cityTitle: 'בתי מלון להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש בית מלון להשכרה ב$city$? במניבים תמצא את כל סוגי בתי המלון להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את בית המלון הטוב ביותר עבורך.'
    },
    plot: {
      title: 'מגרשים',
      googleTitle: 'מגרשים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מגרש להשכרה? במניבים תמצא את כל סוגי המגרשים להשכרה מכל רחבי הארץ. מגרשים אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.',
      cityTitle: 'מגרשים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מגרש להשכרה ב$city$? במניבים תמצא את כל סוגי המגרשים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המגרש הטוב ביותר עבורך.'
    },
    general: {
      title: 'מבנים כלליים',
      googleTitle: 'מבנים להשכרה - מניבים. הלוח לנדל"ן מסחרי',
      description: 'מחפש מבנה להשכרה? במניבים תמצא את כל סוגי המבנים להשכרה מכל רחבי הארץ. מבנים אקטואליים להשכרה עם כל הפרטים הרלוונטים, כדי שתוכל לבחור את המבנה הטוב ביותר עבורך.',
      cityTitle: 'מבנים להשכרה ב$city$ - מניבים. הלוח לנדל"ן מסחרי',
      cityDescription: 'מחפש מבנה להשכרה ב$city$? במניבים תמצא את כל סוגי המבנים להשכרה ב$city$ עם כל פרטי הנכס הרלוונטים, כדי שתוכל לבחור את המבנה הטוב ביותר עבורך.'
    },
  },
  propertySaleCategoryOptionsNadlan: {
    office: {
      title: 'משרדים',
      googleTitle: 'מידע נדל"ן משרדים (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של משרדים, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של משרד לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל המשרדים בישראל למכירה ולהשכרה.'
    },
    commerce: {
      title: 'מסחר',
      googleTitle: 'מידע נדל"ן שטח מסחרי (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של שטחי מסחר, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של שטח מסחרי לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל שטחי המסחר בישראל למכירה ולהשכרה.'
    },
    store: {
      title: 'חנויות',
      googleTitle: 'מידע נדל"ן חנויות (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של חנויות, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של חנות לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל החנויות בישראל למכירה ולהשכרה.'
    },
    industry: {
      title: 'מבני תעשיה',
      googleTitle: 'מידע נדל"ן מבני תעשיה (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של מבני תעשיה, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של מבנה תעשיה לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל מבני התעשיה בישראל למכירה ולהשכרה.'
    },
    storage: {
      title: 'מחסנים',
      googleTitle: 'מידע נדל"ן מחסנים (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של מחסנים, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של מחסן לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל המחסנים בישראל למכירה ולהשכרה.'
    },
    constructionPlot: {
      title: 'מגרשים לבניה',
      googleTitle: 'מידע נדל"ן מגרשים לבניה (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של מגרשים לבניה, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של מגרש לבניה לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל המגרשים לבניה בישראל למכירה ולהשכרה.'
    },
    agriculturalPlot: {
      title: 'מגרשים חקלאיים',
      googleTitle: 'מידע נדל"ן מגרשים חקלאיים (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של מגרשים חקלאיים, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של מגרש חקלאי לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל המגרשים החקלאיים בישראל למכירה ולהשכרה.'
    },
    hotel: {
      title: 'בתי מלון',
      googleTitle: 'מידע נדל"ן בתי מלון (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של בתי מלון, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של בית מלון לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל בתי המלון בישראל למכירה ולהשכרה.'
    },
    parking: {
      title: 'חניון',
      googleTitle: 'מידע נדל"ן חניונים (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של חניונים, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של חניון לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל החניונים בישראל למכירה ולהשכרה.'
    },
    // vacation: {
    //   title: 'צימרים',
    //   googleTitle: 'מידע נדל"ן צימרים (2024) - מניבים הלוח לנדל"ן מסחרי',
    //   description: 'מידע נדל"ן של צימרים, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של צימר לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל הצימרים בישראל למכירה ולהשכרה.'
    // },
    gasStation: {
      title: 'תחנות דלק',
      googleTitle: 'מידע נדל"ן תחנות דלק (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של תחנות דלק, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של תחנת דלק לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל תחנות הדלק בישראל למכירה ולהשכרה.'
    },
    nursingHome: {
      title: 'בית אבות/דיור מוגן',
      googleTitle: 'מידע נדל"ן בית אבות, דיור מוגן (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של בתי אבות, דיור מוגן, על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של בית אבות או דיור מוגן לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל בתי האבות והדיור המוגן בישראל למכירה ולהשכרה.'
    },
    plot: {
      title: 'מגרשים',
      googleTitle: 'מידע נדל"ן מגרשים (2024) - מניבים הלוח לנדל"ן מסחרי',
      description: 'מידע נדל"ן של מגרשים , על בסיס עסקאות שנסגרו. המידע מתעדכן מדי יום. רוצים לדעת מהו השווי של מגרש לפני שאתם מבצעים רכישה? אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל המגרשים בישראל למכירה ולהשכרה.'
    }
  },
  propertyRentCategoryOptionsNadlan: {
    office: { title: 'משרדים' },
    commerce: { title: 'מסחר' },
    store: { title: 'חנויות' },
    industry: { title: 'מבני תעשיה' },
    storage: { title: 'מחסנים' },
    parking: { title: 'חניון' },
    gasStation: { title: 'תחנות דלק' },
    plot: { title: 'מגרשים' },
  },
  yieldFilterOptions: [
    { value: '', label: 'הכל' },
    { value: 'lte,5', label: 'עד 5%' },
    { value: 'gte,5', label: 'מ- 5% ומעלה' },
    { value: 'gte,6', label: 'מ- 6% ומעלה' },
    { value: 'gte,7', label: 'מ- 7% ומעלה' },
    { value: 'gte,8', label: 'מ- 8% ומעלה' },
    { value: 'gte,10', label: 'מ- 10% ומעלה' }
  ],
  propertyIntegrityOptions: {
    built : "בהקמה",
    new: 'חדש',
    renovated: 'משופץ',
    saved: 'שמור',
    old: 'ישן',
    needsRenovation: 'טעון שיפוץ',
    casing: 'מעטפת'
  },
  propertyApartmentsInvestmentOptions: {
    apartment: 'דירה',
    gardenApartment: 'דירת גן',
    penthouse: 'פנטהאוז',
    cottageOrPrivate: "קוטג'/פרטי",
    townhouse: 'דו משפחתי',
    duplex: 'דופלקס',
    dividedApartment: 'דירה מחולקת',
    studio: 'סטודיו',
    farmArea: 'משק'
  },
  propertyPlotPurposeOptions: {
    beforeTaba: 'לפני תב"ע',
    tabaApproved: 'תב"ע מאושרת',
    inCommittees: 'בוועדות',
    withPermits: 'עם היתרים',
    underConstruction: 'בבנייה'
  },
  propertyOwnershipTypeOptions: {
    privateTabu: 'טאבו פרטי',
    minhal: 'מנהל',
    other: 'אחר'
  },
  homeCategoryList: {
    office: { title: 'משרדים למכירה', image: 'assets/images/office.jpeg', link: '/category/office/' },
    rentOffice: { title: 'משרדים להשכרה', image: 'assets/images/rentOffice.jpeg', link: '/rent-category/office/' },
    store: { title: 'חנויות למכירה', image: 'assets/images/store.jpeg', link: '/category/store/' },
    rentStore: { title: 'חנויות להשכרה', image: 'assets/images/rentStore.jpeg', link: '/rent-category/store/' },
    storage: { title: 'מחסנים למכירה', image: 'assets/images/storage.jpeg', link: '/category/storage/' },
    rentStorage: { title: 'מחסנים להשכרה', image: 'assets/images/rentStorage.jpeg', link: '/rent-category/storage/' },
    plot: { title: 'מגרשים למכירה', image: 'assets/images/plot.jpeg', link: '/category/plot/' },
    businessesForSale: { title: 'עסקים למכירה', image: 'assets/images/businessesForSale.jpeg', link: '/category/businessesForSale/' },
  },
  rentCheckboxFields: {
    airConditioner: { label: 'מזגן' },
    terrace: { label: 'מרפסת' },
    yard: { label: 'חצר' },
    elevators: { label: 'מעלית' },
    closeParking: { label: 'חניה' },
    furnished: { label: 'ריהוט' },
    storage: { label: 'מחסן' },
    toilet: { label: 'שירותים' },
    kitchenette: { label: 'מטבחון' }
  },
  businessesForSaleCheckboxFields: {
    airConditioner: { label: 'מזגן' },
    storage: { label: 'מחסן' },
    toilet: { label: 'שירותים' },
    kitchenette: { label: 'מטבח' },
    mmad: { label: 'ממ"ד' },
    water: { label: 'מים' },
    gas: { label: 'גז' },
    electricity: { label: 'חשמל' },
    showcase: { label: 'חלון ראווה' },
    entranceFromStreet: { label: 'כניסה מהרחוב' },
    closeParking: { label: 'חניית לקוחות' },
    accessDisabled: { label: 'גישה לנכים' },
    furnished: { label: 'ריהוט' }
  },
  rentSpaceCheckboxFields: {
    airConditioner: { label: 'מיזוג' },
    toilet: { label: 'שירותים' },
    mmad: { label: 'ממ"ד' },
    bars: { label: 'סורגים' },
    elevators: { label: 'מעלית' },
    terrace: { label: 'מרפסת' },
    accessDisabled: { label: 'גישה לנכים' },
    kitchenette: { label: 'מטבחון' },
    privateEntrance: { label: 'כניסה פרטית' },
    meetingRoom: { label: 'חדר ישיבות' },
    printingServices: { label: 'שירותי הדפסה' },
    staffComplex: { label: 'צוות במתחם' },
    swimmingPool: { label: 'בריכת שחיה' },
    gym: { label: 'חדר כושר' },
    coffeeTea: { label: 'קפה ותה' },
    showers: { label: 'מקלחות' }
  },
  projectStatusOptions: {
    draft: { title: 'טיוטה', className: 'label-default', icon: 'fa fa-pencil-square-o' },
    publish: { title: 'פורסם', className: 'label-success', icon: 'fa fa-bullhorn fa-flip-horizontal' },
    paused: { title: 'מושהה', className: 'label-danger', icon: 'fa fa-pause-circle-o' },
    closed: { title: 'נמכר', className: 'label-info', icon: 'fa fa-lock' },
    canceled: { title: 'מבוטל', className: 'label-default', icon: 'fa fa-times-circle' },
    expired: { title: 'פג תוקף תשלום', className: 'label-danger', icon: 'fa fa-exclamation-circle' }
  },
  bannerStatusOptions: {
    draft: { title: 'טיוטה', className: 'label-success', icon: 'fa fa-pencil-square-o' },
    published: { title: 'פורסם', className: 'label-danger', icon: 'fa fa-bullhorn fa-flip-horizontal' },
    canceled: { title: 'מבוטל', className: 'label-danger', icon: 'fa fa-times-circle' }
  },
  planDescriptions: {
    Basic: { label: 'מנוי בסיסי' },
    Business: { label: 'מנוי עסקי' },
    Premium: { label: 'מנוי פרימיום' },
    vip: { label: 'vip מנוי' },
    custom: { label: 'מותאם אישית' }
  }
};
