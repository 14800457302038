import template from './side-menu.html';
import controller from './side-menu.controller';
import './side-menu.scss';

const sideMenuComponent = {
  bindings: {
    fixed: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$uibModal', 'mailingListService', 'messagesService', 'categoryService'];

export default sideMenuComponent;
