import template from './manage-properties.html';
import controller from './manage-properties.controller';
import './manage-properties.scss';

const ManagePropertiesComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'propertyService', 'userService', 'manageService', 'utils', '$uibModal'];

export default ManagePropertiesComponent;
