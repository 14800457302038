import angular from 'angular';
import pageComponent from './page.component';
import about from './about/about';
import p404 from './p404/p404';
import propertyNotAvailable from './property-not-available/property-not-available';
import terms from './terms/terms';
import siteMap from './siteMap/siteMap';

const pageModule = angular.module('page', [
  about,
  p404,
  propertyNotAvailable,
  terms,
  siteMap
])
  .component('page', pageComponent)
  .name;

export default pageModule;
