import angular from 'angular';

import appConfig from './app.config';
import AppComponent from './app.component';
import Common from './common/common';
import Components from './components/components';
import Directives from './directives/directives';
import Filters from './filters/filters';
import Layouts from './layouts/layouts';
import Services from './services/services';
import Vendors from './app.vendors';
import '../assets/pwa';

angular.module('menivim', [
  Common,
  Components,
  Directives,
  Filters,
  Layouts,
  Services,
  Vendors
])
  .config(appConfig)
  .component('app', AppComponent)
  // close any modal-box when navigating back in history.
  .run(['$transitions', '$uibModalStack', ($transitions, $uibModalStack) => {
    $transitions.onBefore({}, (transition) => {
      const top = $uibModalStack.getTop();
      if (top) {
        $uibModalStack.dismiss(top.key);
        console.log('Open modal-nox found, transition ignored');
        return false;
      }
    });
  }]);
