import angular from 'angular';
import uiRouter from 'angular-ui-router';

import paymentsComponent from './payments.component';
import paymentsService from './payments.service';
import paymentsCardComponent from './payments-cards/payments-cards';
import paymentsTransactions from './payments-transactions/payments-transactions';
import paymentsPlans from './payments-plans/payments-plans';

const paymentsModule = angular.module('payments', [
  uiRouter,
  paymentsService,
  paymentsCardComponent,
  paymentsTransactions,
  paymentsPlans
])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('payments.plans', {
        url: '/plans',
        component: 'paymentsPlans'
      })
      .state('payments.transactions', {
        url: '/history',
        component: 'paymentsTransactions'
      })
      .state('payments.cards', {
        url: '/cards?ResponseCode&Status&Operation',
        component: 'paymentsCards'
      });
  }])

  .component('payments', paymentsComponent)
  .name;

export default paymentsModule;
