import angular from 'angular';
import fileRequest from './file-request';
import page from './page';
import request from './request';
import sweetAlert from './sweet-alert';
import utils from './utils';

const servicesModule = angular.module('app.services', [
  fileRequest,
  page,
  request,
  sweetAlert,
  utils
])
  .name;

export default servicesModule;
