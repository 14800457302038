import template from './project-files-edit.html';
import controller from './project-files-edit.controller';
import './project-files-edit.scss';

const projectFilesEditComponent = {
  require: {
    projectEditCtrl: '^projectEdit'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'utils'];

export default projectFilesEditComponent;
