import jquery from 'jquery';
// import _ from 'lodash';

// let service;
let request;

class savedSearchesService {
  constructor (_request) {
    request = _request;
  }

  getSavedSearches (queryParams) {
    return request('/saved-searches?' + (queryParams ? jquery.param(queryParams) : ''))
      .then((res) => res.data.data);
  }

  saveSearch (type, filters) {
    return request('/saved-searches', { type, filters }, 'POST')
      .then((res) => res.data.data);
  }

  deleteSavedSearch (savedSearchId) {
    return request('/saved-searches/' + savedSearchId, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

savedSearchesService.$inject = ['request'];

const savedSearchesServiceModule = angular.module('savedSearchesService', [])
  .service('savedSearchesService', savedSearchesService)
  .name;

export default savedSearchesServiceModule;
