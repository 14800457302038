import angular from 'angular';
import Footer from './footer/footer';
import Header from './header/header';
import SideMenu from './side-menu/side-menu';
import SelectPropertyCategory from './select-property-category/select-property-category';
import autocompleteGov from './autocomplete-gov/autocomplete-gov';

import './margins.css';
import './onesignal-rtl.scss';
import './status-icon.scss';

const commonModule = angular.module('app.common', [
  Footer,
  Header,
  SideMenu,
  SelectPropertyCategory,
  autocompleteGov
])
  .name;

export default commonModule;
