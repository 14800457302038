import template from './plan-chooser.html';
import controller from './plan-chooser.controller';
import './plan-chooser.scss';

const planChooserComponent = {
  bindings: {
    isNotSelected: '@isNotSelected'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope','propertyService', 'planService', 'paymentsService', 'utils', '$state', '$timeout', 'eventsService'];

export default planChooserComponent;
