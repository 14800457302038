import angular from 'angular';

import savedPropertiesComponent from './saved-properties.component';
import savedPropertiesService from './saved-properties.service';

const savedPropertiesModule = angular.module('savedProperties', [
  savedPropertiesService
])
  .component('savedProperties', savedPropertiesComponent)
  .name;

export default savedPropertiesModule;
