
export default function ($scope, $state, $timeout, request) {
  let vm;

  class deleteItem {
    constructor (_fileRequest) {
      vm = this;
      vm.fileRequest = _fileRequest;
    }

    $onInit () {
      vm.Id = $state.params.ID;
      if (!vm.Id) {
        $state.go('home');
        return;
      }
      const query = { property: '{"status":"closed"}' }
      vm.saveProperty(vm.Id, query).then(r => {
        if (r) {
          vm.successMessage = true;
          $timeout(() => {
            $state.go('home');
          }, 3000);
        }
        else {
          alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home')
        }
      })
        .catch(error => { alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home') });
    }

    saveProperty (propertyId, propertyObject, publishOneSignal) {
      return request('/property/' + (propertyId || '') + (publishOneSignal ? '?publishOneSignal=true' : ''), propertyObject, (propertyId ? 'PUT' : 'POST'))
        .then((res) => res.data.data);
    }
  }
  return new deleteItem();
}
