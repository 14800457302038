import template from './page.html';
import controller from './page.controller';
import './page.scss';

const pageComponent = {
  transclude: {
    title: '?pageTitle'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils'];

export default pageComponent;
