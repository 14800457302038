// import _ from 'lodash';
import options from '../../../../common/options';

export default function ($scope, $state, $timeout, propertyNadlanService, $window) {
  let vm;

  class PropertyNadlanController {
    constructor () {
      vm = this;
      vm.limit = 6;
      vm.propertyCategoryOptions = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions
      };
    }

    async $onInit () {
      if (vm.property.nadlanProperties) {
        vm.getDeals();
      }
      else {
        vm.getMenivimDeals();
      }

      const mobile = $window.matchMedia('screen and (max-width: 800px)');
      vm.mobile = mobile.matches;
      addEventListener("resize", (event) => {
        const mobile = $window.matchMedia('screen and (max-width: 800px)');
        vm.mobile = mobile.matches;
        if (!$scope.$$phase) {
          $scope.$apply()
        }
      });
    }

    getMenivimDeals () {
      vm.loading = true;
      return propertyNadlanService.getMenivimDeals(vm.property.id)
        .then((response) => {
          if (response) {
            response.sort(function (b, a) {
              var c = new Date(a.publishedAt);
              var d = new Date(b.publishedAt);
              return c - d;
            });
            let filter = response.map(item => {
              return {
                DEALDATE: item.publishedAt,
                ADDRESS: item.address,
                ASSETTYPE: vm.propertyCategoryOptions[item.type][item.category].title,
                ASSETMETER: item.squareMeter,
                pricePerMeter: item.squareMeterPrice,
                DEALS_DEALAMOUNT: item.price
              };
            });
            vm.Menivim = filter ? true : null;
            vm.Api = vm.list ? true : null;
            vm.list = vm.list ? vm.list.concat(filter) : filter;
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    getDeals () {
      vm.loading = true;
      return propertyNadlanService.getDeals(vm.property.id)
        .then((response) => {
          if (response) {
            vm.list = response;
          }
          vm.getMenivimDeals();
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    getFilterPricePerMeter (property) {
      if (property.ASSETTYPE === 'משרד' || property.ASSETTYPE === 'משרדים') {
        if (property.pricePerMeter < 2000 || property.pricePerMeter > 55000) {
          return false
        }
      }
      if (property.ASSETTYPE === 'חנות' || property.ASSETTYPE === 'חנויות') {
        if (property.pricePerMeter < 4000 || property.pricePerMeter > 200000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'תעשיה' || property.ASSETTYPE === 'מחסנים' || property.ASSETTYPE === 'מלאכה' || property.ASSETTYPE === 'מבני תעשיה') {
        if (property.pricePerMeter < 2000 || property.pricePerMeter > 35000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'קרקע למגורים' || property.ASSETTYPE === 'מגרשים לבניה') {
        if (property.pricePerMeter < 300 || property.pricePerMeter > 150000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'מגרשים חקלאיים') {
        if (property.pricePerMeter < 30 || property.pricePerMeter > 40000) {
          return false
        }
      }
      return true
    }

    collectDeals () {
      return propertyNadlanService.collectDeals(vm.property.id).then(r => alert('סיום ריצה', r));
    }
  }

  return new PropertyNadlanController();
}
