import angular from 'angular';
import propertyShowComponent from './property-show.component';
import propertyMistakeReport from './property-mistake-report/property-mistake-report';
import propertyNadlan from './property-nadlan/property-nadlan';
import propertySendMessage from './property-send-message/property-send-message';
import propertyShare from './property-share/property-share';

const propertyShowModule = angular.module('propertyShow', [
  propertyMistakeReport,
  propertyNadlan,
  propertySendMessage,
  propertyShare
])
  .component('propertyShow', propertyShowComponent)
  .name;

export default propertyShowModule;
