import template from './popup-exceeding-assets.html';
import controller from './popup-exceeding-assets.controller';
import './popup-exceeding-assets.scss';

const popupExceedingAssetsComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state','$timeout', 'propertyService'];

export default popupExceedingAssetsComponent;
