import template from './saved-searches.html';
import controller from './saved-searches.controller';
import './saved-searches.scss';

const savedSearchesComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'savedSearchesService', 'utils', 'mailingListService'];

export default savedSearchesComponent;
