import _ from 'lodash';
import moment from 'moment';
import options from '../../../common/options';

export default function ($scope, sweetAlert, $rootScope, $state, $timeout, $uibModal, propertyService, propertyMistakeReportService, savedPropertiesService, utils, eventsService, $window) {
  let vm;

  class PropertyShowController {
    constructor () {
      vm = this;

      vm.shownSlide = 0;
      vm.integrityOptions = options.propertyIntegrityOptions;
      vm.plotPurposeOptions = options.propertyPlotPurposeOptions;
      vm.ownershipTypeOptions = options.propertyOwnershipTypeOptions;
      vm.propertyApartmentsInvestmentOptions = options.propertyApartmentsInvestmentOptions;
      vm.businessesList = options.propertyBusinessesForSaleOptions;
      vm.propertyCategoryOptions = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions
      };
      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };

      vm.rentCheckboxFields = options.rentCheckboxFields;
    }


    $onInit () {
      console.log(vm.property);
      this.getSimilarAssets();
      vm.allFiles = vm.property.files;
      vm.homelyFiles = [];
      vm.similarAssets = [];
      if (vm.property.homelyFiles) {
        if (JSON.parse(vm.property.homelyFiles).length > 0) {
          let homelyFiles = JSON.parse(vm.property.homelyFiles);
          for (let index = 0; index < homelyFiles.length; index++) {
            const element = homelyFiles[index];
            vm.fetchImage(element)
          }
        } else {
          vm.ready = true;
        }
      }
      else {
        vm.ready = true;
      }
      angular.element($window).bind("scroll", function (e) {
        let brokerY = document.querySelector('.broker-info').getBoundingClientRect().bottom;
        if (brokerY < 91) { vm.brokerFloat = true } else { vm.brokerFloat = false }
        if (!$scope.$$phase) {
          $scope.$apply()
        }
      })
      if (vm.property.category === 'workSpace') {
        vm.rentCheckboxFields = options.rentSpaceCheckboxFields;
      }
      if (vm.property.category === 'businessesForSale') {
        vm.rentCheckboxFields = options.businessesForSaleCheckboxFields;
      }
      if (vm.property.companyEmployees && vm.property.companyEmployees !== '[]') {
        propertyService.getUsersDetails(vm.property.companyEmployees)
          .then((employees) => {
            vm.employees = employees
            let allUsers = [];
            employees.forEach((user) => {
              allUsers.push(user.id)
            });
            vm.phoneNumberShown = allUsers;
          });
      }
      else {
        propertyService.getManagerDetails(vm.property.orgId).then((employees) => {
          vm.employees = [employees]
          vm.phoneNumberShown = [employees];
        })
      }
      vm.isMobileScreen = window.innerWidth < 800;
      vm.propertyAddressString = (_.get(vm, 'property.address') || '').replace(/, ישראל$/, '');
      // propertyService.getSiblingsProperties(vm.property.id)
      // .then((response) => {
      // vm.prevProperty = _.get(response, 'prev');
      // vm.nextProperty = _.get(response, 'next');
      // });
      let list = vm.getWithExpiry("list");
      if (list) {
        vm.SiblingsProperties(JSON.parse(list));
      }
      else {
        propertyService.getSiblingsProperties(vm.property.id)
          .then((response) => {
            vm.prevProperty = _.get(response, 'prev');
            vm.nextProperty = _.get(response, 'next');
          });
      }
      vm.propertySaved = _.get(vm.property, 'savedProperties.length');
      vm.block = vm.property.org.block
      eventsService.create('propertyShow', vm.property.id);
      vm.dataForm = {
        name: '',
        phone: ''
      };
    }

    getSimilarAssets () {
      propertyService.getSimilarAssets(vm.property.id)
        .then((response) => {
          vm.similarAssets = response;
        });
    }

    propertyAddressStringFun (address) {
      return (address || '').replace(/, ישראל$/, '');
    }

    getUserImage (userImage) {
      if (!userImage) {
        return '';
      }
      return window.SERVER_URL + userImage;
    }

    getWithExpiry (key) {
      const itemStr = localStorage.getItem(key)
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }

    SiblingsProperties (list) {
      // let list = JSON.parse(localStorage.getItem("list"));
      let currentItem = list.findIndex(i => i.id === vm.property.id)
      let last = list.findIndex((i) => i === list.slice(-1)[0]);
      let back = list[currentItem - 1] ? list[currentItem - 1] : list[last];
      let next = list[currentItem + 1] ? list[currentItem + 1] : list[0];
      vm.prevProperty = back;
      vm.nextProperty = next;
    }

    submitM () {
      if (vm.dataForm.name === '') {
        alert('שדה שם מלא חובה');
        return;
      }
      if (vm.dataForm.phone === '') {
        alert('שדה טלפון חובה');
        return;
      }
      let propertys = {
        orgId: vm.property.org.id,
        phone: vm.dataForm.phone,
        name: vm.dataForm.name,
        propertyTitle: vm.property.title,
        propertyID: vm.property.id,
      }
      propertyService.AddLade(propertys)
      vm.dataForm = {
        name: '',
        phone: ''
      };
      sweetAlert.swal('', 'ההודעה נשלחה למפרסם בהצלחה', 'success', {
        buttons: false,
        timer: 3000
      });
    };

    prevSlide () {
      vm.shownSlide--;
    }

    nextSlide () {
      vm.shownSlide++;
    }

    fetchImage (url) {
      return propertyService.fetchImage(url)
        .then((r) => {
          if (r) { vm.homelyFiles.push({ url: r }); } else { vm.homelyFiles.push({ url: "assets/images/default.jpg" }); }
          vm.allFiles = vm.property.files;
          vm.allFiles = vm.allFiles.concat(vm.homelyFiles);
          if (vm.homelyFiles.length === JSON.parse(vm.property.homelyFiles).length) {
            // $timeout(function () {
            vm.ready = true;
            // }, 1000);
          }
        })
    }

    getFileUrl1 (file) {
      return `${window.SERVER_URL}/file/property/${vm.property.id}/${file.name}_750x485${file.ext}`;
    }

    getFileUrl (file) {
      return `${window.SERVER_URL}/file/property/${vm.property.id}/${file.name}_750x485${file.ext}`;
    }

    getFileUrl2 (property, size, type) {
      if (_.get(property, 'files.length')) {
        return `${window.SERVER_URL}/file/${type}/${property.id}/${property.files[0].name}${size ? '_' + size : ''}${property.files[0].ext}`;
      }
      return 'assets/images/default.jpg';
    }

    getFileSrcsetUrl (file) {
      return `${window.SERVER_URL}/file/property/${vm.property.id}/${file.name}_750x485${file.ext} 750w, ${window.SERVER_URL}/file/property/${vm.property.id}/${file.name}${file.ext} 1306w`;
    }

    getSlideBackground (file) {
      return `url('${vm.getFileUrl(file)}'), url('assets/images/default.jpg');`;
    }

    getUserProfileImage (url) {
      if (!url) {
        if (!_.get(vm.property, 'user.profileImageUrl')) {
          return 'assets/images/user.png';
        }
        return window.SERVER_URL + vm.property.user.profileImageUrl;
      }
      else {
        return window.SERVER_URL + url;
      }
    }

    isPlot () {
      return vm.isForSale() && (vm.property.category === 'constructionPlot' || vm.property.category === 'agriculturalPlot');
    }

    isForSale () {
      return vm.property.type === 'forSale';
    }

    isForRent () {
      return vm.property.type === 'forRent';
    }

    hasPlotFields () {
      return vm.isPlot() && (vm.property.plotStatus ||
        vm.property.plotPurpose ||
        vm.property.squareMeterBuildingRights ||
        vm.property.ownershipType ||
        vm.property.gush ||
        vm.property.helka);
    }

    dateTime (date) {
      return moment(date).format();
    }

    isPhoneBlocked () {
      return false; // Temporary unblock all properties phones.
      // return _.get(vm.property, 'user.phoneBlocked') && !vm.isForRent();
    }

    async showPhone (id) {
      let user = $rootScope.user;

      if (vm.isPhoneBlocked() && !user) {
        user = await $uibModal.open({
          component: 'loginContainer',
          resolve: {
            loginTitle: () => 'להציג טלפון זה'
          },
          windowClass: 'login-modal',
          size: 'sm'
        })
          .result;
      }

      if (!vm.isPhoneBlocked() || user) {
        vm.phoneNumberShown[id] = true;
        propertyService.phoneNumberShown(vm.property.id);
        window.dataLayer.push({
          event: 'phoneNumberShown',
          phoneNumber: _.get(vm.property, 'user.phone') || ''
        });
      }

      eventsService.create('propertyShowPhone', vm.property.id);
    }

    categoryLink () {
      return (vm.property.type === 'forRent' ? 'forRentList' : 'forSaleList') + '({category: "' + vm.property.category + '"})';
    }

    async print () {
      const user = $rootScope.user || await $uibModal.open({
        component: 'loginContainer',
        resolve: {
          loginTitle: () => 'להדפיס דפי נכס'
        },
        windowClass: 'login-modal',
        size: 'sm'
      })
        .result;

      if (user) {
        if (!vm.phoneNumberShown) {
          vm.showPhone();
        }
        propertyService.propertyPrinted(vm.property.id);
        $timeout(() => print(), 200);
      }

      eventsService.create('propertyPrint', vm.property.id);
    }

    openShareModal () {
      $uibModal.open({
        component: 'propertyShare',
        resolve: {
          property: () => vm.property
        },
        windowClass: '',
        size: 'md'
      });

      eventsService.create('propertyShareModal', vm.property.id);
    }

    async openPropertySendMessageForm (userid, Email) {
      const propertyUser = { userId: userid, email: Email }
      const user = $rootScope.user || await $uibModal.open({
        component: 'loginContainer',
        resolve: {
          loginTitle: () => 'לפנות למפרסם'
        },
        windowClass: 'login-modal',
        size: 'sm'
      })
        .result;

      if (!user) { return; }

      $uibModal.open({
        component: 'propertySendMessage',
        resolve: {
          property: () => vm.property,
          propertyUser: () => propertyUser
        },
        windowClass: 'property-send-message-modal',
        size: 'md'
      });

      eventsService.create('propertyClickMessageForm', vm.property.id);
    }

    openMistakeReportForm () {
      propertyMistakeReportService.openForm(vm.property);
      eventsService.create('propertyMistakeReportForm', vm.property.id);
    }

    async toggleSaveProperty () {
      if (vm.savePropertyProgress || vm.property.status !== 'publish') { return; }
      let user = $rootScope.user;
      let actionName = 'toggle';

      if (!user) {
        actionName = 'saveProperty';
        vm.savePropertyProgress = true;
        user = await $uibModal.open({
          component: 'loginContainer',
          resolve: {
            loginTitle: () => 'לשמור נכס'
          },
          windowClass: 'login-modal',
          size: 'sm'
        })
          .result
          .then((user) => {
            return $timeout(() => user, 400);
          })
          .catch(() => {
            vm.savePropertyProgress = false;
          });
      }

      if (!user) {
        vm.savePropertyProgress = false;
        return;
      }
      vm.savePropertyProgress = true;
      savedPropertiesService[actionName](vm.property)
        .then((res) => {
          vm.propertySaved = res !== 1;

          if (res) {
            eventsService.create('propertySaveProperty', vm.property.id);
          }
        })
        .finally(() => {
          vm.savePropertyProgress = false;
        });
    }
  }

  return new PropertyShowController();
}
