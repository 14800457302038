import _ from 'lodash';

export default function ($scope, $state, $location, $timeout, $sce, propertyService, $window) {
  let vm;

  class NadlanListController {
    constructor () {
      vm = this;
      vm.limit = 50;
      vm.propertyTypeList = {
        forSale: 'למכירה',
        forRent: 'להשכרה'
      };
      vm.placeholder = $sce.trustAsHtml(createPlaceholderContent());
    }

    $onInit () {
      vm.getList();
      $scope.$watch('vm.filters', (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          vm.getList();
        }
      }, true);
      $scope.$watch('vm.type', (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          vm.getList();
        }
      });

      // $window.addEventListener('click', function (e) {
      //   $rootScope.$broadcast('app.clickEvent', e);
      // })

      const mobile = $window.matchMedia('screen and (max-width: 800px)');
      vm.mobile = mobile.matches;
      addEventListener("resize", (event) => {
        const mobile = $window.matchMedia('screen and (max-width: 800px)');
        vm.mobile = mobile.matches;
        if (!$scope.$$phase) {
          $scope.$apply()
        }
      });
    }

    $onDestroy () {
      window.listImageLoaded = undefined;
    }

    getFilterPricePerMeter (property) {
      if (property.ASSETTYPE === 'משרד' || property.ASSETTYPE === 'משרדים') {
        if (property.pricePerMeter < 2000 || property.pricePerMeter > 55000) {
          return false
        }
      }
      if (property.ASSETTYPE === 'חנות' || property.ASSETTYPE === 'חנויות') {
        if (property.pricePerMeter < 4000 || property.pricePerMeter > 200000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'תעשיה' || property.ASSETTYPE === 'מחסנים' || property.ASSETTYPE === 'מלאכה' || property.ASSETTYPE === 'מבני תעשיה') {
        if (property.pricePerMeter < 2000 || property.pricePerMeter > 35000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'קרקע למגורים' || property.ASSETTYPE === 'מגרשים לבניה') {
        if (property.pricePerMeter < 300 || property.pricePerMeter > 150000) {
          return false
        }
      }

      if (property.ASSETTYPE === 'מגרשים חקלאיים') {
        if (property.pricePerMeter < 30 || property.pricePerMeter > 40000) {
          return false
        }
      }
      return true
    }

    getList () {
      vm.getListProgress = true;
      vm.imageLoadedTimeout = false;
      vm.imageListLoaded = false;
      const query = {
        type: vm.type
      };
      if ($state.params.category) {
        query.category = $state.params.category;
      }
      if ($state.params.column) {
        query.column = $state.params.column
      }

      ['query', 'minPrice', 'maxPrice', 'minSquareMeter', 'maxSquareMeter'].forEach((key) => {
        if (vm.filters[key]) {
          query[key] = vm.filters[key];
        }
      });
      return new Promise((resolve, reject) => {
        return resolve(propertyService.getNadlanList(query));
      })
        .then((list) => {
          vm.list = list;
          vm.results = list.length > 0 ? true : false;
        })
        .finally(() => {
          vm.getListProgress = false;
          if (!_.get(vm, 'list.rows.length')) {
            vm.imageListLoaded = true;
          }
          $timeout.cancel(vm.progressBlocksTimeout); // cancel the last timeout
          vm.progressBlocksTimeout = $timeout(() => {
            $scope.$apply(() => {
              vm.imageListLoaded = true;
              vm.imageLoadedTimeout = true;
            });
          }, 5000);
        });
    }
  }

  return new NadlanListController();
}

function createPlaceholderContent () {
  let placeholder = '';
  const placeholderContent = `
  <a class="property col-lg-3 col-sm-4 col-xs-12 property-placeholder">
    <div class="property-inner">
      <svg class="placeholder-svgstyled__PlaceholderSVG-tcbxga-0 bixdtb" viewBox="0 0 350 275" width="100%" height="100%" preserveAspectRatio="none" style="border: solid 1px #e0e0e0;">
        <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="77%"></rect>
        <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" clip-path="url(#placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="100%"></rect>
        <defs>
          <clipPath id="placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
            <rect class="placeholder-textstyled__PlaceholderTextItem-s1aiy53q-1 cvdKqV" x="108" y="222" width="230" height="15"></rect>
            <rect class="placeholder-textstyled__PlaceholderTextItem-s1aiy53q-1 cvdKqV" x="200" y="248" width="138" height="17"></rect>
          </clipPath>
          <linearGradient id="placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
            <stop offset="-1.89007" stop-color="#F5F7F9" stop-opacity="1">
              <animate attributeName="offset" values="-3; 1" dur="1s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="-0.667554" stop-color="#fcfcfc" stop-opacity="1">
              <animate attributeName="offset" values="-1.5; 1.5" dur="1s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="0.109928" stop-color="#F5F7F9" stop-opacity="1">
              <animate attributeName="offset" values="-1; 3" dur="1s" repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </a>`;
  for (let i = 0; i < 10; i++) {
    placeholder += placeholderContent;
  }

  return placeholder;
}
