import isEmail from 'validator/lib/isEmail';

export default function ($scope, $state, $timeout, request) {
  let vm;

  class UnsubscribeCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.mailingId = $state.params.mailingId;
      vm.email = $state.params.email;
      if (!vm.mailingId && (!vm.email || !isEmail(vm.email || ''))) {
        $state.go('home');
        return;
      }

      let requestUrl = '/mailing-list/unsubscribe?';
      if (vm.mailingId) {
        requestUrl += 'mailingId=' + vm.mailingId;
      } else {
        requestUrl += 'email=' + vm.email;
      }

      return request(requestUrl)
        .then((res) => {
          vm.successMessage = true;

          $timeout(() => {
            $state.go('home');
          }, 5000);
        });
    }
  }

  return new UnsubscribeCtrl();
}
