import template from './footer.html';
import controller from './footer.controller';
import './footer.scss';

const footerComponent = {
  bindings: {
    minimal: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$uibModal', 'categoryService', 'mailingListService'];

export default footerComponent;
