import template from './p404.html';
import controller from './p404.controller';
import './p404.scss';

const p404Component = {
  template,
  controller,
  controllerAs: 'vm'
};

export default p404Component;
