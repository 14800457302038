import template from './autocomplete-gov.html';
import controller from './autocomplete-gov.controller';
import './autocomplete-gov.scss';

const autocompleteGovComponent = {
  bindings: {
    model: '=',
    search: '&',
    columnModel: '='
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', 'request'];

export default autocompleteGovComponent;
