import template from './home.html';
import controller from './home.controller';
import './home.scss';

const homeComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'utils', 'request'];

export default homeComponent;
