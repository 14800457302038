export default function ($scope, $uibModal, categoryService, mailingListService) {
  let vm;

  class FooterController {
    constructor () {
      vm = this;
      vm.name = 'footer';

      vm.now = new Date();
      vm.categorySaleList = categoryService.forSaleList;
      vm.categoryRentList = categoryService.forRentList;

      // vm.city =
      // vm.type = ['forRentList', 'categoryRentShow', 'cityRentShow'].includes(vm.stateName) ? 'forRent' : vm.stateName === 'producingList' ? 'producing' : 'forSale';
      // vm.cityCategorySideNav = categoryService.cityCategorySideNav;
      // vm.shownCategory = $state.params;
      // vm.categoryPage =
    }

    // $onInit () { }

    openMailingListForm () {
      mailingListService.openForm();
    }
  }

  return new FooterController();
}
