import angular from 'angular';
import propertyNadlanComponent from './property-nadlan.component';
import propertyNadlanService from './property-nadlan.service';

const propertyNadlanModule = angular.module('propertyNadlan', [
  propertyNadlanService
])
  .component('propertyNadlan', propertyNadlanComponent)
  .name;

export default propertyNadlanModule;
