/* globals google */
import _ from 'lodash';
import options from '../../../common/options';
import MarkerClusterer from '@google/markerclustererplus';

export default function ($scope, $timeout, propertyService, NgMap) {
  let vm;

  class PropertiesMapController {
    constructor () {
      vm = this;
      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };
    }


    $onInit () {
      vm.loadPropertyListCount = 0;
      vm.map = {
        libLoaded: false,
        show: true,
        polygons: [],
        circles: []
      };
      vm.type = vm.type || 'forSale';
      vm.getMap();

      $scope.$watch('vm.filters', (newValue, oldValue) => {
        if (!_.isEqual(newValue, oldValue)) {
          vm.getMap();
        }
      }, true);
    }

    $onDestroy () {
      if (vm.map.markers) {
        vm.map.markers.forEach((m) => m.setMap(null));
      }
      if (vm.map.markerClusterer) {
        vm.map.markerClusterer.clearMarkers();
      }
      vm.mapPropertyInfoHide();
    }

    async getMap () {
      const query = {
        limit: 8000,
        offset: 0
      };

      ['query', 'category', 'minPrice', 'maxPrice', 'minSquareMeter', 'maxSquareMeter', 'yieldFilter', 'projectId'].forEach((key) => {
        if (vm.filters[key]) {
          query[key] = vm.filters[key];
        }
      });

      await $timeout();
      $scope.$apply(() => {
        vm.map.libLoaded = true;
      });

      if (!vm.map.map) {
        vm.map.map = await NgMap.getMap();
        vm.map.map.setOptions({
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }]
            }
          ]
        });
      }

      // get all properties
      vm.loadPropertyListCount++;
      const loadPropertyListCount = vm.loadPropertyListCount;
      const allProperties = await propertyService.getPropertyList(vm.type, query);
      if (loadPropertyListCount >= vm.loadPropertyListCount) {
        await $timeout();
        $scope.$apply(() => {
          vm.map.properties = _.get(allProperties, 'rows') || [];
        });
        if (vm.map.map) {
          vm.mapClusterInit();
        }
      }
    }

    mapClusterInit () {
      if (vm.map.markerClusterer) {
        vm.map.markers.forEach((m) => m.setMap(null));
        vm.map.markerClusterer.clearMarkers();
      }
      const markerBounds = new google.maps.LatLngBounds();

      vm.map.markers = vm.map.properties
        .filter((p) => p.lat !== null && p.lon !== null && (vm.withinLimits(p) || vm.map.properties.length < 10))
        .map((p) => {
          const position = new google.maps.LatLng(p.lat, p.lon);
          const marker = new google.maps.Marker({
            position,
            id: p.id,
            icon: {
              url: '/assets/images/icon-maps.png'
            }
          });

          google.maps.event.addListener(marker, 'click', async function () {
            await vm.mapPropertyInfoShow(p.id);
            vm.map.map.showInfoWindow('propertyPreview', this);
          });

          markerBounds.extend(position);

          return marker;
        });

      const mcOptions = {
        imagePath: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
      };

      vm.map.markerClusterer = new MarkerClusterer(vm.map.map, vm.map.markers, mcOptions);

      google.maps.event.addListener(vm.map.markerClusterer, 'click', async function (event) {
        if (vm.map.map.getZoom() < 19) {
          return;
        }
        const markers = event.getMarkers();
        vm.mapPropertyListInfoShow(markers);
        vm.map.map.infoWindows.propertyListPreview.setPosition(event.getCenter());
        vm.map.map.showInfoWindow('propertyListPreview');
      });

      if (vm.map.properties.length) {
        vm.map.map.fitBounds(markerBounds);
      }
    }

    async mapPropertyInfoShow (id) {
      await $timeout();
      $scope.$apply(async () => {
        const property = vm.map.properties.find((property) => property.id === id) || await propertyService.getProperty(id);
        vm.map.shownProperty = property;
      });
    }

    mapPropertyListInfoShow (markers) {
      const propertyList = vm.map.properties
        .filter((property) => markers.some((marker) => marker.id === property.id));
      vm.map.shownPropertyList = propertyList;
    }

    mapPropertyInfoHide () {
      vm.map.map.hideInfoWindow('propertyPreview', this);
      vm.map.map.hideInfoWindow('propertyListPreview', this);
      vm.map.shownProperty = null;
      vm.map.shownPropertyList = null;
    }

    mapClick () {
      vm.mapPropertyInfoHide();
    }

    getFileUrl (property, size) {
      if (_.get(property, 'files.length')) {
        return `${window.SERVER_URL}/file/property/${property.id}/${property.files[0].name}${size ? '_' + size : ''}_350x227${property.files[0].ext}`;
      }
      return 'assets/images/default.jpg';
    }

    propertyAddressString (address) {
      return (address || '').replace(/, ישראל$/, '');
    }

    withinLimits (p) {
      return (parseFloat(p.lat) < 33.291 && parseFloat(p.lon) < 35.9) && (parseFloat(p.lat) > 29.488 && parseFloat(p.lon) > 34.22);
    }
  }

  return new PropertiesMapController();
}
