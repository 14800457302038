import template from './cancel-monthly-summary-email.html';
import controller from './cancel-monthly-summary-email.controller';
import './cancel-monthly-summary-email.scss';

const cancelMonthlySummaryEmailComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'request'];

export default cancelMonthlySummaryEmailComponent;
