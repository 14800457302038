export default function ($rootScope, $scope, planService) {
  let vm;

  class PaymentsPlansController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.currentPlan = {
        name: $rootScope.org.planName,
        description: $rootScope.org.planDescription,
        type: $rootScope.org.planType,
        price: $rootScope.org.planPrice,
        maxProperties: $rootScope.org.maxProperties,
        maxAllProperties: $rootScope.org.maxAllProperties,
        expiry: $rootScope.org.planExpiry
      };
      $scope.$on('plan-changed', (event, args) => {
        if (args.orgId === $rootScope.org.id) {
          vm.currentPlan = args.planDetails;
        }
      });
    }
  }

  return new PaymentsPlansController();
}
