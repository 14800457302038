function requestService ($http) {
  return function (url, data, method, config = {}) {
    method = method || (data ? 'POST' : 'GET');
    const headers = {
      'Content-Type': 'application/json'
    };

    return $http({
      method,
      withCredentials: true,
      headers,
      url: window.SERVER_URL + url,
      data: data || {},
      ...config
    });
  };
}

requestService.$inject = ['$http'];

const requestServiceModule = angular.module('requestService', [])
  .factory('request', requestService)
  .name;

export default requestServiceModule;
