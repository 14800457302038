export default function () {
  let vm;

  class MainController {
    constructor () {
      vm = this;
      vm.name = 'main';
    }
  }

  return new MainController();
}
