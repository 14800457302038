import template from './lead-send-message.html';
import controller from './lead-send-message.controller';
import './lead-send-message.scss';

const leadSendMessageComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'sweetAlert', 'messagesService', 'utils', 'eventsService'];

export default leadSendMessageComponent;
