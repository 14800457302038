import angular from 'angular';
import link from './input-number.link';

const inputNumberModule = angular.module('inputNumber', [])
  .directive('inputNumber', inputNumberDirective)
  .name;

function inputNumberDirective ($filter, $timeout) {
  return {
    scope: {
      min: '=?',
      max: '=?',
      step: '<?'
    },
    restrict: 'A',
    require: 'ngModel',
    link: link($filter, $timeout)
  };
}

inputNumberDirective.$inject = ['$filter', '$timeout'];

export default inputNumberModule;
