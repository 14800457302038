function utilsService ($rootScope, $uibModal, request) {
  return {
    setFormFieldsDirty (form) {
      if (form.$setDirty) {
        form.$setDirty();
      }

      angular.forEach(form, (value, key) => {
        if (typeof value === 'object' && value.hasOwnProperty('$modelValue')) {
          value.$setDirty();
        }
      });
    },

    modalAlert (params) {
      return new Promise((resolve, reject) => {
        function ModalInstanceCtrl ($scope, $uibModalInstance) {
          $scope.$uibModalInstance = $uibModalInstance;
          $scope.params = params;
          $scope.click = function (btn) {
            if (btn.onClick) {
              return resolve(btn.onClick($uibModalInstance));
            }

            $uibModalInstance.dismiss('cancel');
            return reject(new Error('cancled'));
          };
        }

        const dialogTemplate = `<div class="inmodal">
          <div class="modal-header">
            <h4 class="modal-title m-b-xs">{{params.title}}</h4>
            <small ng-if="params.message" class="m-r-sm">{{params.message}}</small>
          </div>
          <div class="modal-footer">
            <button ng-repeat="btn in params.buttons" type="button" class="btn {{btn.btnClass || 'btn-default'}}" ng-click="click(btn)">{{btn.label}}</button>
          </div>
        </div>`;

        $uibModal.open({
          template: dialogTemplate,
          controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
          windowClass: params.windowClass
        });
      });
    },

    getUrlParameter (sParam) {
      const sPageURL = window.location.search.substring(1);
      const sURLVariables = sPageURL.split('&');
      let sParameterName;
      let i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
      }
    }
  };
}

utilsService.$inject = ['$rootScope', '$uibModal', 'request'];

const utilsServiceModule = angular.module('utilsService', [])
  .factory('utils', utilsService)
  .name;

export default utilsServiceModule;
