import template from './profile.html';
import controller from './profile.controller';
import './profile.scss';

const profileComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', 'profileService', 'utils'];

export default profileComponent;
