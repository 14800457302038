import angular from 'angular';

import template from './checkbox.html';
import './checkbox.scss';

const checkboxModule = angular.module('checkbox', [])
  .directive('checkbox', [function () {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        model: '=?',
        bindId: '@',
        bindChange: '<?',
        label: '@'
      },
      template,
      controller () {
        const vm = this;
        vm.$onInit = function () {
          vm.bindId = vm.bindId || vm.model;
        };
      }
    };
  }])
  .name;

export default checkboxModule;
