import _ from 'lodash';

// let $rootScope;
let request;
let fileRequest;

class propertyService {
  constructor (_$rootScope, _request, _fileRequest) {
    // $rootScope = _$rootScope;
    request = _request;
    fileRequest = _fileRequest;
  }

  getPropertyList (type = 'forSale', args = {}) {
    if (type === 'producing') {
      type = 'forSale';
      _.set(args, 'producingOnly', true);
    }

    return request('/property/' + type + '/?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getAllProjects (args = {}) {
    return request('/project/?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getNadlanList (args = {}) {
    return request('/property/nadlan/?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getPropertyHomeList () {
    return request('/property/home-list')
      .then((res) => res.data.data);
  }

  getProperty (propertyId) {
    return request('/property/' + propertyId)
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetProperty failed', error);
        return Promise.reject(error);
      });
  }

  saveProperty (propertyId, propertyObject, publishOneSignal, withoutTelegramnot, withoutTwitter) {
    return fileRequest('/property/' + (propertyId || '') + (publishOneSignal ? '?publishOneSignal=true' : '') + (withoutTelegramnot ? '?withoutTelegramnot=true' : '') + ((withoutTelegramnot && withoutTwitter) ? '&withoutTwitter=true' : withoutTwitter ? '?withoutTwitter=true' : ''), propertyObject, (propertyId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  AddLade (LadeNewObject) {
    return request('/property/AddLade', LadeNewObject, 'POST')
      .then((res) => res.data.data);
  }

  getUsersDetails (araay) {
    return request('/user/get-users-details', araay, 'POST')
      .then((res) => res.data.data);
  }

  fetchImage (url) {
    return request('/homely/image/', { url: url }, 'POST')
      .then((res) => res.data)
      .catch((error) => console.log(error));
  }

  getLeads (orgId = '') {
    return request('/property/getLeads/' + orgId, null, 'GET')
      .then((res) => res.data.data);
  }

  deleteProperty (propertyId) {
    return request('/property/' + propertyId || '', null, 'DELETE')
      .then((res) => res.data.data);
  }

  getMyProperties (adminGetAll = '') {
    return request('/property/my/' + adminGetAll)
      .then((res) => res.data.data);
  }

  getSiblingsProperties (propertyId) {
    return request('/property/siblings/' + propertyId, null, 'GET', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  getSimilarAssets (propertyId) {
    return request('/property/similar/' + propertyId, null, 'GET', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  getManagerDetails (orgId) {
    return request('/property/getManagerDetails/' + orgId, null, 'GET')
      .then((res) => res.data.data);
  }

  phoneNumberShown (propertyId) {
    return request('/property/phone-shown/' + propertyId, null, 'GET', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  propertyPrinted (propertyId) {
    return request('/property/property-printed/' + propertyId, null, 'GET', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }

  getOrgProperties (orgId, args = {}) {
    return request('/property/org/' + orgId + '?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getPropertyGeoList (type = 'forSale', args = {}) {
    if (type === 'producing') {
      type = 'forSale';
      _.set(args, 'producingOnly', true);
    }

    return request('/property/' + type + '/geo/?' + jQuery.param(args))
      .then((res) => {
        return res.data.data;
      });
  }

  getOrgCards () {
    return request('/payment/get-credit-cards')
      .then((res) => res.data.data);
  }

  getNumPropertys (orgId) {
    return request('/property/get-num-propertys/' + orgId, null, 'GET')
      .then((res) => res.data.data);
  }

  contactRequest () {
    return request('/property/contact-request', null, 'GET')
      .then((res) => res.data.data);
  }

  // setPropertyActivePlan (propertyId, ptokenId) {
  //   return request('/property/' + propertyId, { property: JSON.stringify({ ptokenId }) }, 'PUT')
  //     .then((res) => res.data.data);
  // }

  // removePropertyActivePlan (propertyId) {
  //   return request('/property/' + propertyId, { property: JSON.stringify({ ptokenId: null }) }, 'PUT')
  //     .then((res) => res.data.data);
  // }
}

propertyService.$inject = ['$rootScope', 'request', 'fileRequest'];

const propertyServiceModule = angular.module('propertyService', [])
  .service('propertyService', propertyService)
  .name;

export default propertyServiceModule;
