import angular from 'angular';

import planChooser from './plan-chooser/plan-chooser';
import choosePlanComponent from './choose-plan.component';

const choosePlanModule = angular.module('choosePlan', [
  planChooser
])
  .component('choosePlan', choosePlanComponent)
  .name;

export default choosePlanModule;
