import angular from 'angular';
import leadsMainComponent from './leads-main.component';
import leadSendMessageModule from './lead-send-message/lead-send-message';

const leadsMainModule = angular.module('leadsMain', [
  leadSendMessageModule
])
  .component('leadsMain', leadsMainComponent)
  .name;

export default leadsMainModule;
