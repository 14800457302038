import angular from 'angular';

import checkbox from './checkbox/checkbox';
import formatDate from './format-date/format-date';
import imagePreview from './image-preview/image-preview';
import inputImage from './input-image/input-image';
import inputNumber from './input-number/input-number';
import radioCheckbox from './radio-checkbox/radio-checkbox';
import switchToggle from './switch-toggle/switch-toggle';
import videoEdit from './video-edit/video-edit';
import formLead from './form-lead/form-lead';
import projectsKeys from './projects-keys/projects-keys';

const directivesModule = angular.module('app.directives', [
  checkbox,
  formatDate,
  imagePreview,
  inputImage,
  inputNumber,
  radioCheckbox,
  switchToggle,
  videoEdit,
  formLead,
  projectsKeys
])
  .name;

export default directivesModule;
