import angular from 'angular';
import managePluginComponent from './manage-plugin.component';
import OrgService from './../../org/org.service';

const managePluginModule = angular.module('manage-plugin', [
  OrgService
])
  .component('managePlugin', managePluginComponent)
  .name;

export default managePluginModule;
