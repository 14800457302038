import _ from 'lodash';

export default function ($scope, $rootScope, $state, sweetAlert, propertyMistakeReportService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class PropertyMistakeReportCtrl {
    constructor () {
      vm = this;

      vm.caseList = {
        irrelevant: 'המודעה אינה אקטואלית',
        abusive: 'המודעה מכילה תוכן פוגעני',
        incorrect: 'המחיר או פרט אחר במודעה שונים ממה שפורסם',
        other: 'סיבה אחרת'
      };
    }

    $onInit () {
      vm.model = {};
      vm.property = vm.resolve.property;
    }

    sendReport () {
      utils.setFormFieldsDirty(vm.mistakeReportForm);

      if (vm.mistakeReportForm.$invalid) {
        $('#mistake-report-form')[0].reportValidity();
        return;
      }
      vm.sendProgress = true;
      const reportObject = {};
      reportObject.case = vm.model.case;
      reportObject.description = vm.model.description;
      reportObject.userId = _.get($rootScope, 'user.id');

      return propertyMistakeReportService.sendReport(reportObject, vm.property.id)
        .then(() => {
          sweetAlert.swal('', 'תודה!\n הדיווח התקבל ויטופל בהקדם', 'success', {
            buttons: false,
            timer: 3000
          });
          vm.modalInstance.close();
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#mistake-report-form'))
        .bind('keydown keypress', (event) => {
          if (event.ctrlKey && event.which === KEY_EVENT_ENTER) {
            vm.sendReport();
            event.preventDefault();
          }
        });
    }
  }

  return new PropertyMistakeReportCtrl();
}
