export default function () {
  let vm;

  class PaymentsController {
    constructor () {
      vm = this;
    }
  }

  return new PaymentsController();
}
