import _ from 'lodash';
import zxcvbn from 'zxcvbn';

export default function ($scope, $state, $location, $timeout, loginService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class NewPasswordCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.token = $state.params.token;
      if (!vm.token) {
        $state.go('home');
        return;
      }
      loginService.checkToken(vm.token)
        .then((response) => {
          vm.tokenStatus = 'valid';
        })
        .catch((err) => {
          vm.tokenStatus = _.get(err, 'data.data');
        });

      $('#new-password-form').on('keydown keypress', (event) => {
        if (event.which === KEY_EVENT_ENTER) {
          vm.send();
          event.preventDefault();
        }
      });

      vm.newPasswordFields = {};
      $('#password').focus();
      $scope.$watchGroup(['vm.newPasswordFields.password', 'vm.newPasswordFields.password2'], (newVal, oldVal) => {
        if (_.get(vm, 'newPasswordForm.password')) {
          const passwordMatch = vm.newPasswordFields.password === vm.newPasswordFields.password2;
          vm.newPasswordForm.password2.$setValidity('passwordMatch', passwordMatch);
          $('#password2')[0].setCustomValidity(passwordMatch || !vm.newPasswordFields.password2 ? '' : 'הסיסמאות אינן תואמות');
        }
      });
      vm.newPasswordFields.email = $state.params.email || '';
    }

    checkPassword () {
      vm.result = zxcvbn(vm.newPasswordForm.password.$viewValue);
    }

    send () {
      if (vm.newPasswordForm.$invalid) {
        $('#new-password-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.newPasswordForm);
        return;
      }

      vm.error = false;
      vm.sendProgress = true;
      loginService.newPassword(vm.token, vm.newPasswordFields.password)
        .then((res) => {
          if (!res) {
            return Promise.reject(new Error(''));
          }
          $timeout(() => {
            $scope.$apply(vm.successMessage = true);
          });
          $timeout(() => {
            $state.go('home');
          }, 5000);
        })
        .catch((error) => {
          vm.errorMessage = 'שגיאה';
          vm.showErrorMessage();
          return error;
        })
        .finally(() => {
          $timeout(() => {
            $scope.$apply(vm.sendProgress = false);
          });
        });
    }

    showErrorMessage () {
      $timeout(() => {
        $scope.$apply(() => {
          vm.sendProgress = false;
          vm.error = true;
        });
      });
    }
  }

  return new NewPasswordCtrl();
}
