import angular from 'angular';
import popupExceedingAssets from '../../popup-exceeding-assets/popup-exceeding-assets';

import myPropertiesComponent from './my-properties.component';

const myPropertiesModule = angular.module('myProperties', [
  popupExceedingAssets
])
  .component('myProperties', myPropertiesComponent)
  .name;

export default myPropertiesModule;
