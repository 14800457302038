import template from './manage-address.html';
import controller from './manage-address.controller';
import './manage-address.scss';

const ManageAddressComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['manageService'];

export default ManageAddressComponent;
