import template from './accessibility.html';
import controller from './accessibility.controller';
import './accessibility.scss';

const accessibilityComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils'];

export default accessibilityComponent;
