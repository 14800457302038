import template from './property-send-message.html';
import controller from './property-send-message.controller';
import './property-send-message.scss';

const propertySendMessageComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'sweetAlert', 'messagesService', 'utils', 'eventsService'];

export default propertySendMessageComponent;
