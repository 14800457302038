import _ from 'lodash';

export default function ($scope, $rootScope, $state, sweetAlert, messagesService, utils, eventsService) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class ProjectSendMessageCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.model = {};
      vm.project = vm.resolve.project;
      vm.messageModel = `היי, הייתי רוצה לשמוע פרטים נוספים על הפרויקט ${vm.project.address ? 'ב' + vm.project.address : ''}. \nתודה!`;
    }

    sendMessage () {
      utils.setFormFieldsDirty(vm.projectSendMessageForm);

      if (vm.projectSendMessageForm.$invalid) {
        $('#project-send-message-form')[0].reportValidity();
        return;
      }
      vm.sendProgress = true;

      return messagesService.sendProjectMessage(vm.messageModel, vm.project.id)
        .then(() => {
          sweetAlert.swal('', 'ההודעה נשלחה למפרסם בהצלחה', 'success', {
            buttons: false,
            timer: 3000
          });
          vm.modalInstance.close();

          eventsService.create('projectSendMessageForm', vm.project.id);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#project-send-message-form'))
        .bind('keydown keypress', (event) => {
          if (event.ctrlKey && event.which === KEY_EVENT_ENTER) {
            vm.sendMessage();
            event.preventDefault();
          }
        });
    }
  }

  return new ProjectSendMessageCtrl();
}
