import template from './leads-main.html';
import controller from './leads-main.controller';
import './leads-main.scss';

const leadsMainComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'sweetAlert', 'eventsService', 'propertyService', 'projectService', 'manageService', 'utils', '$uibModal'];

export default leadsMainComponent;
