import _ from 'lodash';

const GLOBAL_SETTINGS = window.GLOBAL_SETTINGS || {};
const SERVER_URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');
window.SERVER_URL = SERVER_URL;

export default function ($scope, $rootScope) {
  // let vm;

  class AppCtrl {
    constructor () {
      $rootScope.isAdmin = function () {
        return _.get($rootScope, 'user.permission') === 'admin';
      };
    }
  }

  return new AppCtrl();
}
