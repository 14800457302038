export default function ($filter, $timeout) {
  return function (scope, element, attr, vm) {
    if (!vm) {
      return;
    }

    scope.$watchGroup(['min', 'max'], (newVal, oldVal) => {
      vm.onChange();
    });

    if (Number.isInteger(scope.step) && scope.step > 0) {
      scope.step = Math.min(scope.step, 2);
    } else {
      scope.step = 0;
    }

    vm.onChange = function () {
      if (vm.$modelValue) {
        $timeout(() => {
          vm.min = Number(scope.min) || 0;
          vm.max = Number(scope.max) || Infinity;
          const value = vm.$modelValue;
          const maxSum = Number(value) <= vm.max;
          const minSum = Number(value) >= vm.min;
          vm.$setValidity('maxSum', maxSum);
          vm.$setValidity('minSum', minSum);
        });
      }
    };

    vm.$formatters.unshift((a) => {
      vm.onChange();
      return $filter('number')(vm.$modelValue, scope.step);
    });

    vm.$parsers.unshift((viewValue) => {
      const plainNumber = scope.step ? viewValue.replace(/[^\d|.]/g, '') : viewValue.replace(/[^\d]/g, '');
      if (plainNumber === '') {
        return plainNumber;
      }
      if (scope.step && viewValue[viewValue.length - 1] === '.') {
        return plainNumber.replace(/[.]/g, '');
      }
      let step = 0;
      const splitStep = plainNumber.split('.')[1];
      if (splitStep > 0) {
        step = Math.min(splitStep.length, scope.step);
      }
      vm.onChange();
      let resultValue = $filter('number')(plainNumber, step);
      if (!angular.isUndefined(attr.maxlength)) {
        resultValue = resultValue.slice(0, attr.maxlength);
      }
      element.val(resultValue);
      return Number(Number(plainNumber).toFixed(step));
    });
  };
}
