import template from './payments-plans.html';
import controller from './payments-plans.controller';
import './payments-plans.scss';

const paymentsPlansComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$scope', 'planService'];

export default paymentsPlansComponent;
