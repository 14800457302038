import angular from 'angular';
import profileEdit from './profile-edit/profile-edit';
import profileService from './profile.service';
import profileComponent from './profile.component';
import companyEmployees from './company-employees/company-employees';

const profileModule = angular.module('profile', [
  profileEdit,
  profileService,
  companyEmployees
])
  .component('profile', profileComponent)
  .name;

export default profileModule;
