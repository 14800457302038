import angular from 'angular';
import PropertiesHomeList from './properties-home-list/properties-home-list';
import PropertiesList from './properties-list/properties-list';
import PropertiesListPage from './properties-list-page/properties-list-page';
import propertiesMap from './properties-map/properties-map';
import PropertyEdit from './property-edit/property-edit';
import PropertyShow from './property-show/property-show';
import CategoryService from './category.service';
import CityService from './city.service';
import PropertyService from './property.service';
import eventsService from '../dashboard/events/events.service';
import NadlanList from './nadlan-list/nadlan-list';
const propertyModule = angular.module('property', [
  PropertiesHomeList,
  PropertiesList,
  PropertiesListPage,
  propertiesMap,
  PropertyEdit,
  PropertyShow,
  CategoryService,
  CityService,
  PropertyService,
  eventsService,
  NadlanList,
])
  .name;

export default propertyModule;
