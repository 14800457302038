import template from './siteMap.html';
import controller from './siteMap.controller';
import './siteMap.scss';

const siteMapComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils', 'categoryService'];

export default siteMapComponent;
