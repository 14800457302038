import template from './company-employees.html';
import controller from './company-employees.controller';
import './company-employees.scss';

const companyEmployeesComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'request', 'utils'];

export default companyEmployeesComponent;
