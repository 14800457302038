import template from './unsubscribe.html';
import controller from './unsubscribe.controller';
import './unsubscribe.scss';

const unsubscribeComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'request'];

export default unsubscribeComponent;
