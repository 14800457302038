import template from './property-not-available.html';
import controller from './property-not-available.controller';
import './property-not-available.scss';

const propertyNotAvailableComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

export default propertyNotAvailableComponent;
