import template from './manage-projects-leads.html';
import controller from './manage-projects-leads.controller';
import './manage-projects-leads.scss';

const ManageProjectsLeadsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'manageService', 'utils', '$uibModal'];

export default ManageProjectsLeadsComponent;
