import angular from 'angular';
import leadsMain from './leads-main/leads-main';
import eventsService from './events.service';

const eventsModule = angular.module('events', [
  leadsMain,
  eventsService
])
  .name;

export default eventsModule;
