import _ from 'lodash';
import marked from 'marked';

export default function ($scope, $rootScope, $state, $timeout, messagesService, utils, $uibModal) {
  const KEY_EVENT_ENTER = 13;
  let vm;

  class MessageShowController {
    constructor () {
      vm = this;
      vm.messagesService = messagesService;

      vm.markedOps = {
        renderer: new marked.Renderer(),
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true,
        smartLists: true,
        smartypants: false
      };

      vm.markedOps.renderer.link = function (href, title, text) {
        return '<a ' + (text === href ? 'class="a-ltr"' : '') + " href='" + href + "' target='_blank'>" + text + '</a>';
      };
    }

    $onInit () {
      vm.messagesType = $state.params.type;
      vm.messageId = $state.params.id;
      vm.userId = $rootScope.user.id;
      vm.me = { id: vm.userId, firstName: 'אני' };
      vm.getMessage();

      $timeout(() => {
        if (!vm.destroyed) {
          messagesService.markAsRead(vm.messageId);
        }
      }, 900);
    }

    $onDestroy () {
      vm.destroyed = true;
    }

    messagesTypeChange (type) {
      vm.messagesType = type;
      vm.getMessage();
    }

    getMessage (args) {
      vm.loading = true;
      messagesService.getMessage(vm.messageId)
        .then((res) => {
          vm.message = res;
          if (!vm.message) {
            $state.go('messages');
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    sendPost () {
      if (vm.form.$invalid) {
        $('#post-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      if (vm.sendProgress) {
        return;
      }
      const to = vm.userId === vm.message.userId ? vm.message.to : vm.message.userId;

      vm.sendProgress = true;
      $('.new-post').css('opacity', 0);
      messagesService.sendMessagePost(vm.message.id, to, vm.messageModel)
        .then((postResult) => {
          vm.messageModel = '';
          $timeout(() => {
            $('.new-post').css('opacity', 1);
          }, 600);
          vm.form.$setPristine();
          vm.getMessage();
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    getUserProfileImage (user) {
      if (!user || !user.profileImageUrl) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + user.profileImageUrl;
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#post-form'))
        .bind('keydown keypress', function (event) {
          if (event.which === KEY_EVENT_ENTER && event.ctrlKey) {
            vm.sendPost();
            event.preventDefault();
          }
        });
    }
  }

  return new MessageShowController();
}
