import _ from 'lodash';

let request;

class propertyNadlanService {
  constructor (_request) {
    request = _request;
  }

  getDeals (propertyId) {
    return request('/property/property-get-deals/' + propertyId)
      .then((res) => res.data.data);
  }

  collectDeals (propertyId) {
    return request(`/manage/property/${propertyId}/collect-ndalan-data`, null, 'POST')
      .then((res) => res.data.data);
  }

  getMenivimDeals (propertyId) {
    return request('/property/get-menivim-deals/' + propertyId)
    .then((res) => res.data.data);
  }
}

propertyNadlanService.$inject = ['request'];

const propertyNadlanServiceModule = angular.module('propertyNadlanService', [])
  .service('propertyNadlanService', propertyNadlanService)
  .name;

export default propertyNadlanServiceModule;
