import isUrl from 'is-url';

export default function (planService, paymentsService, $timeout, manageService, profileService) {
  let vm;

  class ManagePlanController {
    constructor () {
      vm = this;
      this.builtinPlans = {
        Basic: {
          name: 'Basic',
          description: 'מנוי בסיסי',
          type: 'monthly',
          maxProperties: 1,
          maxAllProperties: 5,
          price: 150
        },
        Business: {
          name: 'Business',
          description: 'מנוי עסקי',
          type: 'monthly',
          maxProperties: 4,
          maxAllProperties: 15,
          price: 250
        },
        Premium: {
          name: 'Premium',
          description: 'מנוי פרימיום',
          type: 'monthly',
          maxProperties: 8,
          maxAllProperties: 30,
          price: 350
        },
        vip: {
          name: 'vip',
          description: ' vip מנוי',
          type: 'monthly',
          maxProperties: 12,
          maxAllProperties: 50,
          price: 450
        },
        custom: {
          name: 'custom',
          description: 'מותאם אישית',
          type: '',
          maxProperties: 0, // TODO make that mean unlimited
          maxAllProperties: 0,
          price: 0
        }
      };
      vm.orgKindOptions = [
        { value: 'broker', title: 'מתווך' },
        { value: 'lender', title: 'בעל נכס' },
        { value: 'investor', title: 'משקיע' },
        { value: 'buyer', title: 'רוכש' },
        { value: 'other', title: 'אחר' }
      ];
      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      }
    }

    $onInit () {
      vm.modelObject = {};
      const org = vm.org = vm.resolve.user.org;
      vm.modelObject.org = {};
      vm.modelObject.org.kind = org.kind;
      vm.modelObject.org.invoiceName = vm.getInvoiceName();
      vm.modelObject.org.name = org.name;
      vm.modelObject.phone = vm.resolve.user.phone;
      vm.modelObject.email = vm.resolve.user.email;
      vm.modelObject.firstName = vm.resolve.user.firstName;
      vm.modelObject.lastName = vm.resolve.user.lastName;
      vm.modelObject.id = vm.resolve.user.id;
      vm.planDetails = {
        name: org.planName,
        description: org.planDescription,
        type: org.planType,
        maxProperties: org.planMaxProperties,
        maxAllProperties: org.planMaxAllProperties,
        price: org.planPrice,
        expiry: new Date(org.planExpiry),
        blockAlert: org.blockAlert,
        block: org.block,
        VATexempt: org.VATexempt
      };
      vm.getOrgCards(org.id);
      vm.blockAlertText = "התראה";
      vm.blockText = "חסימה";
      if (vm.planDetails.blockAlert) { vm.blockAlert = "backgroundBlockAlert", vm.blockAlertText = "הותרה" }
      if (vm.planDetails.block) { vm.block = "backgroundBlock", vm.blockText = "נחסם" }
    }

    getOrgCards(id) {
      manageService.getOrgCards(id)
      .then((res) => {
       vm.cards = res;
      })
      .catch(console.error);
    }

    deleteCard (cardId) {
      return paymentsService.deleteCardManage(cardId, vm.resolve.user.org.id)
        .then((res) => {
          alert("בוצע בהצלחה!");
          vm.cancel()
        });
    }

    getInvoiceName () {
      return vm.org.invoiceName || vm.org.name || [_.get(vm.resolve.user, 'firstName'), _.get(vm.resolve.user, 'lastName')].join(' ') || '';
    }

    updateEmailUser (action) {
      let user = vm.resolve.user
      let body = { email: user.email, action: action }
      manageService.updateEmailUser(user.id, body)
        .then((res) => {
        })
        .catch(console.error);
    }

    activeCard (orgId) {
      manageService.activeCard(orgId)
        .then((res) => {
          alert("הפעולה הסתיימה")
        })
        .catch(console.error);
    }

    getUserProfileImage () {
      if (!vm.resolve.user.profileImageUrl) {
        return '';
      }
      return window.SERVER_URL + vm.resolve.user.profileImageUrl;
    }

    saveProfile () {
      if (vm.saveProgress) { return; }
      if (vm.form.$invalid) {
        utils.setFormFieldsDirty(vm.form);
        $('#profile-form')[0].reportValidity();
        return;
      }
      vm.saveProgress = true;
      const query = {
        user: JSON.stringify(vm.modelObject)
      };

      if (vm.profileImage) {
        query.profileImage = vm.profileImage;
      }
      // if (vm.orgLogo) {
      //   query.orgLogo = vm.orgLogo;
      // }
      return profileService.saveProfile(vm.modelObject.id, query, true)
        .then((response) => {
          vm.modalInstance.close();
        })
        .catch(console.error)
        .finally(() => {
          vm.saveProgress = false;
          vm.modalInstance.close();
        });
    }

    blockAlertFun (org) {
      if (!vm.planDetails.blockAlert) {
        // console.log("הפעל תהליך התראה", org);
        manageService.blockingAlert(org.id, true)
          .then(() => { vm.planDetails.blockAlert = true, vm.blockAlert = "backgroundBlockAlert", vm.blockAlertText = "הותרה" });
      }
      else {
        // console.log("עצור תהליך התראה");
        manageService.blockingAlert(org.id, false)
          .then(() => { vm.planDetails.blockAlert = false, vm.blockAlert = "", vm.blockAlertText = "התראה" });
      }
    }
    blockingFun (org, num) {
      if (!vm.planDetails.block) {
        manageService.blocking(org.id, true, num)
          .then(() => { vm.planDetails.block = true, vm.block = "backgroundBlock", vm.blockText = "נחסם" });
      }
      else {
        manageService.blocking(org.id, false)
          .then(() => { vm.planDetails.block = false, vm.block = "", vm.blockText = "חסימה" });
      }
    }

    breakEmail (org) {
      manageService.breakEmail(org.id)
        .then();
    }

    endBenefit (org) {
      manageService.endBenefit(org.id)
        .then();
    }

    ChangeVATexempt (org) {
      if (!vm.planDetails.VATexempt) {
        manageService.ChangeVATexempt(org.id, true)
          .then(() => { vm.planDetails.VATexempt = true });
      }
      else {
        manageService.ChangeVATexempt(org.id, false)
          .then(() => { vm.planDetails.VATexempt = false });
      }
    }

    planSelectionChanged () {
      Object.assign(vm.planDetails, vm.builtinPlans[vm.planDetails.name]);
    }

    toggleUserBlockingMessages (blocked, user) {
      return manageService.toggleUserBlockingMessages(user, blocked)
        .then((resUser) => {
          user.blockingSendingMessages = resUser.blockingSendingMessages;
        })
        .catch(() => {
          user.blockingSendingMessages = !user.blockingSendingMessages;
        });
    }

    addCard (org) {
      paymentsService.getOrgAddCardUrl(org.id, '/manage/manage-user')
        .then((res) => {
          if (isUrl(res)) {
            $timeout(() => {
              window.open(res);
            });
          }
        });
    }

    save () {
      planService.setPlan(vm.org.id, vm.planDetails)
        .then(() => {
          vm.modalInstance.close();
        })
        .catch(console.error);
    }

    cancel () {
      vm.modalInstance.close();
    }
  }

  return new ManagePlanController();
}
