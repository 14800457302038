import template from './manage-mailing-list.html';
import controller from './manage-mailing-list.controller';
import './manage-mailing-list.scss';

const ManageMailingListComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'manageService', 'utils'];

export default ManageMailingListComponent;
