import _ from 'lodash';
import options from './common/options';

const titleSuffix = ' - מניבים ישראל נכסים ונדל\u0022ן מניב.';

export default ['$locationProvider', '$stateProvider', '$urlRouterProvider', '$compileProvider', function ($locationProvider, $stateProvider, $urlRouterProvider, $compileProvider) {
  // @see: https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions
  // #how-to-configure-your-server-to-work-with-html5mode
  $locationProvider.html5Mode(true);
  // $compileProvider.debugInfoEnabled(true);

  // Set default state
  // $urlRouterProvider.otherwise('/');
  $urlRouterProvider.otherwise('/404');
  $urlRouterProvider.otherwise(($injector, $location) => {
    $injector.invoke(['$state', ($state) => {
      $state.go('p404', {}, { reload: true });
    }]);
  });

  $stateProvider
    .state('logout', {
      url: '/logout',
      resolve: {
        logout: ['$state', 'loginService', logout],
        pageService: ['pageService', (pageService) => pageService()]
      }
    })
    .state('home', {
      url: '/',
      component: 'home',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('מניבים - לוח הנדל"ן המסחרי המוביל בישראל')]
      }
    })
    .state('forSaleList', {
      url: '/for-sale/:page?/?:column?:category?:businesses?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forSaleList',
        page: null
      },
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', '$stateParams', (pageService, $stateParams) => pageService(
          'למכירה - מניבים. לוח הנדל"ן המסחרי המוביל בישראל',
          'מחפש נכס למכירה? כנס ללוח הנד"ן מניבים! פה תמצא אלפי נכסים מסחריים, משרדים למכירה, חנויות למכירה, מבני תעשייה, השקעות נדל"ן ועוד, כל המידע הרלוונטי בקלות וללא מאמץ.',
          'https://menivim.net/for-sale/' + `${$stateParams.page ? $stateParams.page : ""}`
        )]
      }
    })
    .state('projectsforSaleList', {
      url: '/projects-for-sale/:page?/?:column?:category?:businesses?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'projectsforSaleList',
        page: null
      },
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService(
          'פרויקטים - מניבים. לוח הנדל"ן המסחרי המוביל בישראל',
          'מחפש נכס למכירה? כנס ללוח הנד"ן מניבים! פה תמצא אלפי נכסים מסחריים, משרדים למכירה, חנויות למכירה, מבני תעשייה, השקעות נדל"ן ועוד, כל המידע הרלוונטי בקלות וללא מאמץ.',
          'https://menivim.net/projects-for-sale/'
        )]
      }
    })
    .state('forRentList', {
      url: '/for-rent/:page?/?:column?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forRentList',
        page: null
      },
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', '$stateParams', (pageService, $stateParams) => pageService(
          'להשכרה - מניבים. לוח הנדל"ן המסחרי המוביל בישראל',
          'מחפש נכס להשכרה? כנס ללוח הנד"ן מניבים! פה תמצא אלפי נכסים מסחריים, משרדים להשכרה, חנויות להשכרה, מבני תעשייה, השקעות נדל"ן ועוד, כל המידע הרלוונטי בקלות וללא מאמץ.',
          'https://menivim.net/for-rent/' + `${$stateParams.page ? $stateParams.page : ""}`
        )],
      }
    })
    .state('nadlanList', {
      url: '/nadlan-information/:type/:category?/?:column?:query?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        page: null
      },
      resolve: {
        nadlanPage: ['$stateParams', 'pageService', resolveNadlan],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('producingList', {
      url: '/producing/:page?/?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter?:yieldFilter',
      component: 'propertiesListPage',
      params: {
        type: 'producingList',
        page: null
      },
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        // pageService: ['pageService', '$stateParams', (pageService, $stateParams) => pageService(
          // 'נכסים מניבים למכירה - מניבים לוח נדל"ן מניב',
          // 'מחפש נכסים מניבים? כנס ללוח הנדל"ן מניבים! אצלינו תמצא את כל סוגי השקעות הנדל"ן המניב, משרדים, קניונים, תחנות דלק, בתי מלון ועוד. כל המידע הרלוונטי נגיש לכל נכס.',
          // 'https://menivim.net/producing/' + `${$stateParams.page ? $stateParams.page : ""}`
        // )]
        pageService: ['pageService', (pageService) => pageService(
          'נכסים מניבים למכירה - מניבים לוח נדל"ן מניב',
          'מחפש נכסים מניבים? כנס ללוח הנדל"ן מניבים! אצלינו תמצא את כל סוגי השקעות הנדל"ן המניב, משרדים, קניונים, תחנות דלק, בתי מלון ועוד. כל המידע הרלוונטי נגיש לכל נכס.',
          'https://menivim.net/producing/'
        )]
      }
    })
    .state('producingShowDistrict', {
      url: '/producing-district/:district/:page?/?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter?:yieldFilter',
      component: 'propertiesListPage',
      params: {
        type: 'producingList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveProducingDistrict],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('producingShowCity', {
      url: '/producing-city/:city/:page?/?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter?:yieldFilter',
      component: 'propertiesListPage',
      params: {
        type: 'producingList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveProducingCity],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryShow', {
      url: '/category/:category/:page?/:city?/:district?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forSaleList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveCategory],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryShowDistrict', {
      url: '/category-district/:category/:district/:page?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forSaleList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveCategoryDistrict],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryShowCity', {
      url: '/category-city/:category/:city/:page?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forSaleList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveCategoryCity],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryRentShow', {
      url: '/rent-category/:category/:page?/:city?/:district?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forRentList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveRentCategory],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryRentShowDistrict', {
      url: '/rent-category-district/:category/:district/:page?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forRentList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveRentCategoryDistrict],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('categoryRentShowCity', {
      url: '/rent-category-city/:category/:city/:page?/?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forRentList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'categoryService', 'pageService', resolveRentCategoryCity],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('cityShow', {
      url: '/city/:city/:page?/?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forSaleList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'cityService', 'pageService', resolveCityCategory],
        city: ['$state', '$stateParams', 'cityService', resolveCity],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('cityRentShow', {
      url: '/rent-city/:city/:page?/?:category?:query?:kind?:minPrice?:maxPrice?:minSquareMeter?:maxSquareMeter',
      component: 'propertiesListPage',
      params: {
        type: 'forRentList',
        page: null
      },
      resolve: {
        categoryPage: ['$state', '$stateParams', 'cityService', 'pageService', resolveCityCategory],
        city: ['$state', '$stateParams', 'cityService', resolveCity],
        checkUser: ['$state', 'loginService', checkUser]
      }
    })
    .state('orgShow', {
      url: '/org/:orgId/:page?',
      component: 'orgShow',
      params: {
        page: null
      },
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        org: ['$state', '$stateParams', 'orgService', 'pageService', resolveOrg]
      }
    })
    .state('categoryShowAlt', {
      url: '/category/:category',
      redirectTo: 'categoryShow'
    })
    .state('categoryRentShowAlt', {
      url: '/rent-category/:category',
      redirectTo: 'categoryShow'
    })
    .state('cityShowAlt', {
      url: '/city/:city',
      redirectTo: 'cityShow'
    })
    .state('propertyAdd', {
      url: '/property/add',
      component: 'propertyEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('פרסום נכס' + titleSuffix)]
      },
      params: {
        projectId: null
      }
    })
    .state('propertyShow', {
      url: '/property/:id',
      component: 'propertyShow',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        property: ['$state', '$stateParams', 'propertyService', 'pageService', resolveProperty]
      }
    })
    .state('propertyNotAvailable', {
      url: '/not-available/:propertyId',
      component: 'propertyNotAvailable',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('נכס לא זמין' + titleSuffix)]
      }
    })
    .state('propertyEdit', {
      url: '/property/:id/edit',
      component: 'propertyEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        property: ['$rootScope', '$state', '$stateParams', 'propertyService', 'pageService', resolveUserPropertyEdit]
      }
    })
    .state('mailingListForm', {
      url: '/mailing-list',
      component: 'home',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('הרשמה לעדכונים' + titleSuffix)],
        openForm: ['mailingListService', (mailingListService) => mailingListService.openForm()]
      }
    })
    .state('loginForm', {
      url: '/login',
      component: 'home',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('התחברות למערכת' + titleSuffix)],
        openForm: ['$state', 'loginService', ($state, loginService) => {
          return loginService.isUserLoggedIn()
            .catch(() => loginService.openLoginForm())
            .then(() => $state.transitionTo('home'));
        }]
      }
    })
    .state('signUpForm', {
      url: '/sign-up',
      component: 'home',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('הרשמה למערכת' + titleSuffix)],
        openForm: ['$state', 'loginService', ($state, loginService) => {
          return loginService.isUserLoggedIn()
            .catch(() => loginService.openSignUpForm())
            .then(() => $state.transitionTo('home'));
        }]
      }
    })
    .state('projectAdd', {
      url: '/project/add/:type',
      component: 'projectEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('פרסום פרויקט' + titleSuffix)]
      }
    })
    .state('projectShow', {
      url: '/project/:id',
      component: 'projectShow',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        project: ['$state', '$stateParams', 'projectService', 'pageService', resolveProject]
      }
    })
    .state('projectEdit', {
      url: '/project/:id/edit',
      component: 'projectEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        project: ['$rootScope', '$state', '$stateParams', 'projectService', 'pageService', resolveProjectEdit]
      }
    })
    .state('choosePlan', {
      url: '/dashboard/choose-plan',
      component: 'choosePlan',
      params: {
        new: null
      },
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('בחירת מסלול' + titleSuffix)]
      }
    })
    .state('profile', {
      url: '/dashboard/profile',
      component: 'profile',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('הפרופיל שלי' + titleSuffix)]
      }
    })
    .state('myProperties', {
      url: '/dashboard/my-properties',
      component: 'myProperties',
      params: {
        new: null,
        card: null
      },
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('הנכסים שלי' + titleSuffix)]
      }
    })
    .state('savedProperties', {
      url: '/dashboard/saved-properties',
      component: 'savedProperties',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('נכסים ששמרתי' + titleSuffix)]
      }
    })
    .state('savedSearches', {
      url: '/dashboard/saved-searches',
      component: 'savedSearches',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('חיפושים שמורים' + titleSuffix)]
      }
    })
    .state('messages', {
      url: '/dashboard/messages?:type',
      component: 'messages',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('הודעות' + titleSuffix)]
      }
    })
    .state('messageShow', {
      url: '/dashboard/messages/:id?:type',
      component: 'messageShow',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('הודעות' + titleSuffix)]
      }
    })
    .state('payments', {
      abstract: true,
      url: '/dashboard/payments',
      component: 'payments',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', userAuth],
        pageService: ['pageService', (pageService) => pageService('תשלומים' + titleSuffix)]
      }
    })
    .state('leadsMain', {
      url: '/dashboard/leads',
      component: 'leadsMain',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', '$stateParams', userAuth],
        pageService: ['pageService', (pageService) => pageService('לידים' + titleSuffix)]
      }
    })
    .state('manageProperties', {
      url: '/manage/manage-properties?:status',
      component: 'manageProperties',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול נכסים' + titleSuffix)]
      }
    })
    .state('manageProjects', {
      url: '/manage/manage-projects?:status',
      component: 'manageProjects',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול פרויקטים' + titleSuffix)]
      }
    })
    .state('manageProjectsLeads', {
      url: '/manage/manage-projects-leads',
      component: 'manageProjectsLeads',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('לידים של פרוקיטים' + titleSuffix)]
      }
    })
    .state('manageUserList', {
      url: '/manage/manage-user',
      component: 'manageUserList',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול משתמשים' + titleSuffix)]
      }
    })
    .state('managePlugins', {
      url: '/manage/manage-plugins',
      component: 'managePlugins',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', 'orgService', (pageService) => pageService('ניהול פלאגנים' + titleSuffix)]
      }
    })
    .state('managePlugin', {
      url: '/manage/manage-plugin',
      component: 'managePlugin',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', 'orgService', (pageService) => pageService('עריכת פלאגין' + titleSuffix)]
      }
    })
    .state('manageMailingList', {
      url: '/manage/manage-mailing-list',
      component: 'manageMailingList',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול רשימת תפוצה' + titleSuffix)]
      }
    })
    .state('manageAddress', {
      url: '/manage/manage-address',
      component: 'manageAddress',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול רשימת תפוצה' + titleSuffix)]
      }
    })
    .state('manageBanners', {
      url: '/manage/manage-banners?:status',
      component: 'manageBanners',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('ניהול באנרים' + titleSuffix)]
      }
    })
    .state('bannerAdd', {
      url: '/manage/manage-banners/add',
      component: 'bannerEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('באנר חדש' + titleSuffix)]
      }
    })
    .state('bannerEdit', {
      url: '/manage/manage-banners/:id/edit', // !TODO: fix 'active' class for this nested route
      component: 'bannerEdit',
      resolve: {
        user: ['$state', '$uibModal', 'loginService', adminAuth],
        pageService: ['pageService', (pageService) => pageService('עריכת באנר' + titleSuffix)]
      }
    })
    .state('terms', {
      url: '/terms',
      component: 'terms',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('תנאי שימוש' + titleSuffix)]
      }
    })
    .state('about', {
      url: '/about',
      component: 'about',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('אודות מניבים ישראל' + titleSuffix)]
      }
    })
    .state('siteMap', {
      url: '/site-map',
      component: 'siteMap',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('אודות מניבים ישראל' + titleSuffix)]
      }
    })
    .state('accessibility', {
      url: '/accessibility',
      component: 'accessibility',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('אודות מניבים ישראל' + titleSuffix)]
      }
    })
    .state('newPassword', {
      url: '/new-password?email?token',
      component: 'newPassword',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('מניבים ישראל - בחר סיסמה חדשה')]
      }
    })
    .state('unsubscribe', {
      url: '/unsubscribe?email?mailingId',
      component: 'unsubscribe',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('מניבים ישראל - הסרה מרשימת תפוצה')]
      }
    })
    .state('cancelMonthlySummaryEmail', {
      url: '/cancelMonthlySummaryEmail?ID&action',
      component: 'cancelMonthlySummaryEmail',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('מניבים ישראל - הסרה מרשימת תפוצה חודשית')]
      }
    })
    .state('deleteItem', {
      url: '/deleteItem?ID',
      component: 'deleteItem',
      resolve: {
        pageService: ['pageService', (pageService) => pageService('מניבים ישראל - מחיקת מודעה')]
      }
    })
    .state('p404', {
      url: '/404',
      component: 'p404',
      resolve: {
        checkUser: ['$state', 'loginService', checkUser],
        pageService: ['pageService', (pageService) => pageService('דף לא נמצא - 404' + titleSuffix)]
      }
    });
}];

function checkAuth ($state, $uibModal, loginService, permission) {
  return new Promise((resolve, reject) => {
    loginService.isAuthenticated()
      .then((res) => {
        if (res.data.status === 'success') {
          const user = res.data.data;
          if (!user) {
            return Promise.reject(new Error('No user'));
          }

          if (!permission || user.permission === permission) {
            return resolve(user);
          }
        }
        return Promise.reject(new Error(''));
      })
      .catch(() => {
        $uibModal.open({
          component: 'loginContainer',
          windowClass: 'login-modal',
          size: 'sm'
        })
          .result
          .then((user) => {
            if (!permission || user.permission === permission) {
              return resolve(user);
            }
            return reject(new Error('no user'));
          })
          .catch((error) => reject(error));
      });
  });
}

function userAuth ($state, $uibModal, loginService) {
  return checkAuth($state, $uibModal, loginService, null);
}

function nanagerAuth ($state, $uibModal, loginService) {
  return checkAuth($state, $uibModal, loginService, 'nanager');
}

function adminAuth ($state, $uibModal, loginService) {
  return checkAuth($state, $uibModal, loginService, 'admin');
}

function checkUser ($state, loginService) {
  return loginService.checkUser();
}

function logout ($state, loginService) {
  loginService.logout()
    .then((resLogout) => {
      $state.transitionTo('home');
    });
}

function resolveProperty ($state, $stateParams, propertyService, pageService) {
  return propertyService.getProperty($stateParams.id)
    .then((property) => {
      if (!property) {
        return $state.transitionTo('propertyNotAvailable', { propertyId: $stateParams.id });
      }

      const title = 'נכס ' + (property.type === 'forRent' ? 'להשכרה' : 'למכירה') + (property.title ? ' - ' + property.title : '') + titleSuffix;
      const description = (property.description || '').replace(/\r?\n|\r/g, '');
      pageService(title, description, "https://menivim.net/property/"+property.id);

      return property;
    })
    .catch(() => $state.transitionTo('propertyNotAvailable', { propertyId: $stateParams.id }));
}

function resolveUserPropertyEdit ($rootScope, $state, $stateParams, propertyService, pageService) {
  return resolveProperty($state, $stateParams, propertyService, pageService)
    .then((property) => {
      if (_.get($rootScope, 'org.id') !== property.org.id && !$rootScope.isAdmin()) {
        return $state.transitionTo('propertyShow', { id: property.id });
      }
      pageService('עריכת נכס - ' + document.title);
      return property;
    });
}


function resolveNadlan ($stateParams, pageService) {
  let url = `https://menivim.net/nadlan-information/${$stateParams.type}/`;
  let title = 'מידע נדל"ן (2024) - מניבים הלוח לנדל"ן מסחרי';
  let description = 'מידע נדל"ן מתעדכן מדי יום. אתר מניבים מאפשר לך לצפות בהיסטוריית הנדל"ן של כל הנכסים המסחריים בישראל למכירה ולהשכרה כולל מגרשים, חנויות, משרדים, מחסנים ועוד.';

  if ($stateParams.category) {
    url += $stateParams.category;
    if (options.propertySaleCategoryOptionsNadlan[$stateParams.category]) {
      let property = options.propertySaleCategoryOptionsNadlan[$stateParams.category];
      if (property.googleTitle) {
        title = property.googleTitle;
        description = property.description;
      }
    }
  }

  return pageService(title, description, url);
}

function resolveCategoryDistrict ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forSaleList[stateParamsCategory] || !$stateParams.district) {
    return $state.transitionTo('home');
  }
  let title = categoryService.forSaleList[stateParamsCategory].title;
  let description = categoryService.forSaleList[stateParamsCategory].description;
  if (options.propertySaleCategoryOptions[stateParamsCategory]) {
    const property = options.propertySaleCategoryOptions[stateParamsCategory];
    const district = categoryService.districtCategorySideNav[$stateParams.district]
    if (property.cityTitle && district) {
      title = property.cityTitle.replaceAll("$city$", district);;
      description = property.cityDescription.replaceAll("$city$", district);;
    }
  }
  pageService(title, description, `https://menivim.net/category-district/${stateParamsCategory}/${$stateParams.district}/${page}`);

  return categoryService.forSaleList[stateParamsCategory];
}
function resolveCategoryCity ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forSaleList[stateParamsCategory] || !$stateParams.city) {
    return $state.transitionTo('home');
  }
  let title = categoryService.forSaleList[stateParamsCategory].title;
  let description = categoryService.forSaleList[stateParamsCategory].description;
  if (options.propertySaleCategoryOptions[stateParamsCategory]) {
    const property = options.propertySaleCategoryOptions[stateParamsCategory];
    const city = categoryService.cityCategorySideNav[$stateParams.city]
    if (property.cityTitle && city) {
      title = property.cityTitle.replaceAll("$city$", city);;
      description = property.cityDescription.replaceAll("$city$", city);;
    }
  }
  pageService(title, description, `https://menivim.net/category-city/${stateParamsCategory}/${$stateParams.city}/${page}`);

  return categoryService.forSaleList[stateParamsCategory];
}
function resolveProducingCity ($state, $stateParams, categoryService, pageService) {
  let title = "נכסים  מניבים";
  let description = "נכסים  מניבים";
  if (categoryService.cityCategorySideNav[$stateParams.city]) {
    const city = categoryService.cityCategorySideNav[$stateParams.city]
    if (city) {
      let t = 'נכסים  מניבים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      title = t.replaceAll("$city$", city);;
      description = t.replaceAll("$city$", city);;
    }
  }
  pageService(title, description, `https://menivim.net/producing-city/${$stateParams.city}/`);
  return $stateParams.city;
}

function resolveProducingDistrict ($state, $stateParams, categoryService, pageService) {
  let title = "נכסים  מניבים";
  let description = "נכסים  מניבים";
  if (categoryService.districtCategorySideNav[$stateParams.district]) {
    const district = categoryService.districtCategorySideNav[$stateParams.district]
    if (district) {
      let t = 'נכסים  מניבים למכירה ב$city$ - מניבים. הלוח לנדל"ן מסחרי'
      title = t.replaceAll("$city$", district);;
      description = t.replaceAll("$city$", district);;
    }
  }
  pageService(title, description, `https://menivim.net/category-district/${$stateParams.district}/`);
  return $stateParams.district;
}

function resolveCategory ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forSaleList[stateParamsCategory]) {
    return $state.transitionTo('home');
  }

  let title = categoryService.forSaleList[stateParamsCategory].title;
  let description = categoryService.forSaleList[stateParamsCategory].description;
  if (options.propertySaleCategoryOptions[stateParamsCategory]) {
    let property = options.propertySaleCategoryOptions[stateParamsCategory];
    if (property.googleTitle) {
      title = property.googleTitle;
      description = property.description;
    }
  }
  pageService(title, description, `https://menivim.net/category/${stateParamsCategory}/${page}`);

  return categoryService.forSaleList[stateParamsCategory];
}
function resolveRentCategoryDistrict ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forRentList[stateParamsCategory] || !$stateParams.district) {
    return $state.transitionTo('home');
  }
  let title = categoryService.forRentList[stateParamsCategory].title;
  let description = categoryService.forRentList[stateParamsCategory].description;
  if (options.propertyRentCategoryOptions[stateParamsCategory]) {
    const property = options.propertyRentCategoryOptions[stateParamsCategory];
    const district = categoryService.districtCategorySideNav[$stateParams.district]
    if (property.cityTitle && district) {
      title = property.cityTitle.replaceAll("$city$", district);;
      description = property.cityDescription.replaceAll("$city$", district);;
    }
  }
  pageService(title, description, `https://menivim.net/rent-category-district/${stateParamsCategory}/${$stateParams.district}/${page}`);

  return categoryService.forRentList[stateParamsCategory];
}
function resolveRentCategoryCity ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forRentList[stateParamsCategory] || !$stateParams.city) {
    return $state.transitionTo('home');
  }

  let title = categoryService.forRentList[stateParamsCategory].title;
  let description = categoryService.forRentList[stateParamsCategory].description;
  if (options.propertyRentCategoryOptions[stateParamsCategory]) {
    const property = options.propertyRentCategoryOptions[stateParamsCategory];
    const city = categoryService.cityCategorySideNav[$stateParams.city]
    if (property.cityTitle && city) {
      title = property.cityTitle.replaceAll("$city$", city);;
      description = property.cityDescription.replaceAll("$city$", city);;
    }
  }
  pageService(title, description, `https://menivim.net/rent-category-city/${stateParamsCategory}/${$stateParams.city}/${page}`);

  return categoryService.forRentList[stateParamsCategory];
}
function resolveRentCategory ($state, $stateParams, categoryService, pageService) {
  const stateParamsCategory = $stateParams.category;
  const page = $stateParams.page ? $stateParams.page : "";
  if (!categoryService.forRentList[stateParamsCategory]) {
    return $state.transitionTo('home');
  }

  let title = categoryService.forRentList[stateParamsCategory].title;
  let description = categoryService.forRentList[stateParamsCategory].description;
  if (options.propertyRentCategoryOptions[stateParamsCategory]) {
    let property = options.propertyRentCategoryOptions[stateParamsCategory];
    if (property.googleTitle) {
      title = property.googleTitle;
      description = property.description;
    }
  }
  pageService(title, description, `https://menivim.net/rent-category/${stateParamsCategory}/${page}`);

  return categoryService.forRentList[stateParamsCategory];
}


function resolveCityCategory ($state, $stateParams, cityService, pageService) {
  const stateParamsCity = $stateParams.city;
  const city = cityService.list[stateParamsCity];
  const page = $stateParams.page ? $stateParams.page : "";
  if (!city) {
    return $state.transitionTo('home');
  }
  let property = 'נדל"ן מסחרי';
  if (options.propertySaleCategoryOptions[$stateParams.category]) {
    property = options.propertySaleCategoryOptions[$stateParams.category].title;
  }

  const h1 = `${property} ${$stateParams.type === 'forSaleList' ? 'למכירה' : 'להשכרה'} ב${city.label}`;

  if (city.title) {
    const title = city.title.replace(/{%h1}/g, h1);
    const description = city.description.replace(/{%h1}/g, h1);
    const saleOrRentCity = $stateParams.type === 'forSaleList' ? 'city' : 'rent-city';
    pageService(title, description, `https://menivim.net/${saleOrRentCity}/${stateParamsCity}/${page}`);
  }
  _.set(city, 'h1', h1);
  return city;
}

function resolveCity ($state, $stateParams, cityService) {
  const city = cityService.list[$stateParams.city];
  if (!city) { return $state.transitionTo('home'); }
  return city.label;
}

function resolveOrg ($state, $stateParams, orgService, pageService) {
  return orgService.getOrg($stateParams.orgId, true)
    .then((org) => {
      if (!org) { return $state.transitionTo('home'); }
      const orgName = org.name;
      const orgUser = _.get(org, 'users.0') || {};
      const orgUserName = [orgUser.firstName, orgUser.lastName].join(' ').trim();
      const pageTitle = [(orgName || orgUserName), 'מניבים לוח נדל"ן מסחרי'].filter((value) => !!value).join(' | ');
      pageService(pageTitle, pageTitle, `https://menivim.net/org/${$stateParams.orgId}/`);
      return org;
    })
    .catch(() =>
      $state.transitionTo('home')
    );
}

function resolveProject ($state, $stateParams, projectService, pageService) {
  return projectService.getProject($stateParams.id)
    .then((project) => {
      if (!project) {
        return $state.transitionTo('projectNotAvailable', { projectId: $stateParams.id });
      }

      const title = project.title + ' - ' + project.title + titleSuffix;
      const description = (project.description || '').replace(/\r?\n|\r/g, '');
      pageService(title, description);

      return project;
    })
    .catch(() => $state.transitionTo('home'));
}

function resolveProjectEdit ($rootScope, $state, $stateParams, projectService, pageService) {
  return resolveProject($state, $stateParams, projectService, pageService)
    .then((project) => {
      if (_.get($rootScope, 'user.id') !== project.userId && !$rootScope.isAdmin()) {
        return $state.transitionTo('projectShow', { id: project.id });
      }
      pageService('עריכת פרויקט - ' + document.title);
      return project;
    });
}
