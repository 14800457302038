import angular from 'angular';

import messageShowComponent from './message-show.component';

const messageShowModule = angular.module('messageShow', [
])
  .component('messageShow', messageShowComponent)
  .name;

export default messageShowModule;
