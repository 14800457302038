import _ from 'lodash';
import moment from 'moment';
moment.locale('he');

export default function ($scope, $rootScope, $state, $timeout, manageService, utils, $uibModal) {
  let vm;

  class ManageProjectsLeadsController {
    constructor () {
      vm = this;
      vm.properties = [];

      vm.months = [
        '12-2020',
        '01-2021',
        '02-2021',
        '03-2021',
        '04-2021',
        '05-2021',
        '06-2021',
        '07-2021',
        '08-2021',
        '09-2021',
        '10-2021',
        '11-2021',
        '12-2021',
        '01-2022',
        '02-2022',
        '03-2022',
        '04-2022'
      ];
      vm.types = {
        projectSendMessageLead: 'ליד',
        projectSendMessageForm: 'שלח הודעה',
        projectShowPhone: 'התקשר',
        projectShow: 'צפה'
      };

      vm.orderBy = 'createdAt';
      vm.type = 'projectSendMessageLead';
      vm.projectFilter = '';

      vm.getCurrentMonth();
    }

    $onInit () {
      vm.paging = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 50,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.typeFor = localStorage.getItem('manageforSale');
      if (!vm.typeFor) {
        vm.typeFor = 'forSale';
      }
      localStorage.setItem('manageforSale', vm.typeFor);

      vm.getProjectsLeads();
      vm.getProjects();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    getProjects () {
      manageService.getManageProjects(null, null, null, { limit: 1000 }, vm.typeFor)
        .then((res) => {
          const myProjects = {};
          if (res.rows.length) {
            res.rows.map((project) => {
              myProjects[project.id] = project.title;
            });
          }
          vm.projects = myProjects;
        });
    }

    getProjectsLeads () {
      manageService.getProjectsLeads({
        filter: {
          limit: vm.paging.limit,
          offset: vm.paging.offset,
          order: vm.paging.orderBy,
          desc: vm.paging.orderDesc,
          type: vm.type,
          project: vm.projectFilter,
          month: vm.monthFilter
        }
      })
        .then((res) => {
          if (res.rows && res.rows.length) {
            res.rows.map((lead) => {
              lead.data = lead.data !== '' ? JSON.parse(lead.data) : {};
            });
          }
          vm.list = res;
          vm.setPagingFlags();
        });
    }

    // on change filter
    changeFilter () {
      vm.getProjectsLeads();
    }

    changePropertyType (type) {
      if (vm.typeFor !== type) {
        vm.typeFor = type;
        localStorage.setItem('manageforSaleLeads', type);
        vm.getProjects();
      }
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getProjectsLeads();
    }

    getCurrentMonth () {
      vm.monthFilter = `${moment().format('MM')}-${moment().format('YYYY')}`;
    }
  }

  return new ManageProjectsLeadsController();
}
