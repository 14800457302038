
export default function ($scope, $state, $timeout, request) {
  let vm;

  class cancelMonthlySummaryEmailCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.orgId = $state.params.ID;
      vm.action = $state.params.action;

      if (!vm.orgId || !vm.action) {
        $state.go('home');
        return;
      }

      if (vm.action === "1") {
        vm.blockingAlert(vm.orgId).then(r => {
          if (r.blockMonthlyEmail) {
            vm.successMessage = true;
            $timeout(() => {
              $state.go('home');
            }, 5000);
          }
          else {
            alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home')
          }
        })
          .catch(error => { alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home') });
      }

      if (vm.action === "2") {
        vm.blockrelevantEmail(vm.orgId).then(r => {
          if (r.blockrelevantEmail) {
            vm.successMessage = true;
            $timeout(() => {
              $state.go('home');
            }, 5000);
          }
          else {
            alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home')
          }
        })
          .catch(error => { alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home') });
      }

      if (vm.action === "3") {
        vm.blockpdateRequestBlockEmail(vm.orgId).then(r => {
          if (r.blockpdateRequestBlockEmail) {
            vm.successMessage = true;
            $timeout(() => {
              $state.go('home');
            }, 5000);
          }
          else {
            alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home')
          }
        })
          .catch(error => { alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home') });
      }

      if (vm.action === "4") {
        vm.blockEmailPostingAnAd(vm.orgId).then(r => {
          if (r.blockEmailPostingAnAd) {
            vm.successMessage = true;
            $timeout(() => {
              $state.go('home');
            }, 5000);
          }
          else {
            alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home')
          }
        })
          .catch(error => { alert("שגיאה - אנא נסה שנית בפעם אחרת"), $state.go('home') });
      }
    }

    blockingAlert (orgId) {
      return request('/org/BlockMonthlyEmail', { orgId }, 'POST')
        .then((res) => res.data.data);
    }
    blockrelevantEmail (orgId) {
      return request('/org/blockrelevantEmail', { orgId }, 'POST')
        .then((res) => res.data.data);
    }
    blockpdateRequestBlockEmail (orgId) {
      return request('/org/blockpdateRequestBlockEmail', { orgId }, 'POST')
        .then((res) => res.data.data);
    }
    blockEmailPostingAnAd (orgId) {
      return request('/org/blockEmailPostingAnAd', { orgId }, 'POST')
        .then((res) => res.data.data);
    }
  }
  return new cancelMonthlySummaryEmailCtrl();
}
