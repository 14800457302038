import template from './property-files-edit.html';
import controller from './property-files-edit.controller';
import './property-files-edit.scss';

const propertyFilesEditComponent = {
  require: {
    propertyEditCtrl: '^propertyEdit'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'utils'];

export default propertyFilesEditComponent;
