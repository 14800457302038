import angular from 'angular';

import savedSearchesComponent from './saved-searches.component';
import savedSearchesService from './saved-searches.service';

const savedSearchesModule = angular.module('savedSearches', [
  savedSearchesService
])
  .component('savedSearches', savedSearchesComponent)
  .name;

export default savedSearchesModule;
