import template from './select-property-category.html';
import controller from './select-property-category.controller';
import './select-property-category.scss';

const selectPropertyCategoryComponent = {
  bindings: {
    model: '=',
    businesses: '@',
    type: '@',
    direction: '@',
    producingFilterModel: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$uibModal'];

export default selectPropertyCategoryComponent;
