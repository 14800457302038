import template from './manage-plugins.html';
import controller from './manage-plugins.controller';
import './manage-plugins.scss';

const ManagePluginsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'manageService', 'utils', '$uibModal'];

export default ManagePluginsComponent;
