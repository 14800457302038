// import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $timeout, $uibModal, savedPropertiesService, utils) {
  let vm;

  class SavedPropertiesController {
    constructor () {
      vm = this;

      vm.orderByOptions = [
        { name: 'publishedAt', desc: true, label: 'פורסם לאחרונה' },
        { name: 'price', desc: false, label: 'מחיר (מהנמוך לגבוה)' },
        { name: 'price', desc: true, label: 'מחיר (מהגבוה לנמוך)' }
      ];

      vm.typeOptions = options.propertyTypeOptions;
    }

    $onInit () {
      vm.paging = {
        orderBy: 'publishedAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.getSavedProperties();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getSavedProperties();
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getSavedProperties () {
      vm.loadProgress = true;

      return savedPropertiesService.getSavedProperties({
        limit: vm.paging.limit,
        offset: vm.paging.offset,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      })
        .then((res) => {
          vm.list = res;
          vm.setPagingFlags();
        })
        .finally(() => {
          vm.loadProgress = false;
        });
    }

    unsaveProperty (propertyId) {
      savedPropertiesService.unsaveProperty(propertyId)
        .then(() => {
          vm.getSavedProperties();
        });
    }
  }

  return new SavedPropertiesController();
}
