import jquery from 'jquery';
import _ from 'lodash';

let request;

class SavedPropertiesService {
  constructor (_$rootScope, _request) {
    request = _request;
  }

  getSavedProperties (queryParams) {
    return request('/saved-properties?' + (queryParams ? jquery.param(queryParams) : ''))
      .then((res) => res.data.data);
  }

  saveProperty (property) {
    const propertyId = _.get(property, 'id');
    return request('/saved-properties', { propertyId }, 'POST')
      .then((res) => res.data.data);
  }

  unsaveProperty (propertyId) {
    return request('/saved-properties', { propertyId }, 'DELETE')
      .then((res) => res.data.data);
  }

  toggle (property) {
    const propertyId = _.get(property, 'id');
    return request('/saved-properties/toggle', { propertyId }, 'POST')
      .then((res) => res.data.data);
  }
}

SavedPropertiesService.$inject = ['$rootScope', 'request'];

const savedPropertiesServiceModule = angular.module('savedPropertiesService', [])
  .service('savedPropertiesService', SavedPropertiesService)
  .name;

export default savedPropertiesServiceModule;
