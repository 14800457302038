import template from './banner-edit.html';
import controller from './banner-edit.controller';
import './banner-edit.scss';

const bannerEditComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', 'bannerService', 'userService', 'utils'];

export default bannerEditComponent;
