import template from './properties-list.html';
import controller from './properties-list.controller';
import './properties-list.scss';

const propertiesListComponent = {
  bindings: {
    type: '<?',
    filters: '<?',
    title: '<?',
    hideProjectAds: '<?',
    popup: '&',
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', '$sce', 'propertyService'];

export default propertiesListComponent;
