import _ from 'lodash';
import options from '../../common/options';

const KEY_EVENT_ENTER = 13;

export default function ($scope, $state, $timeout, utils, request) {
  let vm;

  class HomeController {
    constructor () {
      vm = this;
      vm.name = 'home';

      vm.type = 'forSale';
      vm.searchFields = {
        category: [],
        producingOnly: false
      };

      function sortCategory (list) {
        return Object.keys(list).map(key => list[key])
      }

      vm.categoryList = {
        forSale: sortCategory(options.propertySaleCategoryOptions),
        forRent: sortCategory(options.propertyRentCategoryOptions)
      };

      vm.categoryList2 = {
        forSaleList: options.propertySaleCategoryOptions,
        categoryShowCity: options.propertySaleCategoryOptions,
        categoryShow: options.propertySaleCategoryOptions,
        forRentList: options.propertyRentCategoryOptions,
        categoryRentShow: options.propertyRentCategoryOptions,
        categoryRentShowCity: options.propertyRentCategoryOptions,
      };

      vm.HebrewType = {
        forSaleList: "למכירה",
        categoryShowCity: "למכירה",
        categoryShow: "למכירה",
        forRentList: "להשכרה",
        categoryRentShow: "להשכרה",
        categoryRentShowCity: "להשכרה",
      }
    }

    $onInit () {
      $(window).on('scroll', vm.doParallax);
      if (vm.getCookie("recentSearches")) {
        vm.recentSearches = JSON.parse(vm.getCookie("recentSearches")).slice(-3).reverse()
      }
    }

    $onDestroy () {
      $(window).off('scroll', vm.doParallax);
    }

    doParallax () {
      if (!vm.backgroundWrapperElement) {
        vm.backgroundWrapperElement = $('.home-background-wrapper')[0];
      }
      vm.backgroundWrapperElement.style.transform = 'translateY(' + (window.pageYOffset / 4) + 'px)';
    }

    selectType (type) {
      vm.type = type;
      vm.searchFields.producingOnly = false;
    }

    async doQuery () {
      let state;
      if (vm.type === 'forRent') {
        state = 'forRentList';
      } else if (vm.searchFields.producingOnly) {
        state = 'producingList';
      } else {
        state = 'forSaleList';
      }

      let column = vm.searchFields.column ? JSON.stringify(vm.searchFields.column) : "";
      const category = vm.searchFields.category.join(',') || '';
      // const query = vm.searchFields.query;
      const query = await vm.cityExists(vm.searchFields.query);
      vm.addSearch(column, category, query, state);
      $state.go(state, { column, category, query });
    }

    cityExists (search) {
      if (search === "תל אביב") { return "תל אביב-יפו" }
      if (!search) { return "" }
      return request('/address/cityExists', { search: search }, 'POST')
        .then((res) => {
          return res.data.data ? res.data.data.cityName : ""
        })
    }

    setupEnterKeyPress () {
      $('#search-form').on('keydown keypress', (event) => {
        if (event.which === KEY_EVENT_ENTER) {
          vm.doQuery();
          event.preventDefault();
        }
      });
    }

    deleteFromRecentSearches (key) {
      let arr = JSON.parse(vm.getCookie("recentSearches"))
      let newArr = arr.filter(i => i.key !== key)
      document.cookie = `recentSearches=${JSON.stringify(newArr)}; path=/`;
      vm.recentSearches = newArr.slice(-3).reverse();
    }

    categorysToString (state, categorys) {
      let arr = []
      categorys.split(',').forEach((category) => {
        arr.push(vm.categoryList2[state][category].title)
      });
      return ', ' + arr.join(', ')
    }

    getCookie (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    addSearch (column, category, query, state) {
      if (column) {
        let arr = [];
        if (vm.getCookie("recentSearches")) {
          arr = JSON.parse(vm.getCookie("recentSearches"))
        }
        if (arr.length < 10 && (vm.searchFields.column.city || vm.searchFields.column.district)) {
          const city = vm.searchFields.column.city ? vm.searchFields.column.city : vm.searchFields.column.district
          let url = "";
          if (state === "forRentList") {
            url += '/for-rent/?category=' + category + '&query=' + query;
          }
          else {
            url += '/for-sale/?category=' + category + '&query=' + query;
          }
          url += '&column=' + column;
          let exist = arr.find(i => i.key === city);
          if (exist) {
            let newArr = arr.filter(i => i.key !== exist.key)
            newArr.push({ key: city, url: url, category: category, state: state, query: query })
            arr = newArr;
          }
          else {
            arr.push({ key: city, url: url, category: category, state: state, query: query });
          }
          document.cookie = `recentSearches=${JSON.stringify(arr)}; path=/`;
        }
      }
    }
  }

  return new HomeController();
}
