import _ from 'lodash';
import zxcvbn from 'zxcvbn';
import isEmail from 'validator/lib/isEmail';

export default function ($scope, $rootScope, $state, $timeout, request, utils) {
  let vm;

  class CompanyEmployeesController {
    constructor () {
      vm = this;
      vm.errorMessage = "";
      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      };
    }

    $onInit () {
      vm.successMessage = false;
      vm.signUpFields = {};
      vm.sendProgress = false;
      $('#sign-up-first-name').focus();

      $scope.$watchGroup(['vm.signUpFields.password', 'vm.signUpFields.password2'], (newVal, oldVal) => {
        if (vm.signUpForm.password) {
          vm.signUpForm.password2.$setValidity('passwordMatch', vm.signUpFields.password === vm.signUpFields.password2);
        }
      });
      $scope.$watch('vm.signUpFields.email', (newVal, oldVal) => {
        if (vm.signUpForm?.email) {
          vm.signUpForm.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.signUpFields.email || '');
          vm.signUpForm.email.$setValidity('email', isValidEmail);
          $('#sign-up-email')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });
    }

    closeModalBox () {
      vm.modalInstance.close();
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    checkPassword () {
      vm.result = zxcvbn(vm.signUpForm.password.$viewValue);
    }

    async send () {
      vm.errorMessage = "";
      if (vm.sendProgress) { return; }
      if (vm.signUpForm.$invalid) {
        $('#sign-up-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.signUpForm);
        return;
      }
      let emailIsExist = false;
      const email = (vm.signUpFields.email || '').replace(/[\u{0080}-\u{FFFF}]/gu, '');
      await request('/sign-up/check-registered', { email })
        .then((result) => {
          emailIsExist = result.data.data;
        });

      if (emailIsExist) {
        vm.signUpForm.email.$setValidity('registeredEmail', false);
        vm.errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
        return;
      }
      vm.sendProgress = true;

      vm.signUpFields.orgId = $rootScope.org.id
      return request('/sign-up/addUserToOrg', {
        userDetails: vm.signUpFields
      })
        .then(() => {
          vm.successMessage = true;
          $timeout(() => {
            location.reload();
            vm.closeModalBox();
          }, 2000);
        })
        .catch((error) => {
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            vm.errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            vm.errorMessage = 'דוא"ל לא תקין';
          }
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

  }

  return new CompanyEmployeesController();
}