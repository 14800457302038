// import _ from 'lodash';
// import moment from 'moment';
import isUrl from 'is-url';

export default function ($scope, $rootScope, $state, $window, $timeout, $uibModal, utils, paymentsService) {
  let vm;
  const $ = window.$;

  class PaymentsCardsController {
    constructor () {
      vm = this;
      vm.name = 'payments';
    }

    $onInit () {
      vm.cardsLoaded = false;
      vm.loadCards();
      vm.cardcomParameters = {
        Operation: $state.params.Operation,
        ResponseCode: $state.params.ResponseCode,
        Status: $state.params.Status
      };
      window.history.replaceState({}, document.title, '/dashboard/payments/cards');
    }

    loadCards () {
      return paymentsService.getOrgCards()
        .then((res) => {
          vm.cardsLoaded = true;
          vm.cards = res;
        });
    }

    getOrgAddCardPage () {
      if (vm.getLinkProgress) {
        return;
      }
      vm.getLinkProgress = true;
      return paymentsService.getOrgAddCardUrl($rootScope.org.id, '/dashboard/payments/cards')
        .then((res) => {
          if (isUrl(res)) {
            $timeout(() => {
              window.open(res, '_self');
            });
          }
        })
        .finally(() => {
          vm.getLinkProgress = false;
        });
    }

    deleteCard (cardId, event) {
      vm.progressAnimation(event);
      return paymentsService.deleteCard(cardId)
        .then((res) => {
          vm.loadCards();
        });
    }

    confirmDeleteCard (cardId, event) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק כרטיס אשראי?',
        message: 'לא ניתן יהיה לשחזר את הפעולה.',
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'מחק את הכרטיס',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.deleteCard(cardId, event);
            $uibModalInstance.close();
          }
        }
        ]
      };

      utils.modalAlert(modalParams);
    }

    progressAnimation (event) {
      $(event.currentTarget).find('i.fa').attr('class', 'fa fa-spinner fa-spin');
    }
  }

  return new PaymentsCardsController();
}
