import _ from 'lodash';

export default function ($scope, $state, $timeout) {
  let vm;

  class PropertyShareController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.property = vm.resolve.property;
      const pageLink = 'https://menivim.net/property/' + vm.property.id;
      const pageTitle = encodeURIComponent(document.title || '');
      vm.socialLinks = {
        whatsapp: 'https://wa.me/?text=' + pageTitle + '+ +' + pageLink,
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=' + pageLink + '&title=' + pageTitle + '&quote=' + pageTitle,
        twitter: 'https://twitter.com/intent/tweet?url=' + pageLink + '&text=' + pageTitle,
        telegram: 'https://telegram.me/share/url?url=' + pageLink + '&text=' + pageTitle,
        copy: pageLink
      };
      vm.propertyAddressString = (_.get(vm, 'property.address') || '').replace(/, ישראל$/, '');
    }

    share (media) {
      if (media === 'copy') {
        navigator.clipboard.writeText(vm.socialLinks[media]).then(function () {
            let copy = document.getElementById('copy')
            copy.setAttribute('class', 'copied')
            copy.innerHTML = "<p>הקישור הועתק</p>"
        });
      }
      else {
        window.open(vm.socialLinks[media], '_blank', 'width=600,height=509');
      }
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    getFileUrl () {
      const file = vm.property.files[0];
      if (!file) { return ''; }
      return `${window.SERVER_URL}/file/property/${vm.property.id}/${file.name}_350x227${file.ext}`;
    }
  }

  return new PropertyShareController();
}
