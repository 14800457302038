import angular from 'angular';
import loginContainerComponent from './login-container.component';
import completeSignUp from './complete-sign-up/complete-sign-up';
import login from './login/login';
import restorePassword from './restore-password/restore-password';
import signUp from './sign-up/sign-up';
import loginService from './login.service';

const loginContainerModule = angular.module('loginContainer', [
  completeSignUp,
  login,
  restorePassword,
  signUp,
  loginService
])
  .component('loginContainer', loginContainerComponent)
  .name;

export default loginContainerModule;
