import template from './choose-plan.html';
import controller from './choose-plan.controller';
import './choose-plan.scss';

const choosePlanComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [''];

export default choosePlanComponent;
