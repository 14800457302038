import _ from 'lodash';

export default function ($scope, $timeout, utils) {
  let vm;

  class TermsController {
    constructor () {
      vm = this;
      vm.name = 'about';
    }

    $onInit () {

    }
  }

  return new TermsController();
}
