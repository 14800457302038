import _ from 'lodash';
import moment from 'moment';
moment.locale('he');
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $stateParams, $timeout, propertyService, projectService, userService, utils, profileService, $uibModal) {
  let vm;

  class PropertyEditController {
    constructor () {
      vm = this;
      vm.selectedCompanyEmployees = [];
      vm.modelObject = {
        type: 'forSale',
        userId: _.get($rootScope, 'user.id') || '',
        publishedAt: moment().toISOString(),
        paymentExpiresAt: moment().add(3, 'months'),
        projectId: _.get($stateParams, 'projectId') || null
      };
      vm.businessesList = options.propertyBusinessesForSaleOptions;
      vm.saleCategoryList = options.propertySaleCategoryOptions;
      vm.rentCategoryList = options.propertyRentCategoryOptions;
      vm.statusOptions = options.propertyStatusOptions;
      vm.rentCheckboxFields = options.rentCheckboxFields;
      vm.rentSpaceCheckboxFields = options.rentSpaceCheckboxFields;
      vm.businessesForSaleCheckboxFields = options.businessesForSaleCheckboxFields;
      vm.propertyApartmentsInvestmentOptions = options.propertyApartmentsInvestmentOptions;
      vm.address = {
        text: ''
      };
    }

    getAvailableUsageTypes (index) {
      var selectedTypes = vm.modelObject.usageMix.map(mix => mix.usageType);  // רשימה של כל סוגי השימוש שנבחרו
      var allTypes = ['עיקרי', 'שירות ', 'חניון', 'מסחר', 'תעשייה קלה', 'תעסוקה'];  // כל סוגי השימוש

      return allTypes.filter(type => !selectedTypes.includes(type) || vm.modelObject.usageMix[index].usageType === type);
    };

    onPropertyTypeChange () {
      if (vm.modelObject.propertyType === 'mixedUse') {
        vm.modelObject.usageMix = [{ usageType: '', area: 0, occupancyPercent: 0 }];
      } else {
        vm.modelObject.usageMix = [];
      }
    };

    addUsageMix () {
      vm.modelObject.usageMix.push({ usageType: '', area: 0, occupancyPercent: 0 });
    };


    calcSquareMeterOccupancy () {
      if (vm.modelObject.squareMeter <= 0) {
        vm.modelObject.usageMix = [];
        vm.totalOccupancy = 0;
        vm.occupancyError = '';
      } else {
        vm.calculateOccupancy();
      }
    }
    calculateOccupancy () {
      var totalSquareMeter = vm.modelObject.squareMeter;
      vm.modelObject.totalUsageSquareMeters = vm.modelObject.usageMix.reduce((sum, mix) => sum + parseFloat(mix.area || 0), 0);

      if (totalSquareMeter > 0) {
        if (vm.modelObject.totalUsageSquareMeters > totalSquareMeter) {
          vm.occupancyError = 'סכום השטחים בשימוש לא יכול לחרוג מהשטח הכולל של הנכס, שהוא ' + totalSquareMeter + ' מ"ר.';
        } else if (vm.modelObject.totalUsageSquareMeters < totalSquareMeter) {
          vm.occupancyError = 'סכום השטחים בשימוש קטן מהשטח הכולל של הנכס, שהוא ' + totalSquareMeter + ' מ"ר.';
        } else {
          vm.occupancyError = '';
        }
        vm.modelObject.usageMix.forEach(function (mix) {
          mix.occupancyPercent = ((parseFloat(mix.area || 0) / totalSquareMeter) * 100).toFixed(2);
        })

      } else {
        vm.modelObject.usageMix.forEach(function (mix) {
          mix.occupancyPercent = 0;
        });
        vm.totalOccupancy = 0;
        vm.occupancyError = '';
      }
    };


    $onInit () {
      if (vm.property) {
        vm.modelObject = angular.copy(vm.property);
      }
      vm.address.text = vm.modelObject.address;

      if (vm.isAdmin()) {
        vm.userList = [];
        userService.getUserList()
          .then((list) => {
            vm.userList = list;
          });

        vm.projectList = [];
        projectService.getProjectList()
          .then((list) => {
            vm.projectList = list;
          });
      }
      vm.getEmployeesList();

      $scope.$watch('vm.modelObject.price', (newVal, oldVal) => {
        const isInvalidMinSum = parseInt(newVal) < 10;

        $('#price')[0]?.setCustomValidity(isInvalidMinSum && vm.modelObject.price ? 'הזינו מחיר נכס מלא' : '');
      });
    }
    getEmployeesList () {
      let org = $rootScope.org.id;
      if (vm.property && vm.isAdmin()) {
        org = vm.modelObject.orgId;
      }
      profileService.getEmployeesList(org)
        .then((employees) => {
          if (employees.length > 0) {
            vm.users = employees
            if (vm.property) {
              if (JSON.parse(vm.modelObject.companyEmployees)?.length > 0) {
                vm.selectedCompanyEmployees = JSON.parse(vm.modelObject.companyEmployees);
                vm.selectedCompanyEmployees.forEach((user) => {
                  vm.selectedCompanyEmployees[user] = true;
                });
                vm.companyEmployeesString = _.map(vm.getUserNames(vm.selectedCompanyEmployees)).join(', ') || '';
              }
              else {
                vm.updatebrokers(vm.modelObject.userId)
                vm.selectedCompanyEmployees[vm.modelObject.userId] = true;
              }
            }
            else {
              vm.updatebrokers(vm.modelObject.userId)
              vm.selectedCompanyEmployees[vm.modelObject.userId] = true;
            }
          }
        });
    }

    editDescriptionOpen () {
      vm.editDescription = true;
    }

    managementBarOpen () {
      vm.managementBar = true;
    }

    getCategoryList () {
      return vm.modelObject.type === 'forSale' ? vm.saleCategoryList : vm.rentCategoryList;
    }

    calcSquareMeterPrice () {
      vm.modelObject.squareMeterPrice = Number((vm.modelObject.price / vm.modelObject.squareMeter).toFixed(2));
    }

    calcPrice () {
      vm.modelObject.price = Number((vm.modelObject.squareMeterPrice * vm.modelObject.squareMeter).toFixed(2));
    }

    calcOccupancyPercent () {
      vm.modelObject.occupancyPercent = Number(((vm.modelObject.rentedSquareMeter / vm.modelObject.squareMeter) * 100).toFixed(2));
    }

    calcYieldPercent () {
      vm.modelObject.yieldPercent = Number((((vm.modelObject.monthlyIncome * 12) / vm.modelObject.price) * 100).toFixed(2));
    }

    isPlot () {
      return vm.modelObject.type === 'forSale' && (vm.modelObject.category === 'constructionPlot' || vm.modelObject.category === 'agriculturalPlot');
    }

    // minPrice () {
    //   if (vm.modelObject.type === 'forSale') {
    //     return 10000;
    //   } else if (vm.modelObject.type === 'forRent') {
    //     return 200;
    //   }
    //   return 0;
    // }

    updatebrokers (id) {
      let companyEmployees = vm.modelObject.companyEmployees ? JSON.parse(vm.modelObject.companyEmployees) : [];
      let exists = companyEmployees.find(i => i === id);
      if (exists) {
        companyEmployees = companyEmployees.filter(i => i !== id);
      }
      else {
        companyEmployees.push(id);
      }
      vm.modelObject.companyEmployees = JSON.stringify(companyEmployees);
      vm.companyEmployeesString = _.map(vm.getUserNames(companyEmployees)).join(', ') || '';
    }

    getUserNames (ids) {
      let companyEmployeesString = [];
      for (let index = 0; index < ids.length; index++) {
        const element = ids[index];
        let u = vm.users.find(i => i.id === element)
        companyEmployeesString.push(u.firstName + " " + u.lastName)
      }
      return companyEmployeesString
    }

    initAddress () {
      if (!_.get(window, 'google.maps')) { return; }

      const address = document.getElementById('address');
      const searchBox = new google.maps.places.SearchBox(address);
      searchBox.addListener('places_changed', () => {
        try {
          vm.modelObject.street = searchBox.getPlaces()[0].address_components.find(i => i.types.includes('route'))?.long_name;
          vm.modelObject.city = searchBox.getPlaces()[0].address_components.find(i => i.types.includes("locality"))?.long_name;
          vm.modelObject.district = searchBox.getPlaces()[0].address_components.find(i => i.types.includes("administrative_area_level_1"))?.long_name;
          vm.modelObject.address = searchBox.getPlaces()[0].formatted_address;
          vm.modelObject.lat = searchBox.getPlaces()[0].geometry.location.lat();
          vm.modelObject.lon = searchBox.getPlaces()[0].geometry.location.lng();
        } catch (error) { }
        $scope.$apply();
      });
    }

    onAddressBlur () {
      if (!_.get(window, 'google.maps')) {
        vm.modelObject.address = vm.address.text;
        return;
      }

      vm.address.text = vm.modelObject.address;
    }

    saveProperty (doNotGo) {
      if (vm.modelObject.propertyType === 'mixedUse') {
        for (let i = 0; i < vm.modelObject.usageMix.length; i++) {
          const usage = vm.modelObject.usageMix[i];

          if (!usage.usageType || usage.area <= 0 || usage.occupancyPercent <= 0) {
            vm.occupancyError = 'שגיאה: אין להשאיר שדות ריקים או בעלי ערכים לא תקינים בשדות התמהיל עבור נכס מעורב שימושים.';
            return;
          }
        }
      }

      if (vm.modelObject.propertyType === 'mixedUse') {
        if (!vm.modelObject.totalUsageSquareMeters) {
          vm.occupancyError = 'סכום השטחים בשימוש לא יכול להיות 0. השטח הכולל של הנכס הוא ' + (vm.modelObject.squareMeter ? vm.modelObject.squareMeter : 0) + ' מ"ר.';
          return;
        }
        if (vm.modelObject.totalUsageSquareMeters > vm.modelObject.squareMeter) {
          vm.occupancyError = 'סכום השטחים בשימוש לא יכול להיות יותר מהשטח הכולל של הנכס, שהוא ' + (vm.modelObject.squareMeter ? vm.modelObject.squareMeter : 0) + ' מ"ר.';
          return;
        }
        if (vm.modelObject.totalUsageSquareMeters < vm.modelObject.squareMeter) {
          vm.occupancyError = 'סכום השטחים בשימוש לא יכול להיות פחות מהשטח הכולל של הנכס, שהוא ' + (vm.modelObject.squareMeter ? vm.modelObject.squareMeter : 0) + ' מ"ר.';
          return;
        }
        vm.occupancyError = '';
      }

      if (vm.saveProgress) {
        return;
      }

      utils.setFormFieldsDirty(vm.propertyEditForm);
      if (vm.propertyEditForm.$invalid) {
        $('#property-edit-form')[0].reportValidity();
        return;
      }

      for (let i = 0; i < vm.filesToUpload.length; i++) {
        let description = document.getElementById(`descImg${i}`).value;
        vm.filesToUpload[i].alt = description;
      }


      vm.saveProgress = true;
      const deleteFileList = _.filter(vm.modelObject.files, { deleted: true });
      const filesToUpdate = _.filter(vm.modelObject.files, { update: true });
      const query = {
        property: JSON.stringify(vm.modelObject),
        filesToUpload: vm.filesToUpload,
        filesToDelete: JSON.stringify(deleteFileList),
        filesToUpdate: JSON.stringify(filesToUpdate)
      };

      return propertyService.saveProperty(vm.modelObject.id, query)
        .then((response) => {
          $rootScope.$broadcast('propertySaved');
          return propertyService.getOrgCards()
            .then((res) => {
              if (doNotGo) { }
              else {
                if (!$rootScope.org.planName && vm.modelObject.status === 'publish') {
                  $state.go('choosePlan', { "new": true });
                } else {
                  if (res.length === 0) {
                    $state.go('myProperties', { "new": true, "card": "no" });
                  }
                  else {
                    if ($rootScope.org.planName === 'Basic') {
                      $state.go('myProperties', { "new": true, "card": "yes" });
                    }
                    else {
                      $state.go('myProperties');
                    }
                  }
                }
              }
            });
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    getNumPropertys () {
      return propertyService.getNumPropertys($rootScope.org.id).then(result => {
        return result
      });
    }

    async publishProperty () {
      let cards = await propertyService.getOrgCards();
      let numPropertys = await vm.getNumPropertys();
      let propertiesMax = $rootScope.org.planMaxAllProperties;
      if (cards.length === 0 && numPropertys < 1) {
        vm.modelObject.status = 'publish';
        return vm.saveProperty();
      }
      if (numPropertys >= propertiesMax && (propertiesMax !== 0 || propertiesMax !== null)) {
        vm.saveProperty(true);
        $uibModal.open({
          component: 'popupExceedingAssets',
          resolve: {
            numPropertys: () => numPropertys
          }
        });
      }

      else {
        vm.modelObject.status = 'publish';
        return vm.saveProperty();
      }

    }

    isAdmin () {
      return $rootScope.isAdmin();
    }

    isBroker () {
      if (angular.isUndefined(vm.isPropertyUserBroker)) {
        let org;
        if (vm.modelObject.userId) {
          org = _.get((vm.userList.rows || []).find((user) => user.id === vm.modelObject.userId), 'org');
        } else {
          org = $rootScope.org;
        }

        if (!angular.isUndefined(org)) {
          vm.isPropertyUserBroker = org.kind === 'broker';
          vm.modelObject.isBroker = vm.isPropertyUserBroker;
        }
      }

      return vm.isPropertyUserBroker;
    }
  }

  return new PropertyEditController();
}
