import _ from 'lodash';

export default function ($scope, $state, $timeout, bannerService) {
  let vm;

  class BannerShowController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.getBanner();
    }

    getBanner () {
      return bannerService.getRandomBanner()
        .then((banner) => {
          if (!banner) { return; }
          const { urlLink } = banner;
          const file = _.get(banner, 'files.0');
          if (!file) { return; }
          const bannerFullPath = `${window.SERVER_URL}/file/banner/${banner.id}/${file.name}${file.ext}`;
          vm.banner = {
            id: banner.id,
            urlLink,
            src: bannerFullPath
          };
        });
    }

    bannerClicked () {
      bannerService.bannerClicked(vm.banner.id);
    }
  }

  return new BannerShowController();
}
