import angular from 'angular';
import propertiesListPageComponent from './properties-list-page.component';
import ngMap from 'ngmap';

const propertiesListPageModule = angular.module('propertiesListPage', [
  ngMap
])
  .component('propertiesListPage', propertiesListPageComponent)
  .name;

export default propertiesListPageModule;
