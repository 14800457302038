import template from './banner-show.html';
import controller from './banner-show.controller';
import './banner-show.scss';

const bannerShowComponent = {
  // bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'bannerService'];

export default bannerShowComponent;
