import template from './about.html';
import controller from './about.controller';
import './about.scss';

const aboutComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils'];

export default aboutComponent;
