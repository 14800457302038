import angular from 'angular';

import template from './radio-checkbox.html';
import './radio-checkbox.scss';

const radioCheckboxModule = angular.module('radioCheckbox', [])
  .directive('radioCheckbox', [function () {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        model: '=?',
        bindId: '@',
        bindName: '@',
        bindValue: '@',
        bindChange: '<?',
        required: '<?',
        label: '@'
      },
      template,
      controller () {
        const vm = this;
        vm.$onInit = function () {
          vm.bindId = vm.bindId || vm.model;
        };
      }
    };
  }])
  .name;

export default radioCheckboxModule;
