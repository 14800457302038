import angular from 'angular';

import template from './switch-toggle.html';
import './switch-toggle.scss';

const switchToggleModule = angular.module('switchToggle', [])
  .directive('switchToggle', [function () {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        model: '=?',
        bindId: '@',
        disabled: '@',
        onChange: '&?'
      },
      template,
      controller () { }
    };
  }])
  .name;

export default switchToggleModule;
