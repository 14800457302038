import _ from 'lodash';
import zxcvbn from 'zxcvbn';
import isEmail from 'validator/lib/isEmail';

export default function ($scope, $state, $location, $window, $timeout, request, loginService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class SignUpCtrl {
    constructor () {
      vm = this;
      vm.state = 'signUp';
      vm.serverUrl = window.SERVER_URL;
      vm.orgKindOptions = [
        { value: 'broker', title: 'מתווך' },
        { value: 'lender', title: 'בעל נכס' },
        { value: 'investor', title: 'משקיע' },
        { value: 'buyer', title: 'רוכש' },
        { value: 'other', title: 'אחר' }
      ];

      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      };
    }

    $onInit () {
      vm.$window = $window;
      vm.signUpFields = {};
      vm.sendProgress = false;
      $('#sign-up-first-name').focus();
      $('#sign-up-form').on('keydown keypress', (event) => {
        if (event.which === KEY_EVENT_ENTER) {
          vm.send();
          event.preventDefault();
        }
      });

      $scope.$watchGroup(['vm.signUpFields.password', 'vm.signUpFields.password2'], (newVal, oldVal) => {
        if (vm.signUpForm.password) {
          vm.signUpForm.password2.$setValidity('passwordMatch', vm.signUpFields.password === vm.signUpFields.password2);
        }
      });
      $scope.$watch('vm.signUpFields.email', (newVal, oldVal) => {
        if (vm.signUpForm.email) {
          vm.signUpForm.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.signUpFields.email || '');
          vm.signUpForm.email.$setValidity('email', isValidEmail);
          $('#sign-up-email')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });
    }

    checkPassword () {
      vm.result = zxcvbn(vm.signUpForm.password.$viewValue);
    }

    async send () {
      if (vm.sendProgress) { return; }
      if (vm.signUpForm.$invalid) {
        $('#sign-up-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.signUpForm);
        return;
      }
      let emailIsExist = false;
      const email = (vm.signUpFields.email || '').replace(/[\u{0080}-\u{FFFF}]/gu, '');
      await request('/sign-up/check-registered', { email })
        .then((result) => {
          emailIsExist = result.data.data;
        });

      if (emailIsExist) {
        vm.signUpForm.email.$setValidity('registeredEmail', false);
        // utils.notify('כתובת הדוא"ל כבר רשומה במערכת', 'alert-danger');
        $('#sign-up-email')[0].setCustomValidity('כתובת הדוא"ל כבר רשומה במערכת');
        $('#sign-up-form')[0].reportValidity();
        return;
      }

      vm.sendProgress = true;
      return request('/sign-up', {
        userDetails: vm.signUpFields
      })
        .then((result) => {
          // utils.notify('חשבון נוצר בהצלחה', 'alert-success');
          return loginService.login(email, vm.signUpFields.password)
            .then((res) => {
              if (!res) {
                return Promise.reject(new Error(''));
              }
              const user = _.get(res, 'data.data');
              vm.loginContainerCtrl.closeModalBox(user);
              return res;
            })
            .catch(() => {
              vm.loginContainerCtrl.state = 'login';
            });
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          // utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }
  }

  return new SignUpCtrl();
}
