import template from './manage-plugin.html';
import controller from './manage-plugin.controller';
import './manage-plugin.scss';

const managePluginComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['orgService', 'manageService'];

export default managePluginComponent;
