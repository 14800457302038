export default function ($scope, $rootScope, $uibModal, request) {
  let vm;
  let district = [
    { district: 'מחוז הצפון' },
    { district: 'מחוז תל אביב' },
    { district: 'מחוז המרכז' },
    { district: 'מחוז הדרום' },
    { district: 'מחוז חיפה' },
    { district: 'מחוז ירושלים' },
  ]
  class autocompleteGovController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.columnModel;
      vm.searchInput = vm.model;
    }

    hideDistrict () {
      return !location.href.includes("nadlan-information")
    }

    searchInputFun () {
      vm.columnModel = undefined;
      vm.model = vm.searchInput;
      if (vm.searchInput === "") { return }
      vm.getAddress(vm.searchInput)
      // let city = `https://data.gov.il/api/3/action/datastore_search?resource_id=5c78e9fa-c2e2-4771-93ff-7f400a12f7ba&q=${encodeURIComponent(vm.searchInput)}&limit=5`
      // let street = `https://data.gov.il/api/3/action/datastore_search?resource_id=a7296d1a-f8c9-4b70-96c2-6ebb4352f8e3&q=${encodeURIComponent(vm.searchInput)}&limit=5`
      // Promise.all([
      //   vm.fetchFun('street', street),
      //   vm.fetchFun('city', city)
      // ])
      //   .then((list) => {
      //     if (!$scope.$$phase) {
      //       $scope.$apply()
      //     }
      //   });
    }

    // fetchFun (t, url) {
    //   return fetch(url)
    //     .then(response => response.json())
    //     .then(data => {
    //       if (t === 'street') {
    //         data.result.records.forEach(element => {
    //           element.street = element.שם_רחוב
    //           element.city = element.שם_ישוב.trim()
    //         });
    //         vm.STREETList = data.result.records.filter(i => i.street !== i.city);
    //       }
    //       else {
    //         data.result.records.forEach(element => {
    //           element.city = element.שם_ישוב.trim()
    //         });
    //         vm.SETTLEMENTList = data.result.records;
    //       }
    //     })
    // }

    getAddress (search) {
      return request('/property/getAddress', { search: search }, 'POST')
        .then((res) => {
          vm.districtList = district.filter(i => i.district.match(vm.searchInput));
          let data = res.data.data
          data.streetList.forEach(element => {
            element.districtName = element.districtName
            element.city = element.cityName
            element.street = element.streetName
          });
          vm.STREETList = data.streetList;
          data.cityList.forEach(element => {
            element.districtName = element.districtName
            element.city = element.cityName
          });
          vm.SETTLEMENTList = data.cityList;
        });
    }

    viewResults (val) {
      document.addEventListener('click', function (e) {
        e = e || window.event;
        let target = e.target;
        const match = target.getAttribute('class').match('itemLabel');
        if (match !== null) {
          return
        }
        document.querySelector('.autocomplete-gov').setAttribute('class', 'autocomplete-gov ng-binding dropdown' + ' ' + val)
      }, false);
    }

    searchParameters (val) {
      let column = {}
      if (val.street && val.city) {
        vm.searchInput = val.street + ", " + val.city;
        column.street = val.street;
        column.city = val.city;
      }
      else {
        if (val.city) {
          column.city = val.city;
          vm.searchInput = val.city
        } else {
          column.district = val.district
          vm.searchInput = val.district
        }
      }
      vm.columnModel = column;
      vm.model = vm.searchInput;
      if (vm.model === vm.searchInput) {
        document.querySelector('.autocomplete-gov').setAttribute('class', 'autocomplete-gov ng-binding dropdown' + ' ' + val)
      }
      setTimeout(() => {
        vm.search()
      }, 100);
    }
  }

  return new autocompleteGovController();
}
