import template from './property-nadlan.html';
import controller from './property-nadlan.controller';
import './property-nadlan.scss';

const propertyNadlanComponent = {
  bindings: {
    property: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'propertyNadlanService', '$window'];

export default propertyNadlanComponent;
