import template from './project-show.html';
import controller from './project-show.controller';
import './project-show.scss';

const projectShowComponent = {
  bindings: {
    project: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$sce', '$uibModal', 'sweetAlert', 'projectService', 'messagesService', 'eventsService', 'utils'];

export default projectShowComponent;
