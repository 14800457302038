export default function ($rootScope, $uibModal, mailingListService, messagesService, categoryService) {
  let vm;

  class SideMenuController {
    constructor () {
      vm = this;
      vm.name = 'sideMenu';
      vm.getmessages = true;
      vm.categorySaleList = categoryService.forSaleListHeader;
      vm.categoryRentList = categoryService.forRentListHeader;
      vm.openSale = false;
      vm.openRent = false;
    }

    $onInit () {
      if (vm.fixed) {
        $('body').addClass('fixed-side-menu');
      } else {
        $('body').removeClass('fixed-side-menu');
      }
    }

    getUnreadPosts (id) {
      if (vm.getmessages){
        vm.getMessages(id);
        vm.getmessages = false
      }
    }
    
    changeStatus (type) {
      if (type === 'Rent') {vm.openRent = !vm.openRent;}
      else {vm.openSale = !vm.openSale; }
    }

    getMessages (id) {
      if (id && !vm.unreadPosts) {
        messagesService.getMessageList('inbox', {})
          .then((res) => {
            vm.unreadPosts = 0
            res.rows.map((message) => {
              vm.unreadPosts = vm.unreadPosts + message.posts.filter((post) => post.to === id && !post.read).length;
            })
          });
      }
    }

    toggleSideMenu () {
      $('body').toggleClass('show-sidebar');
    }

    minimizeSideMenu () {
      $('body').removeClass('show-sidebar');
    }

    openMailingListForm () {
      mailingListService.openForm();
    }

    isAdmin () {
      return $rootScope.isAdmin();
    }

    login () {
      $uibModal.open({
        component: 'loginContainer',
        windowClass: 'login-modal',
        size: 'sm'
      });
    }

    signUp () {
      $uibModal.open({
        component: 'loginContainer',
        resolve: {
          state: () => 'signUp'
        },
        windowClass: 'login-modal',
        size: 'sm'
      });
    }
  }

  return new SideMenuController();
}
