import _ from 'lodash';

export default function ($scope, $timeout, utils, categoryService) {
  let vm;

  class TermsController {
    constructor () {
      vm = this;
      vm.categorySaleList = categoryService.forSaleListHeader;
      vm.categoryRentList = categoryService.forRentListHeader;
      vm.cityCategory = categoryService.cityCategoryTopNav;
      vm.district = categoryService.districtCategorySideNav;
      vm.citySaleArr = [];
      vm.cityRentArr = [];
      Object.keys(vm.categorySaleList).forEach((element) => {
        vm.citySaleArr.push(100)
        // vm.citySaleArr.push(13)
      });
      Object.keys(vm.categoryRentList).forEach((element) => {
        vm.cityRentArr.push(100)
        // vm.cityRentArr.push(13)
      });
    }

    $onInit () {
    }

  }

  return new TermsController();
}
