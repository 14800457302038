import angular from 'angular';
import ManagePropertiesComponent from './manage-properties.component';
import managePlan from '../manage-plan/manage-plan';

const ManagePropertiesModule = angular.module('manageProperties', [
  managePlan
])
  .component('manageProperties', ManagePropertiesComponent)
  .name;

export default ManagePropertiesModule;
