import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $timeout, savedSearchesService, utils, mailingListService) {
  let vm;

  class SavedSearchesController {
    constructor () {
      vm = this;

      vm.typeList = {
        forSale: 'נכסים למכירה',
        forRent: 'נכסים להשכרה',
        producing: 'נכסים מניבים'
      };
      vm.listComponents = {
        forSale: 'forSaleList',
        forRent: 'forRentList',
        producing: 'producingList'
      };
      vm.categoryImageList = options.homeCategoryList;
      vm.defaultImageSrc = 'assets/images/default.jpg';
      const categories = {};
      Object.keys(options.propertySaleCategoryOptions).forEach((key) => {
        categories[key] = options.propertySaleCategoryOptions[key].title;
      });
      vm.categories = categories;
      vm.yieldFilterOptions = options.yieldFilterOptions;
    }

    $onInit () {
      vm.paging1 = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging2 = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging1.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.paging2.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.getSavedSearches();
      vm.getMailingList();
    }

    setPagingFlags (val) {
      let paging = 'paging1';
      if (val === 2) { paging = 'paging2' }
      let list = 'list1';
      if (val === 2) { list = 'list2' }
      vm[paging].current.count = vm[list].rows.length;
      vm[paging].count = vm[list].count;
      vm[paging].pages = Math.ceil(vm[paging].count / vm[paging].limit) || 1;
      vm[paging].current.page = Math.floor(vm[paging].offset / vm[paging].limit) + 1;
      vm[paging].current.offset = vm[paging].offset;

      const page = vm[paging].current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm[paging].pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page, val) {
      let paging = paging1
      if (val === 2) { paging = paging2 }
      if (Number(page) < 1 || Number(page) > vm[paging].pages) {
        return;
      }
      vm[paging].offset = vm[paging].limit * (Number(page) - 1);
      if (val === 1) { vm.getSavedSearches(); } else { vm.getMailingList(); }
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getSavedSearches () {
      vm.loadProgress2 = true;

      return savedSearchesService.getSavedSearches({
        limit: vm.paging1.limit,
        offset: vm.paging1.offset,
        order: vm.paging1.orderBy,
        desc: vm.paging1.orderDesc
      })
        .then((res) => {
          vm.list1 = res;
          vm.setPagingFlags(1);
        })
        .finally(() => {
          vm.loadProgress2 = false;
        });
    }

    getMailingList () {
      vm.loadProgress1 = true;

      return mailingListService.getMailingList({
        limit: vm.paging2.limit,
        offset: vm.paging2.offset,
        order: vm.paging2.orderBy,
        desc: vm.paging2.orderDesc
      })
        .then((res) => {
          res.rows.forEach(row => {
            row.filters = {};
            row.filters.category = row.propertyCategory ? row.propertyCategory : null;
            row.filters.query = row.address ? row.address : null;
          })
          vm.list2 = res;
          vm.setPagingFlags(2);
        })
        .finally(() => {
          vm.loadProgress1 = false;
        });
    }

    deleteSavedSearch (item) {
      return savedSearchesService.deleteSavedSearch(item.id)
        .then(() => {
          vm.getSavedSearches();
        });
    }

    deleteMailingList (item) {
      return mailingListService.deleteMailingList(item.id)
        .then(() => {
          vm.getMailingList();
        });
    }

    updateMailingList (e, item, i) {
      const val = e.target.value ? e.target.value : null;
      if (val !== item.frequency) {
        const obj = { id: item.id, frequency: val, email: item.email }
        return mailingListService.update(obj)
          .then(
            vm.list2.rows[i].frequency = val,
          )
          .finally(() => { });
      }
    }

    searchLink (item) {
      return (vm.listComponents[item.type] || 'forSaleList') + '(' + JSON.stringify(item.filters) + ')';
    }

    searchLink2 (item) {
      let filters = {};
      let column = {};
      if (item.address) {
        filters.query = item.address;
      }
      const columnOp = ['street', 'city', 'district'];
      columnOp.forEach(element => {
        if (item[element]) {
          column[element] = item[element];
        }
      });
      if (Object.keys(column).length > 0) {
        filters.column = JSON.stringify(column);
      }
      filters.category = item.propertyCategory;
      filters.maxPrice = item.maxPrice;
      filters.maxSquareMeter = item.maxSquareMeter;
      filters.minPrice = item.minPrice;
      filters.minSquareMeter = item.minSquareMeter;

      return (vm.listComponents[item.type] || 'forSaleList') + '(' + JSON.stringify(filters) + ')';
    }

    getItemCategories (item) {
      if (!_.get(item, 'filters.category')) {
        return '';
      }
      return _.get(item, 'filters.category').split(',').map((category) => vm.categories[category] || '').join(', ');
    }

    getItemCategories2 (item) {
      if (!item) {
        return '';
      }
      return item.split(',').map((category) => vm.categories[category] || '').join(', ');
    }

    getItemImage (item) {
      if (!_.get(item, 'filters.category')) {
        return vm.defaultImageSrc;
      }

      const itemCategories = item.filters.category.split(',');
      const itemCategory = itemCategories.find((category) => vm.categoryImageList[category]);
      return _.get(vm.categoryImageList, itemCategory + '.image') || vm.defaultImageSrc;
    }

    getYieldFilterString (item) {
      const yieldFilterValue = _.get(item, 'filters.yieldFilter');
      if (!yieldFilterValue) { return ''; }

      const option = _.find(vm.yieldFilterOptions, { value: yieldFilterValue });
      return option.label;
    }
  }

  return new SavedSearchesController();
}
