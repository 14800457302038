
export default function ($rootScope, $state, $timeout, propertyService) {
  let vm;

  class popupExceedingAssetsController {
    constructor () {
      vm = this;
    }

    $onInit () {
      let org = $rootScope.org;
      vm.planDescription = org.planDescription;
      vm.planMaxAllProperties = org.planMaxAllProperties;
      vm.plan = (org.planName === 'vip' || org.planName === 'custom') ? true : false;
    }

    closeModalBox () {
      vm.modalInstance.close();
    }

    click (plan) {
      if (plan) {
        propertyService.contactRequest().then(() => {
          vm.successMessage = true;
          $timeout(() => {
            vm.closeModalBox();
            $state.go('myProperties');
          }, 4000);
        })
      }
      else {
        vm.goToChoosePlan();
      }
    }

    goToChoosePlan () {
      vm.closeModalBox();
      $state.go('choosePlan');
    }

  }

  return new popupExceedingAssetsController();
}