import template from './property-show.html';
import controller from './property-show.controller';
import './property-show.scss';

const propertyShowComponent = {
  bindings: {
    property: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope','sweetAlert', '$rootScope', '$state', '$timeout', '$uibModal', 'propertyService', 'propertyMistakeReportService', 'savedPropertiesService', 'utils', 'eventsService', '$window'];

export default propertyShowComponent;
