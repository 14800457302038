import template from './message-show.html';
import controller from './message-show.controller';
import './message-show.scss';

const messageShowComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'messagesService', 'utils', '$uibModal'];

export default messageShowComponent;
