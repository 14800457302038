import template from './org-show.html';
import controller from './org-show.controller';
import './org-show.scss';

const orgShowComponent = {
  bindings: {
    org: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', 'orgService'];

export default orgShowComponent;
