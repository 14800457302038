import template from './properties-list-page.html';
import controller from './properties-list-page.controller';
import './properties-list-page.scss';

const propertiesListPageComponent = {
  bindings: {
    categoryPage: '<?',
    city: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$location', '$timeout', '$filter', '$uibModal', '$sce', 'propertyService', 'categoryService', 'savedSearchesService', 'mailingListService'];

export default propertiesListPageComponent;
