import angular from 'angular';
import bannerEditComponent from './banner-edit.component';
import bannerFilesEdit from './banner-files-edit/banner-files-edit';

const bannerEditModule = angular.module('bannerEdit', [
  bannerFilesEdit
])
  .component('bannerEdit', bannerEditComponent)
  .name;

export default bannerEditModule;
