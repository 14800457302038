import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $timeout, projectService, userService, manageService, utils, $uibModal) {
  let vm;

  class ManageProjectsController {
    constructor () {
      vm = this;

      vm.orderByOptions = [
        { name: 'publishedAt', desc: true, label: 'פורסם לאחרונה' },
        { name: 'price', desc: false, label: 'מחיר (מהנמוך לגבוה)' },
        { name: 'price', desc: true, label: 'מחיר (מהגבוה לנמוך)' }
      ];

      vm.statusOptions = options.projectStatusOptions;
      vm.tabsOptions = {
        publish: 'פרויקטים שפורסמו',
        other: 'שאר הפרויקטים',
        closed: 'פרויקטים שנמכרו',
        canceled: 'פרויקטים מבוטלים'
      };
    }

    $onInit () {
      vm.paging = {
        orderBy: 'publishedAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.searchType = 'project';
      vm.type = localStorage.getItem('manageforSale');
      if (!vm.type) {
        vm.type = 'forSale';
      }
      localStorage.setItem('manageforSale', vm.type);
      vm.selectedTab = $state.params.status || localStorage.getItem('manageSelectedTab');
      if (!vm.selectedTab || !Object.keys(vm.tabsOptions).includes(vm.selectedTab)) {
        vm.selectedTab = 'publish';
      }
      localStorage.setItem('manageSelectedTab', vm.selectedTab);

      if (vm.selectedTab === 'other') {
        vm.statusFilter = 'draft|paused|expired';
      } else {
        vm.statusFilter = vm.selectedTab;
      }
      vm.getManageProjects();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getManageProjects();
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getManageProjects () {
      vm.loadProgress = true;
      let searchValue = '';
      let userSearchValue = '';
      if (vm.searchType === 'project') {
        searchValue = vm.searchValue;
      } else if (vm.searchType === 'user') {
        userSearchValue = vm.searchValue;
      }

      return Promise.all([
        manageService.getManageProjects(vm.statusFilter, searchValue, userSearchValue, {
          limit: vm.paging.limit,
          offset: vm.paging.offset,
          order: vm.paging.orderBy,
          desc: vm.paging.orderDesc
        }, vm.type)
          .then((res) => {
            vm.list = res;
            vm.setPagingFlags();
          }),
        manageService.getManageProjectsCounts(vm.type)
          .then((counts) => {
            vm.counts = counts || {};
            _.set(vm.counts, 'all', Object.values(vm.counts).reduce((previous, current) => {
              return current + previous;
            }, 0));
          })
      ])
        .finally(() => {
          vm.loadProgress = false;
        });
    }

    changeSearchType (type) {
      vm.searchType = type || 'project';
      if (vm.searchValue.length) {
        vm.doPaging(1);
      }
    }

    changePropertyType (type) {
      if (vm.type !== type) {
        vm.type = type;
        localStorage.setItem('manageforSale', type);
        vm.getManageProjects();
        console.log(vm.type);
      }
    }

    saveStatus (projectId, status, publishOneSignal) {
      if (vm.saveProgress) {
        return;
      }
      const query = {
        project: JSON.stringify({ status })
      };

      vm.saveProgress = true;
      return projectService.saveProject(projectId, query, publishOneSignal)
        .then(() => {
          vm.getManageProjects();
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }
  }

  return new ManageProjectsController();
}
