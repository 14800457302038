import _ from 'lodash';

export default function ($scope, $rootScope, $timeout, $uibModal, utils) {
  let vm;

  class ProjectFilesEditCtrl {
    constructor () {
      vm = this;
      vm.inputFiles = [];
    }

    $onInit () {
      vm.projectEditCtrl.filesToUpload = [];
    }

    selectFiles () {
      $('.drop-box').trigger('click');
    }

    existNonDeletedFiles () {
      return _.filter(vm.projectEditCtrl.modelObject.files, (file) => !file.deleted) || [];
    }

    getFileUrl (file) {
      return `${window.SERVER_URL}/file/project/${vm.projectEditCtrl.modelObject.id}/${file.name}${file.ext}`;
    }

    isImage (file) {
      return ['.jpg', '.png', '.gif', '.jpeg', '.bmp'].includes(file.ext.toLowerCase());
    }

    zoomImage (file) {
      $uibModal.open({
        template: `<div class="image-container"><img ng-src="${vm.getFileUrl(file)}"/><div>`,
        windowClass: 'image-preview-zoom',
        size: 'lg'
      });
    }

    zoomImageFile (file) {
      let imageUrl;
      const reader = new FileReader();
      reader.onload = function (e) {
        imageUrl = e.target.result;
        $uibModal.open({
          template: `<div class="image-container"><img ng-src="${imageUrl}"/><div>`,
          windowClass: 'image-preview-zoom',
          size: 'lg'
        });
      };
      reader.readAsDataURL(file);
    }

    removeImage (file) {
      file.deleted = true;
    }

    uploadFiles ($files, $invalidFiles) {
      // if ($invalidFiles.length) {
      //   utils.notify($invalidFiles.length + ' קבצים לא הועלו', 'alert-danger');
      // }

      if ($files.length) {
        $files.forEach(async (file) => {
          if (!(file instanceof File || file instanceof Blob)) {
            return;
          }

          vm.projectEditCtrl.filesToUpload.push(file);
        });
      }
    }

    removeUploadFile (file) {
      vm.projectEditCtrl.filesToUpload.splice(vm.projectEditCtrl.filesToUpload.indexOf(file), 1);
    }
  }

  return new ProjectFilesEditCtrl();
}
