import template from './login-container.html';
import controller from './login-container.controller';
import './login-container.scss';

const loginContainerComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', 'loginService'];

export default loginContainerComponent;
