import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $state, $location, $timeout, $sce, propertyService) {
  let vm;

  class PropertiesListController {
    constructor () {
      vm = this;
      vm.orderByOptions = {
        '-publishedAt': { label: 'עודכן לאחרונה' },
        '-price': { label: 'מחיר (מהגבוה לנמוך)' },
        price: { label: 'מחיר (מהנמוך לגבוה)' },
        '-squareMeter': { label: 'גודל (מהגדול לקטן)' },
        squareMeter: { label: 'גודל (מהקטן לגדול)' }
      };
      vm.propertyTypeList = {
        forSale: 'למכירה',
        forRent: 'להשכרה'
      };
      vm.paging = {
        orderBy: 'publishedAt',
        orderDesc: true,
        limit: vm.type === 'forSale' ? 17 : 20,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        orderBy: vm.paging.orderBy,
        orderDesc: vm.paging.orderDesc,
        offset: 0,
        count: 0,
        page: 1
      };

      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };
      vm.businessesList = options.propertyBusinessesForSaleOptions;
      vm.placeholder = $sce.trustAsHtml(createPlaceholderContent());
    }

    $onInit () {
      if ($state.params.orgId) {
        vm.alertMessage = null
      } else {
        vm.alertMessage = true
      }
      window.listImageLoaded = function (img) {
        $(img).addClass('loaded');
        vm.imageListLoaded = vm.imageLoadedTimeout || $('.property img.property-image.loaded').length === $('.property .image-wrapper img.property-image').length;
        $timeout(() => $scope.$apply());
      };

      vm.paging.current.page = Number(_.get($state, 'params.page')) || 1;
      vm.getList();
      $scope.$watch('vm.filters', (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          vm.getList(true);
        }
      }, true);
      $scope.$watch('vm.type', (newValue, oldValue) => {
        if (!angular.equals(newValue, oldValue)) {
          vm.getList(true);
        }
      });
    }

    $onDestroy () {
      window.listImageLoaded = undefined;
    }

    getList (firstPage) {
      if (firstPage) {
        vm.paging.current.page = 1;
      }
      vm.getListProgress = true;
      vm.imageLoadedTimeout = false;
      vm.imageListLoaded = false;

      vm.paging.offset = vm.paging.limit * Number(vm.paging.current.page - 1);
      const query = {
        limit: vm.paging.limit,
        offset: vm.paging.offset,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc,
        column: $state.params.column,
        city: $state.params.city,
        district: $state.params.district,
        businesses: $state.params.businesses
      };

      ['query', 'kind', 'category', 'minPrice', 'maxPrice', 'minSquareMeter', 'maxSquareMeter', 'yieldFilter', 'projectId'].forEach((key) => {
        if (vm.filters[key]) {
          query[key] = vm.filters[key];
        }
        if (vm.filters['projectId']) {
          if (vm.filters.type) {
            vm.type = vm.filters.type
          }
        }
      });

      return new Promise((resolve, reject) => {
        if (vm.type === 'projectsforSaleList') {
          query.type = vm.type;
          return resolve(propertyService.getAllProjects(query));
        }
        if (vm.filters.orgId) {
          return resolve(propertyService.getOrgProperties(vm.filters.orgId, query));
        }
        return resolve(propertyService.getPropertyList(vm.type, query));
      })
        .then((list) => {
          vm.list = list;
          if (vm.type === 'projectsforSaleList') {
            vm.list.projectsAds = vm.list.rows;
          }
          let araay
          if (vm.filters.orgId) {
            araay = JSON.stringify(vm.list.rows);
          }
          else {
            araay = JSON.stringify(vm.list.allRows);
          }
          vm.setWithExpiry('list', araay, 3600000)
          vm.setPagingFlags();
          if (firstPage) {
            if (Number(_.get($state, 'params.page')) && Number(_.get($state, 'params.page')) !== 1) {
              try { window.history.replaceState({}, null, vm.pageLink(1)); } catch (error) { $state.go('.', { page: 1 }, { notify: false, reload: false }); }
            }
          }

          return vm.list;
        })
        .finally(() => {
          vm.getListProgress = false;
          if (!_.get(vm, 'list.rows.length')) {
            vm.imageListLoaded = true;
          }
          $timeout.cancel(vm.progressBlocksTimeout); // cancel the last timeout
          vm.progressBlocksTimeout = $timeout(() => {
            $scope.$apply(() => {
              vm.imageListLoaded = true;
              vm.imageLoadedTimeout = true;
            });
          }, 5000);
        });
    }

    setWithExpiry (key, value, ttl) {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    }

    selectOrderBy (option) {
      if (option[0] === '-') {
        option = option.slice(1);
        vm.paging.orderDesc = true;
      } else {
        vm.paging.orderDesc = false;
      }

      vm.paging.orderBy = option;
      vm.getList();
    }

    pageLink (page) {
      const url = location.pathname.replace(/[\d]{0,}$/, '') + (page && page !== 1 ? page : '') + location.search;
      return url;
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.current.orderBy = vm.paging.orderBy;
      vm.paging.current.orderDesc = vm.paging.orderDesc;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      if (vm.paging.current.page > vm.paging.pages) {
        $state.go('.', { page: 1 });
      }
      vm.paging.current.offset = vm.paging.offset;
      vm.setPageBtnsArray();
    }

    setPageBtnsArray () {
      const page = vm.paging.current.page;
      const arr = [0];
      for (let index = page - 1; index <= page + 1; index++) {
        if (index > 1 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    getFileUrl (property, size, type) {
      if (_.get(property, 'files.length')) {
        return `${window.SERVER_URL}/file/${type}/${property.id}/${property.files[0].name}${size ? '_' + size : ''}${property.files[0].ext}`;
      }
      return 'assets/images/default.jpg';
    }

    getUserImage (userImage) {
      if (!userImage) {
        return '';
      }
      return window.SERVER_URL + userImage;
    }

    propertyAddressString (address) {
      return (address || '').replace(/, ישראל$/, '');
    }

    getHref () {
      let page = 'for-sale';

      switch (true) {
        case vm.filters.category && vm.type === 'forRent':
          page = `rent-category/${vm.filters.category}`;
          break;
        case vm.filters.category && vm.type !== 'forRent':
          page = `category/${vm.filters.category}`;
          break;
        case !vm.filters.category && vm.type === 'forRent':
          page = 'rent-category';
          break;
      }

      return `/${page}/`;
    }
  }

  return new PropertiesListController();
}

function createPlaceholderContent () {
  let placeholder = '';
  const placeholderContent = `
  <a class="property col-lg-3 col-sm-4 col-xs-12 property-placeholder">
    <div class="property-inner">
      <svg class="placeholder-svgstyled__PlaceholderSVG-tcbxga-0 bixdtb" viewBox="0 0 350 275" width="100%" height="100%" preserveAspectRatio="none" style="border: solid 1px #e0e0e0;">
        <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="77%"></rect>
        <rect fill="url(#placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" clip-path="url(#placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8)" x="0" y="0" width="100%" height="100%"></rect>
        <defs>
          <clipPath id="placeholder-clip-path-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
            <rect class="placeholder-textstyled__PlaceholderTextItem-s1aiy53q-1 cvdKqV" x="108" y="222" width="230" height="15"></rect>
            <rect class="placeholder-textstyled__PlaceholderTextItem-s1aiy53q-1 cvdKqV" x="200" y="248" width="138" height="17"></rect>
          </clipPath>
          <linearGradient id="placeholder-linear-gradient-5d7dab74-f943-42f4-bf3b-9c7d223f0ff8">
            <stop offset="-1.89007" stop-color="#F5F7F9" stop-opacity="1">
              <animate attributeName="offset" values="-3; 1" dur="1s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="-0.667554" stop-color="#fcfcfc" stop-opacity="1">
              <animate attributeName="offset" values="-1.5; 1.5" dur="1s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="0.109928" stop-color="#F5F7F9" stop-opacity="1">
              <animate attributeName="offset" values="-1; 3" dur="1s" repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </a>`;
  for (let i = 0; i < 10; i++) {
    placeholder += placeholderContent;
  }

  return placeholder;
}
