import _ from 'lodash';

export default function ($scope, $state, $location, $timeout, loginService) {
  let vm;

  class LoginContainerCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.state = _.get(vm.resolve, 'state') || 'login';
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    closeModalBox (user) {
      vm.modalInstance.close(user);
    }
  }

  return new LoginContainerCtrl();
}
