import _ from 'lodash';

export default function ($scope, $rootScope, $uibModal, profileService, utils) {
  let vm;

  class ProfileController {
    constructor () {
      vm = this;
      vm.sohwPage = true;
    }

    $onInit () {
      vm.getEmployeesList();
      vm.permission = $rootScope.user.permission !== 'user';
    }

    getEmployeesList () {
      profileService.getEmployeesList($rootScope.org.id)
        .then((users) => {
          if (users.length > 1) {
            vm.users = users.filter(i => i.id !== $rootScope.user.id)
          }
        });
    }

    deleteUser (userId) {
      profileService.deleteUser(userId, $rootScope.org.id)
        .then(() => {
          vm.getEmployeesList();
        });
    }

    deleteAccount () {
      profileService.deleteAccount($rootScope.user)
        .then(() => {
          vm.pop = false;
          alert("הבקשה נשלחה בהצלחה!");
        });
    }

    getUserProfileImage () {
      if (!$rootScope.user.profileImageUrl) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + $rootScope.user.profileImageUrl;
    }

    getOrgLogo () {
      if (!$rootScope.org.logoUrl) {
        return 'assets/images/org-logo.png';
      }
      return window.SERVER_URL + $rootScope.org.logoUrl;
    }

    getInvoiceName () {
      return $rootScope.org.invoiceName || $rootScope.org.name || [_.get($rootScope.user, 'firstName'), _.get($rootScope.user, 'lastName')].join(' ') || '';
    }

    getInvoiceEmail () {
      return $rootScope.org.invoiceEmail || $rootScope.org.email || $rootScope.user.email || '';
    }

    editProfile () {
      $uibModal.open({
        component: 'profileEdit',
        windowClass: 'profile-edit-modal'
      });
    }

    addEmployee () {
      $uibModal.open({
        component: 'companyEmployees',
      });
    }

    editOrg () {
      $uibModal.open({
        component: 'profileEdit',
        windowClass: 'profile-edit-modal org-edit-modal',
        resolve: {
          editMode: () => 'org'
        }
      });
    }

    editPassword () {
      $uibModal.open({
        component: 'profileEdit',
        windowClass: 'profile-edit-modal',
        resolve: {
          editMode: () => 'password'
        },
        size: 'sm'
      });
    }
  }

  return new ProfileController();
}
