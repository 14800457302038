import template from './restore-password.html';
import controller from './restore-password.controller';
import './restore-password.scss';

const restorePasswordComponent = {
  bindings: {},
  require: {
    loginContainerCtrl: '^loginContainer'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$timeout', 'loginService', 'utils'];

export default restorePasswordComponent;
