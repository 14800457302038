import _ from 'lodash';

export default function ($scope, $rootScope, $state, $location, $timeout, messagesService, utils, $uibModal) {
  let vm;

  class MessagesController {
    constructor () {
      vm = this;
      vm.messagesService = messagesService;

      vm.messagesTypeOptions = {
        inbox: 'דואר נכנס',
        sent: 'דואר יוצא',
        history: 'ארכיון'
      };
      vm.orderBy = '-createdAt';
    }

    $onInit () {
      vm.messagesType = $state.params.type || 'inbox';
      vm.userId = $rootScope.user.id;
      vm.me = { id: vm.userId, firstName: 'אני' };
      vm.getMessages();
    }

    messagesTypeChange (type) {
      $state.params.type = type;
      $location.search('type', type);
      vm.messagesType = type;
      vm.getMessages();
    }

    click (messageId, action, read) {
      if (read) {
        messagesService.markAsRead(messageId);
      }
      messagesService.deleteOrRestore(messageId, action).then((res) => {
        vm.getMessages();
      })
    }

    getMessages (args) {
      vm.loading = true;
      messagesService.getMessageList(vm.messagesType, args)
        .then((res) => {
          vm.list = {
            count: res.count,
            rows: res.rows.map((message) => {
              let postUserField = vm.messagesType === 'inbox' ? 'fromUser' : vm.messagesType === 'sent' ? 'toUser' : message.to === vm.userId ? 'fromUser' : 'toUser';
              message.unreadPosts = message.posts.filter((post) => post.to === vm.userId && !post.read).length > 0;
              message.userNames = _.uniq(message.posts.map((post) => {
                vm.checkIsMe(post, 'fromUser');
                vm.checkIsMe(post, 'toUser');
                return [_.get(post, postUserField + '.firstName'), _.get(post, postUserField + '.lastName')].filter((user) => !!user).join(' ');
              }))
                .join(', ');

              return message;
            })
          };
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    checkIsMe (post, userField) {
      if (_.get(post, userField + '.id') === vm.userId) {
        _.set(post, userField, vm.me);
      }
    }
  }

  return new MessagesController();
}
