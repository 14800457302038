import _ from 'lodash';

import options from '../options';

export default function ($rootScope, $uibModal) {
  let vm;

  class SelectPropertyCategoryController {
    constructor () {
      vm = this;
      vm.name = 'selectPropertyCategory';

      vm.model = [];
      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };
    }

    $onInit () {
      if (!angular.isArray(vm.model)) {
        vm.model = [];
      }
    }

    hasProducingFilterModel () {
      return Object(vm).hasOwnProperty('producingFilterModel');
    }

    toggleCategorySelect (key) {
      if (vm.isCategorySelected(key)) {
        vm.model.splice(vm.model.indexOf(key), 1);
      } else {
        vm.model.push(key);
      }
    }

    resetCategoryFilters () {
      vm.model = [];
      if (vm.producingFilterModel) {
        vm.producingFilterModel = false;
      }
    }

    isCategorySelected (category) {
      return vm.model.includes(category);
    }

    selectedCategoriesString () {
      const selectedString = [];
      (vm.model || []).forEach((category) => {
        const title = _.get(vm.categoryList, vm.type + '.' + category + '.title');
        if (title) {
          selectedString.push(title);
        }
      });
      return selectedString.join(', ') || 'סוג נכס';
    }
  }

  return new SelectPropertyCategoryController();
}
