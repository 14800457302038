import angular from 'angular';
import ProjectEdit from './project-edit/project-edit';
import ProjectSendMessage from './project-send-message/project-send-message';
import ProjectShow from './project-show/project-show';
import ProjectService from './project.service';

const projectModule = angular.module('project', [
  ProjectEdit,
  ProjectSendMessage,
  ProjectShow,
  ProjectService
])
  .name;

export default projectModule;
