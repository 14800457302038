export default function ($scope, $rootScope, $state, $timeout, manageService, utils) {
  let vm;

  class ManageMailingListController {
    constructor () {
      vm = this;

      vm.orderBy = '-createdAt';
      vm.roleList = {
        private: 'פרטי',
        broker: 'מתווך',
        investor: 'משקיע'
      };
    }

    $onInit () {
      vm.getMailingList();
    }

    getMailingList () {
      manageService.getMailingList()
        .then((list) => {
          vm.list = list;
        });
    }
  }

  return new ManageMailingListController();
}
