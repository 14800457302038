import template from './app.html';
import controller from './app.controller';
import './app.scss';

controller.$inject = ['$scope', '$rootScope'];

const appComponent = {
  template,
  controller
};

export default appComponent;
