import template from './properties-home-list.html';
import controller from './properties-home-list.controller';
import './properties-home-list.scss';

const propertiesHomeListComponent = {
  bindings: {
    type: '@'
  },
  require: {
    homeCtrl: '^home'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', '$sce', 'propertyService', 'utils'];

export default propertiesHomeListComponent;
