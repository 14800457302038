import _ from 'lodash';
import moment from 'moment';
moment.locale('he');
import marked from 'marked';

export default function ($rootScope, $scope, $state, $timeout, $sce, $uibModal, sweetAlert, projectService, messagesService, eventsService, utils) {
  let vm;

  class ProjectShowController {
    constructor () {
      vm = this;

      vm.shownSlide = 0;

      vm.markedOps = {
        renderer: new marked.Renderer(),
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true,
        smartLists: true,
        smartypants: false
      };

      vm.markedOps.renderer.link = function (href, title, text) {
        return '<a ' + (text === href ? 'class="a-ltr underline-hover"' : '') + " href='" + href + "' target='_blank'>" + text + '</a>';
      };
    }

    $onInit () {
      if (vm.project && vm.project.video) {
        const videoID = vm.youtube_parser(vm.project.video);
        if (videoID) {
          vm.videoID = videoID;
          vm.videoSrc = $sce.trustAsResourceUrl('https://www.youtube.com/embed/' + videoID + '?autoplay=1&showinfo=0&iv_load_policy=3&rel=0&controls=0');
        }
      }

      vm.projectAddressString = (_.get(vm, 'project.address') || '').replace(/, ישראל$/, '');

      eventsService.create('projectShow', vm.project.id);
    }

    async openProjectSendMessageForm () {
      const user = $rootScope.user || await $uibModal.open({
        component: 'loginContainer',
        resolve: {
          loginTitle: () => 'לפנות למפרסם'
        },
        windowClass: 'login-modal',
        size: 'sm'
      })
        .result;

      eventsService.create('projectClickMessageForm', vm.project.id);

      if (!user) { return; }

      $uibModal.open({
        component: 'projectSendMessage',
        resolve: {
          project: () => vm.project
        },
        windowClass: 'project-send-message-modal',
        size: 'md'
      });
    }

    toggleDescriptionExpanded () {
      vm.descriptionExpanded = !vm.descriptionExpanded;
      if (!vm.descriptionExpanded) {
        document.querySelector('.project-description').scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }

    youtube_parser (url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[7].length == 11) ? match[7] : false;
    }

    prevSlide () {
      vm.shownSlide--;
    }

    nextSlide () {
      vm.shownSlide++;
    }

    getFileUrl (file) {
      return `${window.SERVER_URL}/file/project/${vm.project.id}/${file.name}_750x485${file.ext}`;
    }

    getFileSrcsetUrl (file) {
      return `${window.SERVER_URL}/file/project/${vm.project.id}/${file.name}_750x485${file.ext} 750w, ${window.SERVER_URL}/file/project/${vm.project.id}/${file.name}${file.ext} 1306w`;
    }

    getSlideBackground (file) {
      return `url('${vm.getFileUrl(file)}'), url('assets/images/default.jpg');`;
    }

    getUserProfileImage () {
      if (!_.get(vm.project, 'user.profileImageUrl')) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + vm.project.user.profileImageUrl;
    }

    dateTime (date) {
      return moment(date).format();
    }

    showPhone () {
      vm.phoneNumberShown = true;
      projectService.phoneNumberShown(vm.project.id);
      eventsService.create('projectShowPhone', vm.project.id);
    }

    getOrgLogoUrl () {
      if (_.get(vm, 'project.org.logoUrl')) {
        return window.SERVER_URL + vm.project.org.logoUrl;
      }
      if (_.get(vm, 'project.user.profileImageUrl')) {
        return window.SERVER_URL + vm.project.user.profileImageUrl;
      }
      return 'assets/images/user.png';
    }

    isDescriptionOverflowing () {
      if (!vm.projectDescriptionElement) {
        vm.projectDescriptionElement = document.querySelector('.project-description');
      }
      if (!vm.projectDescriptionElement) { return false; }
      return vm.projectDescriptionElement.scrollHeight > 160;
    }
  }

  return new ProjectShowController();
}
