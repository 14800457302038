let $uibModal;
let request;
let sweetAlert;

class mailingListService {
  constructor (_$uibModal, _request, _sweetAlert) {
    $uibModal = _$uibModal;
    request = _request;
    sweetAlert = _sweetAlert;
  }

  openForm () {
    $uibModal.open({
      component: 'mailingListForm',
      windowClass: 'mailing-list-form-modal',
      size: 'lg'
    });
  }

  register (entryDetails, search) {
    let text = 'נרשמת בהצלחה לקבלת עדכונים';
    if (search) { text = 'החיפוש נשמר בהצלחה'; }
    return request('/mailing-list/register', { entry: entryDetails })
      .then((res) => {
        sweetAlert.swal('', text, 'success', {
          buttons: false,
          timer: 3000
        });
        return res.data.data;
      });
  }

  update (entryDetails) {
    return request('/mailing-list/update', { entry: entryDetails })
      .then((res) => res.data.data);
  }

  getMailingList (queryParams) {
    return request('/mailing-list/listSaved?' + (queryParams ? jquery.param(queryParams) : ''))
      .then((res) => res.data.data);
  }

  deleteMailingList (mailingListId) {
    return request('/mailing-list/' + mailingListId, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

mailingListService.$inject = ['$uibModal', 'request', 'sweetAlert'];

const mailingListServiceModule = angular.module('mailingListService', [])
  .service('mailingListService', mailingListService)
  .name;

export default mailingListServiceModule;
