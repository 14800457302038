import angular from 'angular';

import messagesComponent from './messages.component';
import messageShow from './message-show/message-show';
import messagesService from './messages.service';

const messagesModule = angular.module('messages', [
  messageShow,
  messagesService
])
  .component('messages', messagesComponent)
  .name;

export default messagesModule;
