import _ from 'lodash';

export default function ($scope, $rootScope, $state, $timeout, profileService, utils) {
  let vm;

  class ProfileEditController {
    constructor () {
      vm = this;

      vm.title = {
        default: 'עריכת פרופיל',
        password: 'שינוי סיסמה',
        org: 'עריכת פרטי חברה'
      };

      vm.textMaskConfig = {
        phone: {
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          guide: false
        }
      };
    }

    $onInit () {
      vm.resolve.editMode = vm.resolve.editMode || 'default';
      vm.initFormFields($rootScope.user);

      profileService.getProfile()
        .then((user) => {
          vm.initFormFields(user);
        });

      if (vm.resolve.editMode === 'default') {
        $scope.$watchGroup(['vm.modelObject.password', 'vm.modelObject.password2'], (newVal, oldVal) => {
          if (vm.form.password2 && _.get(vm, 'modelObject.password2')) {
            vm.form.password2.$setValidity('passwordMatch', vm.modelObject.password === vm.modelObject.password2);
          }
        });
      }
    }

    initFormFields (user) {
      vm.modelObject = angular.copy(user);
      if (vm.resolve.editMode === 'org') {
        _.set(vm.modelObject, 'org.invoiceName', _.get(vm.modelObject, 'org.invoiceName') || _.get(vm.modelObject, 'org.name') || [_.get(vm.modelObject, 'firstName'), _.get(vm.modelObject, 'lastName')].join(' ') || '');
        _.set(vm.modelObject, 'org.invoiceEmail', _.get(vm.modelObject, 'org.invoiceEmail') || _.get(vm.modelObject, 'org.email') || _.get(vm.modelObject, 'email') || '');
      }
    }

    getUserProfileImage () {
      if (!$rootScope.user.profileImageUrl) {
        return '';
      }
      return window.SERVER_URL + $rootScope.user.profileImageUrl;
    }

    getOrgLogo () {
      if (!$rootScope.org.logoUrl) {
        return '';
      }
      return window.SERVER_URL + $rootScope.org.logoUrl;
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    closeModalBox () {
      vm.modalInstance.close();
    }

    saveProfile () {
      if (vm.saveProgress) { return; }
      if (vm.form.$invalid) {
        utils.setFormFieldsDirty(vm.form);
        $('#profile-form')[0].reportValidity();
        return;
      }
      vm.saveProgress = true;
      const query = {
        user: JSON.stringify(vm.modelObject)
      };

      if (vm.profileImage) {
        query.profileImage = vm.profileImage;
      }
      if (vm.orgLogo) {
        query.orgLogo = vm.orgLogo;
      }

      return profileService.saveProfile(vm.modelObject.id, query)
        .then((response) => {
          if ($rootScope.user.profileImageUrl) {
            $rootScope.user.profileImageUrl += ('?' + new Date().getTime());
          }
          if ($rootScope.org.logoUrl) {
            $rootScope.org.logoUrl += ('?' + new Date().getTime());
          }
          $rootScope.$broadcast('profileSaved');
          vm.closeModalBox();
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }
  }

  return new ProfileEditController();
}
