import angular from 'angular';
import jquery from 'jquery';

import 'animate.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-rtl/dist/css/bootstrap-rtl.min.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'quill';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'angular-loading-bar/src/loading-bar.css';

import uiRouter from 'angular-ui-router';
import ngSanitize from 'angular-sanitize';
import ngAnimate from 'angular-animate';
// import ngTouch from 'angular-touch';
import uiBootstrap from 'angular-ui-bootstrap';
import ngCookies from 'angular-cookies';
import angularSlick from 'angular-slick';
import ngFileUpload from 'ng-file-upload';
import textMask from 'angular1-text-mask';
import ngQuill from 'ng-quill';
import angularLoadingBar from 'angular-loading-bar';
import angularMarked from 'angular-marked';
import 'intl-tel-input';
import 'intl-tel-input/build/js/intlTelInput-jquery.min.js';
import 'intl-tel-input/build/js/utils.js';
import 'ng-intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.min.css';
import 'angular-timeago';
import 'angularjs-autogrow';
import 'angular-drag-and-drop-lists';

window.$ = jquery;

const vendorsModule = angular.module('app.vendors', [
  uiRouter,
  'dndLists',
  ngSanitize, // Angular-sanitize
  ngAnimate, // Angular animations
  // ngTouch,
  uiBootstrap, // AngularJS native directives for Bootstrap,
  ngCookies,
  angularSlick,
  ngFileUpload,
  textMask,
  ngQuill,
  angularLoadingBar,
  angularMarked,
  'ngIntlTelInput',
  'yaru22.angular-timeago',
  'angularjs-autogrow'
])
  .config(['ngIntlTelInputProvider', function (ngIntlTelInputProvider) {
    ngIntlTelInputProvider.set({
      initialCountry: 'il',
      preferredCountries: ['il', 'us', 'gb'],
      excludeCountries: ['ps', 'sy', 'af', 'lb', 'ir', 'iq']
    });
  }])
  .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }])
  .config(['timeAgoSettings', function (timeAgoSettings) {
    timeAgoSettings.overrideLang = 'he_IL';
  }])
  .name;

export default vendorsModule;
