import _ from 'lodash';

let $rootScope;
let request;
let fileRequest;

class profileService {
  constructor (_$rootScope, _request, _fileRequest) {
    $rootScope = _$rootScope;
    request = _request;
    fileRequest = _fileRequest;
  }

  getEmployeesList (orgId) {
    return request('/user/get-employees-list/' + orgId)
      .then((result) => {
        const org = _.get(result, 'data.data');
        return org;
      });
  }

  deleteUser (userId, orgId) {
    let body = { userId: userId, orgId: orgId }
    return request('/user/deleteUser', body, 'POST')
      .then((result) => {
        const res = _.get(result, 'data.data');
        return res;
      });
  }

  deleteAccount (user, org) {
    let body = { user: user }
    return request('/user/deleteUserAccount', body, 'POST')
      .then((result) => {
        const res = _.get(result, 'data.data');
        return res;
      });
  }

  getProfile () {
    return request('/user/i')
      .then((res) => {
        const user = _.get(res, 'data.data');
        $rootScope.user = user;
        $rootScope.org = _.get(user, 'org');
        return user;
      });
  }

  saveProfile (userId, userObject, manage) {
    let user;
    return fileRequest('/user/' + (userId || ''), userObject, (userId ? 'PUT' : 'POST'))
      .then((res) => {
        if (manage) { return }
        user = res.data.data;
        $rootScope.user = user;
        return request('/org/' + user.orgId);
      })
      .then((result) => {
        if (manage) { return }
        const org = _.get(result, 'data.data');
        $rootScope.org = org;
      })
      .then((result) => {
        if (manage) { return }
        return user;
      });
  }
}

profileService.$inject = ['$rootScope', 'request', 'fileRequest'];

const profileServiceModule = angular.module('profileService', [])
  .service('profileService', profileService)
  .name;

export default profileServiceModule;
