// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/success.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cancel-monthly-summary-email .status-icon-cancel{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-repeat:no-repeat;background-size:cover;width:70px;height:70px;margin:0 auto 30px}.cancel-monthly-summary-email .cancel-monthly-summary-email-wrapper{width:300px;max-width:100%;margin:50px auto 20px}.cancel-monthly-summary-email .cancel-monthly-summary-email-wrapper h1{line-height:1.4}.cancel-monthly-summary-email .cancel-monthly-summary-email-wrapper h3{font-size:26px;margin:0}.cancel-monthly-summary-email .cancel-monthly-summary-email-wrapper .form-control{display:block;width:100%;height:40px;font-size:16px;font-weight:400}\n", "",{"version":3,"sources":["webpack://./client/app/components/cancel-monthly-summary-email/cancel-monthly-summary-email.scss"],"names":[],"mappings":"AAAA,kDAGI,wDAA2D,CAC3D,0BAA2B,CAC3B,2BAA4B,CAC5B,qBAAsB,CACtB,UAAW,CACX,WAAY,CACZ,kBAAmB,CATvB,oEAaI,WAAY,CACZ,cAAe,CACf,qBAAsB,CAf1B,uEAkBM,eAAgB,CAlBtB,uEAsBM,cAAe,CACf,QAAS,CAvBf,kFA2BM,aAAc,CACd,UAAW,CACX,WAAY,CACZ,cAAe,CACf,eAAgB","sourcesContent":[".cancel-monthly-summary-email {\n\n  .status-icon-cancel {\n    background-image: url('../../../assets/images/success.png');\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    width: 70px;\n    height: 70px;\n    margin: 0 auto 30px;\n  }\n\n  .cancel-monthly-summary-email-wrapper {\n    width: 300px;\n    max-width: 100%;\n    margin: 50px auto 20px;\n\n    h1 {\n      line-height: 1.4;\n    }\n\n    h3 {\n      font-size: 26px;\n      margin: 0;\n    }\n\n    .form-control {\n      display: block;\n      width: 100%;\n      height: 40px;\n      font-size: 16px;\n      font-weight: 400;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
