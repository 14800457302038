let request;

class messagesService {
  constructor (_request) {
    request = _request;
  }

  getMessageList (type = 'inbox', args = {}) {
    return request('/message/list?type=' + type + '&' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  deleteOrRestore(messageId, action) {
    return request('/message/delete-or-restore', { messageId, action }, 'POST')
    .then((res) => res.data.data);
  }

  getMessage (id) {
    return request('/message/' + id)
      .then((res) => res.data.data);
  }

  sendPropertyMessage (message, propertyId, propertyUser) {
    return request('/message/send-property-message', { message, propertyId, propertyUser }, 'POST')
      .then((res) => res.data.data);
  }

  sendLeadMessage (message, userLead) {
    return request('/message/send-lead-message', { message, userLead }, 'POST')
      .then((res) => res.data.data);
  }

  sendProjectMessage (message, projectId) {
    return request('/message/send-project-message', { message, projectId }, 'POST')
      .then((res) => res.data.data);
  }

  sendMessagePost (messageId, to, postMessage) {
    return request(`/message/${messageId}/send`, { message: postMessage, to }, 'POST')
      .then((res) => res.data.data);
  }

  markAsRead (messageId) {
    return request(`/message/${messageId}/mark-as-read`, null, 'POST', { ignoreLoadingBar: true })
      .then((res) => res.data.data);
  }
}

messagesService.$inject = ['request'];

const messagesServiceModule = angular.module('messagesService', [])
  .service('messagesService', messagesService)
  .name;

export default messagesServiceModule;
