import angular from 'angular';

import banner from './banner/banner';
import dashboard from './dashboard/dashboard';
import home from './home/home';
import loginContainer from './login-container/login-container';
import mailingListForm from './mailing-list-form/mailing-list-form';
import manage from './manage/manage';
import newPassword from './new-password/new-password';
import org from './org/org';
import page from './page/page';
import project from './project/project';
import property from './property/property';
import unsubscribe from './unsubscribe/unsubscribe';
import user from './user/user';
import cancelMonthlySummaryEmail from './cancel-monthly-summary-email/cancel-monthly-summary-email';
import deleteItem from './deleteItem/deleteItem';
import accessibility from './page/accessibility/accessibility';
const componentModule = angular.module('app.components', [
  banner,
  dashboard,
  home,
  loginContainer,
  mailingListForm,
  manage,
  newPassword,
  org,
  page,
  project,
  property,
  unsubscribe,
  user,
  cancelMonthlySummaryEmail,
  deleteItem,
  accessibility
])
  .name;

export default componentModule;
