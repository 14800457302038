import template from './mailing-list-form.html';
import controller from './mailing-list-form.controller';
import './mailing-list-form.scss';

const mailingListFormComponent = {
  bindings: {
    modalInstance: '<?'
    // resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'mailingListService', 'utils'];

export default mailingListFormComponent;
