import _ from 'lodash';

export default function (manageService) {
  let vm;

  class ManageAddressController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.type = "street";
      vm.searchValue = '';
      vm.getAddress();
    }

    getAddress () {
      vm.loadProgress = true;

      if (vm.type === 'street') {
        return manageService.getAllStreets(vm.searchValue)
          .then((res) => {
            vm.list = res;
          })
          .finally(() => {
            vm.loadProgress = false;
          });
      }
      else {
        return manageService.getAllCities(vm.searchValue)
          .then((res) => {
            vm.list = res;
          })
          .finally(() => {
            vm.loadProgress = false;
          });
      }
    }

    deleteAddress (messageId) {
      manageService.deleteAddress(messageId, vm.type).
        then(() => {
          vm.getAddress();
        })
    }

    changeType (type) {
      if (vm.type !== type) {
        vm.type = type;
        vm.getAddress();
      }
    }
  }

  return new ManageAddressController();
}
