let request;

class orgService {
  constructor (_request) {
    request = _request;
  }

  getOrg (orgId, includeUsers) {
    return request('/org/' + orgId + (includeUsers ? '?includeUsers=true' : ''))
      .then((res) => res.data.data);
  }

  getOrgs () {
    if (this.orgs) {
      return Promise.resolve(this.orgs);
    }
    return request('/org/')
      .then((res) => {
        this.orgs = res.data;
        return this.orgs;
      });
  }
}

orgService.$inject = ['request'];

const orgServiceModule = angular.module('orgService', [])
  .service('orgService', orgService)
  .name;

export default orgServiceModule;
