import _ from 'lodash';
import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $location, $timeout, $filter, $uibModal, $sce, propertyService, categoryService, savedSearchesService, mailingListService) {
  let vm;

  class PropertiesListPageController {
    constructor () {
      vm = this;

      vm.listTitleOptions = options.propertyListTitleOptions;
      vm.yieldFilterOptions = options.yieldFilterOptions;

      vm.typeList = {
        forSale: 'נכסים למכירה',
        forRent: 'נכסים להשכרה',
        producing: 'נכסים מניבים',
        projects: 'פרויקטים'
      };

      vm.orgKindOptions = {
        private: { title: 'פרטי' },
        brokerOpen: { title: 'תיווך (פתוח לשת"פ)' },
        brokerClosed: { title: 'תיווך (לא פתוח לשת"פ)' }
      };

      vm.categoryList = {
        forSale: options.propertySaleCategoryOptions,
        forRent: options.propertyRentCategoryOptions,
        producing: options.propertySaleCategoryOptions
      };
      vm.nadlanList = {
        forSale: options.propertySaleCategoryOptionsNadlan,
        forRent: options.propertyRentCategoryOptionsNadlan,
      };
      vm.categorySaleList = categoryService.forSaleListHeader;
      vm.categoryRentList = categoryService.forRentListHeader;
      vm.businessesList = options.propertyBusinessesForSaleOptions;
      vm.selectedBusinesses = [];
      vm.selectedCategories = [];
      vm.selectedKind = [];
      vm.searchFields = {};
      vm.filters = {};
      vm.mailingListModel = {};
      vm.isShowMap = false;
      vm.dropdownIsOpen = false;
      vm.businessesForSale = false;
    }

    $onInit () {
      if (vm.categoryPage?.h1) { vm.title = vm.categoryPage.h1 ? vm.categoryPage.h1 : ''; }
      vm.cityCategorylimit = 14;
      vm.articleHeight = '145px';
      vm.stateName = $state.current.name;
      vm.frequency = "daily";
      vm.openPop = false
      switch (vm.stateName) {
        case 'forSaleList':
          vm.h1 = 'נכסים מסחריים למכירה';
          break;
        case 'forRentList':
          vm.h1 = 'נכסים מסחריים להשכרה';
          break;
        case 'producingList':
          vm.h1 = 'נכסים מניבים למכירה';
          break;
        case 'producingShowCity':
          vm.h1 = 'נכסים מניבים למכירה';
          break;
        case 'producingShowDistrict':
          vm.h1 = 'נכסים מניבים למכירה';
          break;
        case 'nadlanList':
          vm.h1 = 'מידע נדל"ן';
          break;
        case 'projects':
          vm.h1 = 'מידע נדל"ן';
          break;
      }
      vm.filters = {
        query: $state.params.query || vm.city || '',
        category: []
      };
      vm.query = $state.params.query || vm.city || '';
      vm.type = ['forRentList', 'categoryRentShow', 'cityRentShow', 'categoryRentShowDistrict', 'categoryRentShowCity'].includes(vm.stateName) ? 'forRent' : ['producingList', 'producingShowCity', 'producingShowDistrict'].includes(vm.stateName) ? 'producing' : vm.stateName === 'projectsforSaleList' ? 'projectsforSaleList' : 'forSale';
      if (vm.stateName === 'nadlanList') {
        vm.nadlan = true
        vm.type = $state.params.type ? $state.params.type : 'forSale';
        if ($state.params.category) {
          vm.title = vm.categoryList[vm.type][$state.params.category].title;
        }
      }

      if (vm.stateName === 'projectsforSaleList') {
        vm.projects = true
      }

      if (!vm.projects) {
        if (!vm.nadlan) {
          const kindList = ($state.params.kind || '').split(',') || [];
          if (kindList.length) {
            kindList.forEach((kindName) => {
              vm.selectedKind[kindName] = true;
            });
            vm.toggleKindSelect();
          }
        }
        ['minPrice', 'maxPrice', 'minSquareMeter', 'maxSquareMeter', 'yieldFilter'].forEach((key) => {
          if ($state.params[key]) {
            vm.filters[key] = $state.params[key];
            vm.searchFields[key] = $state.params[key];
            vm.updateUrlParam(key);
          }
        });
        vm.changeRangeFilters();

        if (vm.categoryPage && !vm.city) {
          vm.shownCategory = $state.params.category;
          vm.forSaleCategoriesSideNav = categoryService.forSaleList;
          vm.forRentCategoriesSideNav = categoryService.forRentList;
          vm.cityCategorySideNav = categoryService.cityCategorySideNav;
          vm.districtCategorySideNav = categoryService.districtCategorySideNav;
          if ($state.params.category === 'businessesForSale') {
            vm.showBusinesses = true;;
          }
          vm.filters.category = vm.selectedCategoriesUrlQuery;
          if ($state.params.city || $state.params.district) {
            let city = vm.cityCategorySideNav[$state.params.city];
            let district = vm.districtCategorySideNav[$state.params.district]
            let place = city ? city : district;
            vm.categoryCity = place;
            if (vm.title && !vm.city) {
              vm.title = vm.categoryPage.h1 + " ב" + place;
              vm.fourth = true;
            }
            vm.query = place;
          }
          if ($state.params.category === 'plot') {
            vm.selectedCategories.constructionPlot = true;
            vm.selectedCategories.agriculturalPlot = true;
          }
        }

        if (!vm.nadlan) {
          const categoryList = ($state.params.category || '').split(',') || [];
          if (categoryList.length) {
            categoryList.forEach((categoryName) => {
              if (categoryName === 'businessesForSale') {
                vm.businessesForSale = true;
              }
              vm.selectedCategories[categoryName] = true;
            });
            vm.toggleCategorySelect();
          }
        }

        if ($state.params.yieldFilter) {
          vm.selectYieldFilter($state.params.yieldFilter);
        }
      }
    }

    $onDestroy () {
      vm.showMapChanged(false);
    }

    listenToScroll () {
      vm.filtersElement = undefined;
      window.addEventListener('scroll', (n) => {
        checkOffset(vm);
      });
      checkOffset(vm);
    }

    queryChanged2 () {
      let params = $location.search();
      params.query = vm.query;
      params.column = vm.searchFields.column ? JSON.stringify(vm.searchFields.column) : null;
      $location.search(params);
      if (!$scope.$$phase) {
        $scope.$apply()
      }
    }

    queryChanged () {
      vm.filters.query = vm.query;
      let column = vm.searchFields.column
      let filters = vm.filters
      setTimeout(() => {
        let url
        if ($location.$$path.includes("rent-category")) {
          url = '/for-rent/?';
          if (vm.filters.category) { url += 'category=' + vm.filters.category };
        }
        else {
          url = '/for-sale/?';
          if (vm.shownCategory) { url += 'category=' + vm.shownCategory };
        }
        if (vm.query) { url += '&query=' + vm.query };
        if (column) { url += '&column=' + JSON.stringify(column) };
        if (filters.minPrice) { url += '&minPrice=' + filters.minPrice };
        if (filters.maxPrice) { url += '&maxPrice=' + filters.maxPrice };
        if (filters.minSquareMeter) { url += '&minSquareMeter=' + filters.minSquareMeter };
        if (filters.maxSquareMeter) { url += '&maxSquareMeter=' + filters.maxSquareMeter };
        if (filters.kind) { url += '&kind=' + filters.kind };
        if ($state.params.city) {
          window.location.replace(url);
        }
        else {
          $location.search('column', vm.searchFields.column ? JSON.stringify(vm.searchFields.column) : null);
          // vm.addSearch(window.location.href);
        }
        if (!$scope.$$phase) {
          $scope.$apply()
        }
      }, 200);

      if (!vm.city) {
        vm.updateUrlParam('query');
      }
    }

    getCookie (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    addSearch (url) {
      const column = vm.searchFields.column ? vm.searchFields.column : $state.params.column ? JSON.parse($state.params.column) : undefined;
      const category = vm.filters.category
      if (column) {
        let arr = [];
        if (vm.getCookie("recentSearches")) {
          arr = JSON.parse(vm.getCookie("recentSearches"))
        }
        if (arr.length < 10 && (column.city || column.district)) {
          const city = column.city ? column.city : column.district
          let exist = arr.find(i => i.key === city);
          if (exist) {
            let newArr = arr.filter(i => i.key !== exist.key)
            newArr.push({ key: city, url: url, category: category, state: vm.stateName, query: vm.query })
            arr = newArr;
          }
          else {
            arr.push({ key: city, url: url, category: category, state: vm.stateName, query: vm.query });
          }
          document.cookie = `recentSearches=${JSON.stringify(arr)}; path=/`;
        }
      }
    }

    showBusinessesList (val) {
      vm.businessesForSale = !val
      if (vm.businessesForSale) {
        Object.keys(vm.categoryList[vm.type]).forEach((type) => {
          vm.selectedCategories[type] = false;
        });
        vm.selectedCategories['businessesForSale'] = true
        vm.selectedCategoriesUrlQuery = 'businessesForSale';
        vm.selectedCategoriesString = 'עסקים למכירה';
        vm.filters.category = vm.selectedCategoriesUrlQuery;
        vm.showBusinesses = true;
        if (!vm.categoryPage || vm.city) {
          vm.updateUrlParam('category');
        }
      }
      else {
        vm.resetCategoryFilters();
        vm.hideBusinessesList();
        vm.showBusinesses = false;
        if (!vm.categoryPage || vm.city) {
          vm.updateUrlParam('category');
        }
      }
    }

    hideBusinessesList () {
      vm.selectedCategories['businessesForSale'] = null
      vm.resetBusinessesFilters();
      vm.showBusinesses = false;
      vm.businessesForSale = false;
    }

    toggleKindSelect () {
      const checkedList = [];
      Object.keys(vm.orgKindOptions).forEach((kind) => {
        if (vm.selectedKind[kind]) {
          checkedList.push({
            name: kind,
            title: vm.orgKindOptions[kind].title
          });
        }
      });
      vm.selectedkindUrlQuery = _.map(checkedList, 'name').join(',') || null;
      vm.selectedkindString = _.map(checkedList, 'title').join(', ') || '';
      vm.filters.kind = vm.selectedkindUrlQuery;

      if (!vm.categoryPage || vm.city) {
        vm.updateUrlParam('kind');
      }
    }

    resetKindFilters () {
      Object.keys(vm.orgKindOptions).forEach((kind) => {
        vm.selectedKind[kind] = false;
      });
      vm.filters.kind = '';
      vm.selectedkindString = '';
      vm.updateUrlParam('kind');
    }

    toggleBusinessesSelect () {
      const checkedList = [];
      Object.keys(vm.businessesList).forEach((category) => {
        if (vm.selectedBusinesses[category]) {
          checkedList.push({
            name: category,
            title: _.get(vm.businessesList, category + '.title')
          });
        }
      });
      vm.selectedBusinessesUrlQuery = _.map(checkedList, 'name').join(',') || null;
      vm.selectedBusinessesString = _.map(checkedList, 'title').join(', ') || '';
      vm.filters.businesses = vm.selectedBusinessesUrlQuery;
      // if (!vm.categoryPage || vm.city) {
      vm.updateUrlParam('businesses');
      // }
    }
    toggleCategorySelect () {
      const checkedList = [];
      if (!vm.shownCategory) {
        vm.hideBusinessesList();
      }
      Object.keys(vm.categoryList[vm.type]).forEach((category) => {
        if (vm.selectedCategories[category]) {
          checkedList.push({
            name: category,
            title: _.get(vm.categoryList, vm.type + '.' + category + '.title')
          });
        }
      });
      vm.selectedCategoriesUrlQuery = _.map(checkedList, 'name').join(',') || null;
      vm.selectedCategoriesString = _.map(checkedList, 'title').join(', ') || '';
      vm.filters.category = vm.selectedCategoriesUrlQuery;
      if (!vm.categoryPage || vm.city || vm.type === 'producing') {
        vm.updateUrlParam('category');
      }
    }
    selectedCategory (k, title) {
      vm.title = title;
      $location.path(`/nadlan-information/${vm.type}/${k}`);
    }
    selectedType (val) {
      vm.type = val;
      $location.path(`/nadlan-information/${val}/`);
    }

    resetBusinessesFilters () {
      Object.keys(vm.businessesList).forEach((type) => {
        vm.selectedBusinesses[type] = false;
      });
      vm.filters.businesses = '';
      vm.selectedBusinessesString = '';
      vm.updateUrlParam('businesses');
    }

    resetCategoryFilters () {
      Object.keys(vm.categoryList[vm.type]).forEach((type) => {
        vm.selectedCategories[type] = false;
      });
      vm.filters.category = '';
      vm.selectedCategoriesString = '';
      vm.showBusinesses = false;
      vm.updateUrlParam('category');
    }

    updateUrlParam (key, value) {
      $state.params[key] = value || vm.filters[key] || null;
      $location.search(key, value || vm.filters[key] || null);
      setTimeout(() => {
        vm.addSearch(location.href);
      }, 200);
      vm.searchSaved = false;
    }

    selectYieldFilter (optionKey) {
      const option = _.find(vm.yieldFilterOptions, { value: optionKey });
      vm.selectedYieldFilterString = optionKey ? option.label : '';
      vm.filters.yieldFilter = option.value;
      vm.updateUrlParam('yieldFilter');
    }

    debounceFilterChange () {
      $timeout.cancel(vm.queryTimeout); // cancel the last timeout
      vm.queryTimeout = $timeout(() => {
        ['minPrice', 'maxPrice', 'minSquareMeter', 'maxSquareMeter'].forEach((key) => {
          vm.filters[key] = vm.searchFields[key];
          vm.updateUrlParam(key);
        });
        vm.changeRangeFilters();
      }, 700);
    }

    changeRangeFilters () {
      vm.selectedPriceFilterString = '';
      vm.selectedSquareMeterFilterString = '';
      if (vm.filters.minPrice) {
        vm.selectedPriceFilterString = 'מ - ' + $filter('number')(vm.filters.minPrice, 0) + ' ₪ ';
      }
      if (vm.filters.maxPrice) {
        vm.selectedPriceFilterString += 'עד - ' + $filter('number')(vm.filters.maxPrice, 0) + ' ₪';
      }
      if (vm.filters.minSquareMeter) {
        vm.selectedSquareMeterFilterString = 'מ - ' + $filter('number')(vm.filters.minSquareMeter, 0) + ' מ"ר ';
      }
      if (vm.filters.maxSquareMeter) {
        vm.selectedSquareMeterFilterString += 'עד - ' + $filter('number')(vm.filters.maxSquareMeter, 0) + ' מ"ר';
      }
    }

    openPopup () {
      vm.openPop = true
    }

    async saveSearch () {
      if ($state.params.column) {
        const column = JSON.parse($state.params.column);
        vm.mailingListModel.city = column.city,
          vm.mailingListModel.street = column.street,
          vm.mailingListModel.district = column.district
      }
      if ($rootScope.user) {
        vm.mailingListModel.name = [$rootScope.user.firstName, $rootScope.user.lastName].join(' ');
        vm.mailingListModel.email = $rootScope.user.email;
        vm.mailingListModel.phone = $rootScope.user.phone;
        vm.mailingListModel.role = $rootScope.org.kind;
      }
      vm.mailingListModel.frequency = vm.frequency ? vm.frequency : null;
      vm.mailingListModel.minPrice = vm.filters.minPrice;
      vm.mailingListModel.maxPrice = vm.filters.maxPrice;
      vm.mailingListModel.minSquareMeter = vm.filters.minSquareMeter;
      vm.mailingListModel.maxSquareMeter = vm.filters.maxSquareMeter;
      vm.mailingListModel.propertyType = vm.type;
      vm.mailingListModel.propertyCategory = vm.filters.category;
      vm.mailingListModel.address = vm.filters.query;

      if (vm.saveSearchProgress) { return; }
      let user = $rootScope.user;

      if (!user) {
        vm.saveSearchProgress = true;
        user = await $uibModal.open({
          component: 'loginContainer',
          resolve: {
            loginTitle: () => 'לשמור חיפושים'
          },
          windowClass: 'login-modal',
          size: 'sm'
        })
          .result
          .then((user) => {
            return $timeout(() => user, 400);
          })
          .catch(() => {
            vm.saveSearchProgress = false;
          });
      }

      if (!user) {
        vm.saveSearchProgress = false;
        return;
      }
      vm.saveSearchProgress = true;
      mailingListService.register(vm.mailingListModel, !vm.frequency)
        .then(() => {
          vm.closePop();
          vm.searchSaved = true;
        })
        .finally(() => {
          vm.saveSearchProgress = false;
        });
    }

    closePop () {
      vm.openPop = false
    }

    getItemCategories (item) {
      if (!item) {
        return '';
      }
      return item.split(',').map((category) => vm.categoryList[vm.type][category].title || '').join(', ');
    }

    filtersAreEmpty () {
      return !Object.values(vm.filters).filter((value) => value).length;
    }

    showMapChanged (show) {
      show ? document.body.classList.add('show-map') : document.body.classList.remove('show-map');
    }
  }

  return new PropertiesListPageController();
}

function checkOffset (vm) {
  if (!vm.filtersElement) {
    vm.filtersElement = document.querySelector('.filters');
  }
  if (!vm.filtersElement) { return; }
  window.scrollY ? vm.filtersElement.classList.add('top') : vm.filtersElement.classList.remove('top');
}
