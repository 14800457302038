export default function () {
  let vm;

  class ChoosePlanController {
    constructor () {
      vm = this;
      vm.name = 'choosePlan';
    }
  }

  return new ChoosePlanController();
}
