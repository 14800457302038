import template from './main.html';
import controller from './main.controller';
import './main.scss';

const mainComponent = {
  bindings: {
    fixedSideMenu: '<?',
    minimalFooter: '<?',
    hideAddBtn: '<?'
  },
  transclude: true,
  template,
  controller,
  controllerAs: 'vm'
};

export default mainComponent;
