import isUrl from 'is-url';

export default function (orgService, manageService) {
  let vm;

  class ManagePluginController {
    constructor () {
      vm = this;
      vm.pluginDetails = {};
      vm.orgs = [];
      vm.pluginsSettings = {};
      vm.projects = [];
    }

    $onInit () {
      vm.pluginDetails = vm.resolve.pluginDetails;
      vm.orgs = vm.getOrgs();
      vm.pluginsSettings = vm.getPluginsSettings();
      vm.projects = vm.getProjects();
    }

    getOrgs () {
      orgService.getOrgs()
        .then((orgs) => {
          vm.orgs = orgs.data;
        });
    }

    getPluginsSettings () {
      manageService.getPluginsSettings()
        .then((settings) => {
          vm.pluginsSettings = settings.list;
        });
    }

    save () {
      manageService.setPlugin(vm.pluginDetails)
        .then(() => {
          vm.modalInstance.close();
        })
        .catch(console.error);
    }

    cancel () {
      vm.modalInstance.close();
    }

    getProjects () {
      manageService.getManageProjects()
        .then((res) => {
          vm.projects = res.rows;
        });
    }
  }

  return new ManagePluginController();
}
