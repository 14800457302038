import angular from 'angular';

import template from './video-edit.html';
import './video-edit.scss';

const videoEditModule = angular.module('videoEdit', [])
  .directive('videoEdit', [function () {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        model: '=?'
      },
      template,
      controller () {
        const vm = this;
      }
    };
  }])
  .name;

export default videoEditModule;
