function categoryService () {
  return {
    forSaleListHeader: {
      office: {
        label: 'משרדים',
      },
      commerce: {
        label: 'נדל"ן מסחרי',
      },
      store: {
        label: 'חנויות',
      },
      industry: {
        label: 'מבני תעשיה',
      },
      storage: {
        label: 'מחסנים',
      },
      plot: {
        label: 'מגרשים',
      },
      // apartmentsInvestment: {
      //   label: 'דירות להשקעה',
      // },
      vacation: {
        label: 'צימרים',
      },
      businessesForSale: {
        label: 'עסקים',
      },
      // nursingHome: {
      //   label: 'בית אבות',
      // },
      // parking: {
      //   label: 'חניונים',
      // },
      // residentialBuilding: {
      //   label: 'מגורים',
      // },
      // hotel: {
      //   label: 'מלון',
      // },
      // gasStation: {
      //   label: 'תחנות דלק',
      // },
      // agriculturalPlot: {
      //   label: 'מגרשים חקלאיים',
      // },
      // constructionPlot: {
      //   label: 'מגרשים לבניה',
      // },

    },
    forRentListHeader: {
      office: {
        label: 'משרדים',
      },
      storage: {
        label: 'מחסנים',
      },
      store: {
        label: 'חנויות',
      },
      industry: {
        label: 'מבני תעשיה',
      },
      hall: {
        label: 'אולמות',
      },
      // workSpace: {
      //   label: 'חלל עבודה משותף',
      // },
      commerce: {
        label: 'נדל"ן מסחרי',
      },
      clinics: {
        label: 'קליניקות',
      },
      plot: {
        label: 'מגרשים',
      },
      // parking: {
      //   label: 'חניונים',
      // },
      // gasStation: {
      //   label: 'תחנות דלק',
      // },
      // vacation: {
      //   label: 'צימרים',
      // },
      // general: {
      //   label: 'מבנים כלליים',
      // },
    },
    forSaleList: {
      store: {
        label: 'חנויות',
        title: 'חנויות מניבות למכירה בכל חלקי הארץ. אלפי נכסים באתר! – מניבים ישראל',
        h1: 'חנויות למכירה',
        description: 'מחפשים חנויות מניבות למכירה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של חנויות מניבות למכירה בכל חלקי הארץ, אשר מתאימות להשכרה ואשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: `
        <h2>חנויות למכירה – השקעה מניבה</h2>
        <p>אם אתם מחפשים דרך להרוויח כסף, חנויות למכירה יכולות להיות הבחירה האולטימטיבית עבורכם. בין אם אתם רוצים לפתוח חנות משלכם ובין אם אתם רוצים להשכיר את החנות למישהו אחר – אם אתם בוחרים את החנות הנכונה אתם יכולים לקבל הכנסה חודשית לא רעה בכלל.</p>
        <h2>איך בוחרים חנות?</h2>
        <p>אם אתם חושבים בכיוון של רכישת חנות למכירה, חשוב מאוד שתבחרו אותה על פי רשימת הפרמטרים הבאה:</p>
        <ul>
          <li><b>מיקום החנות</b> – למקום בו נמצאת החנות יש חשיבות עצומה בכל מה שקשור לתשואה שתקבלו ממנה. אחרי הכל, יש מקומות שבהם דמי השכירות גבוהים יותר ואחרים בהם דמי השכירות נמוכים, ואם תעשו סקר מחירים והשוואות מעמיקות – תוכלו להשיג את העסקה המשתלמת ביותר.</li>
          <li><b>גודל החנות</b> – כמובן שגודל החנות משפיע מאוד הן על גובה המחיר שתצטרכו לשלם בעבורה והן על דמי השכירות שתוכלו לגבות בעבורה אם זה הייעוד שלשמו אתם רוכשים אותה; אם תבחרו חנות מאוד גדולה או מאוד קטנה היא תוכל להתאים רק למספר מאוד מצומצם של תחומי עיסוק ולכן, כדאי מאוד לרכוש חנות בגודל ממוצע שיכול להתאים להרבה מאוד בעלי עסקים.</li>
          <li><b>ליווי עד להצלחה</b> – לפני שאתם רוכשים חנות, חשוב מאוד לרכז את כל נתוני התקציב שלכם ואת המטרות שאתם רוצים שהנכס הזה יסייע לכם להשיג, ועל פי המסקנות להחליט אם לרכוש חנות ואם כן – לבחור את החנות הנכונה עבורכם. על מנת להוציא את כל ההמלצות הללו מהכוח אל הפועל מומלץ לשכור את שירותיה של חברת ייעוץ מקצועי בתחום הנדל"ן, אשר תסייע לכם לעשות את העסקה הנכונה בתחום של חנויות למכירה.</li>
        </ul>`
      },
      nursingHome: {
        label: 'בית אבות',
        title: '',
        h1: 'בתי אבות למכירה',
        description: 'מחפשים בתי אבות למכירה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של בתי אבות למכירה בכל חלקי הארץ, אשר מתאימות להשכרה ואשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: ''
      },
      parking: {
        label: 'חניונים',
        title: 'חניונים למכירה בכל הארץ מוצאים במניבים ישראל (אינדקס מתעדכן)',
        h1: 'חניונים למכירה',
        description: 'כשאתם מחפשים חניונים למכירה אתם צריכים אינדקס מעודכן בו תוכלו למצוא את הפרטים אודות חניונים למכירה שמתאימים לצרכים ולהעדפות שלכם. מניבים ישראל הוא אינדקס מתעדכן בו תוכלו למצוא מידע מפורט אודות כל חניון למכירה שמופיע באתר, וכך לאתר את החניונים הרלוונטיים בקלות ובמהירות. היכנסו עכשיו ומצאו את החניון הבא שלכם >>>',
        content: `<h2>חניונים למכירה - נכסים מניבים</h2>
        <p>חניונים למכירה יכולים להיות נכס מניב מצוין, אם רוכשים את החניון הנכון באזור הנכון. אם אתם חושבים על רכישה של חניון, חשוב לעבוד מול חברה המציעה מספר חניונים למכירה ואשר יועציה ילוו אתכם עד לבחירת החניון שיתאים בצורה הטובה ביותר לצרכים ולהעדפות שלכם, וכאן אנחנו נכנסים לתמונה.</p>`
      },
      vacation: {
        label: 'צימרים',
        title: '',
        h1: 'צימרים למכירה',
        description: 'מחפשים צימרים למכירה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של צימרים למכירה בכל חלקי הארץ, אשר מתאימות להשכרה ואשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: ''
      },
      residentialBuilding: {
        label: 'בנייני מגורים',
        title: 'בנייני מגורים למכירה מוצאים במניבים ישראל (מאגר נכסים למכירה 2024)',
        h1: 'בנייני מגורים למכירה',
        description: 'מחפשים בנייני מגורים למכירה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של בנייני מגורים למכירה בכל חלקי הארץ, אשר מתאימים להשכרה ואשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: `<h3>מגורים - נכסים מניבים</h3>
        <p>חיפוש נכסים למכירה הופך להיות פשוט ואפקטיבי עם מניבים ישראל – אינדקס נכסים למכירה מניבים בהתאמה לצרכים האישיים שלכם. היכנסו עכשיו למנוע החיפוש החכם של האתר ותוך מספר שניות תגלו איזה נכסים למכירה מתאימים לדרישות, לצרכים ולהעדפות שלכם. מאגר הנכסים למכירה של האינדקס מתעדכן באופן שוטף. היכנסו עכשיו >>></p>`
      },
      apartmentsInvestment: {
        label: 'דירות להשקעה',
        title: 'דירות להשקעה למכירה מוצאים במניבים ישראל (מאגר נכסים למכירה 2024)',
        h1: 'דירות להשקעה למכירה',
        description: 'מחפשים דירות להשקעה למכירה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של דירות להשקעה למכירה בכל חלקי הארץ, אשר מתאימות להשכרה ואשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: `<h3>דירות להשקעה - נכסים מניבים</h3>
        <p>חיפוש נכסים למכירה הופך להיות פשוט ואפקטיבי עם מניבים ישראל – אינדקס נכסים למכירה מניבים בהתאמה לצרכים האישיים שלכם. היכנסו עכשיו למנוע החיפוש החכם של האתר ותוך מספר שניות תגלו איזה נכסים למכירה מתאימים לדרישות, לצרכים ולהעדפות שלכם. מאגר הנכסים למכירה של האינדקס מתעדכן באופן שוטף. היכנסו עכשיו >>></p>`
      },
      hotel: {
        label: 'מלון',
        title: 'מלונות למכירה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'מלונות למכירה',
        description: 'מחפשים מלונות למכירה? אתם בדיוק במקום הנכון. מניבים ישראל הוא אינדקס נכסים בכל חלקי הארץ לרבות מלונות למכירה, המביא לכם את הנכסים המעודכנים ביותר ומאפשר לכם למצוא את המלון שמתאים באופן מדויק לצרכים ולהעדפות שלכם. היכנסו עכשיו אל מניבים ישראל בקטגוריית מלונות למכירה ותוכלו להתרשם באופן מיידי מההיצע >>>',
        content: ''
      },
      commerce: {
        label: 'נדל"ן מסחרי',
        title: 'נדלן מסחרי למכירה זה מניבים ישראל (כרגע באתר למעלה מ 300 נכסים).',
        h1: 'נדל"ן מסחרי למכירה',
        description: 'נדלן מסחרי למכירה מחפשים ומוצאים במניבים ישראל. מבחר גדול ביותר של נכסים מניבים, לרבות נדלן מסחרי למכירה שיוכל להניב לכם יותר. היכנסו עכשיו למנוע החיפוש באתר ותוכלו למצוא בתוך שניות את אותם נכסים מסחריים מניבים שמתאימים בדיוק לצרכים ולהעדפות האישיים שלכם.',
        content: `<h3>נדל"ן מסחרי למכירה</h3>
        <p>ההגדרה נדל"ן מסחרי למכירה כוללת כל נכס שאינו מיועד לשימוש פרטי, לרבות חנויות, קניונים, מסעדות ועסקים אחרים. אפשר כמובן לרכוש נכסים כאלה לשימוש פרטי, ואפשר לרכוש אותם על מנת שישמשו נכס מניב. בכל מקרה, חשוב לרכוש נדל"ן מסחרי למכירה שיניב תשואות גבוהות, ואנחנו כאן כדי שזה יקרה.</p>`
      },
      office: {
        label: 'משרדים',
        title: 'משרדים למכירה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'משרדים למכירה',
        description: 'מחפשים משרדים למכירה? אתם בדיוק במקום הנכון. מניבים ישראל הוא אינדקס נכסים בכל חלקי הארץ לרבות משרדים למכירה, המביא לכם את הנכסים המעודכנים ביותר ומאפשר לכם למצוא את המשרד שמתאים באופן מדויק לצרכים ולהעדפות שלכם. היכנסו עכשיו אל מניבים ישראל בקטגוריית משרדים למכירה ותוכלו להתרשם באופן מיידי מההיצע >>>',
        content: `<h2>משרדים למכירה - נכסים מניבים</h2>
        <p>אם אתם מחפשים משרדים למכירה על מנת להשכיר אותם ולספק לעצמכם מקור הכנסה חודשי נוסף – אתם עושים צעד נכון. על מנת לבחור את המשרד הנכון אשר באמת יספק לכם הכנסה יפה, חשוב מאוד שילוו אתכם אנשי מקצוע המבינים בתחום של נכסים מניבים בכלל ושל משרדים למכירה בפרט. בדיוק בשביל זה אנחנו כאן.</p>
        <p>מניבים ישראל מציעה מגוון רחב של משרדים למכירה בכל רחבי הארץ: משרדים למכירה בתל אביב, רעננה, אשדוד, פתח תקווה, כפר סבא, חדרה, בני ברק, ראשון לציון, נתניה, רמת גן, עפולה, נצרת עילית ועוד הרבה מאוד אזורים, כך שאם אתם מחפשים משרד – לא משנה מאיפה אתם – סביר להניח שתוכלו למצוא את המשרד שלכם כאן.</p>
        <h2>מניבים השקעה</h2>
        <p>משרדים למכירה הם נכסים מניבים במלוא מובן המילה. כל מה שאתם צריכים לעשות הוא לבחור את המשרד המתאים באזור המתאים, ולהשכיר אותו ללקוח סופי. אם בחרתם נכון – יכולה להיות לכם כך הכנסה מצוינת למשך שנים.</p>
        <p>רוצים לבחור את המשרד שיניב עבורכם הכנסה חודשית מצוינת? אתם מוזמנים ליצור עמנו קשר עוד היום ונמליץ לכם על משרדים למכירה באזור המתאים שיסייעו לכם בהגשמת המטרה. צוות היועצים שלנו מונה אנשי מקצוע מוסמכים בתחומי הנדל"ן השונים, כך שאתם יכולים להיות בטוחים שהנכס שתבחרו יאפשר לכם לשדרג את רמת החיים.</p>`
      },
      gasStation: {
        label: 'תחנות דלק',
        title: 'תחנות דלק למכירה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'תחנות דלק למכירה',
        description: 'מחפשים תחנות דלק למכירה? אתם בדיוק במקום הנכון. מניבים ישראל הוא אינדקס נכסים בכל חלקי הארץ לרבות תחנות דלק למכירה, המביא לכם את הנכסים המעודכנים ביותר ומאפשר לכם למצוא את התחנת דלק שמתאים באופן מדויק לצרכים ולהעדפות שלכם. היכנסו עכשיו אל מניבים ישראל בקטגוריית תחנות דלק למכירה ותוכלו להתרשם באופן מיידי מההיצע >>>',
        content: ''
      },
      industry: {
        label: 'מבני תעשיה',
        title: 'מבני תעשיה למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מבני תעשיה למכירה',
        description: 'אם אתם מחפשים מבנה תעשיה למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מבנה תעשיה למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מבנה תעשיה למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: `<h2>מבני תעשיה למכירה רוכשים ממניבים</h2>
          <p>רכישה של מבני תעשיה למכירה היא השקעה מצוינת לטווח ארוך. אם בוחרים אותם נכון – ניתן להשתמש במבנים לצורך הפעלת עסק עצמאי או להשכיר אותם לאחרים, שתי אפשרויות שמניבות תשואה היכולה להוות במשך הרבה מאוד שנים יופי של הכנסה.</p>
          <h2>איך בוחרים מבנה תעשיה למכירה?</h2>
          <p>על מנת להגיע לתוצאה הרצויה – חשוב מאוד לבחור מבני תעשיה אשר מתאימים לתקציב שלכם מבחינת העלות, ואם אתם מתכוונים להשכיר אותם – כאלה שיספקו לכם תשואה יפה באמצעות דמי השכירות. על מנת לעשות את הבחירה הנכונה של נכסים כאלה, מומלץ לבסס אותה על הפרמטרים הבאים:</p>
          <ul>
            <li>האזור בו נמצא המבנה – יש בארץ אזורים מבוקשים יותר, אשר בהם ניתן לבקש דמי שכירות גבוהים יותר מאשר באזורים אחרים. כמובן שבאזורים אלה מחיר הרכישה של נכסים כאלה יהיה גבוה יותר.</li>
            <li>סוג מבנה התעשיה – מבנה תעשיה יכול להיות מחסן, מפעל או מרכז לוגיסטי, וכמובן שלסוג המבנה יש השפעה הן על מחיר הנכס והן על התשואה שניתן להפיק ממנו. חשוב מאוד לקחת את הפרמטר הזה בחשבון, כי לפעמים כדאי להשקיע יותר על מנת לקבל יותר.</li>
            <li>ייעוץ וליווי – רכישת מבנה תעשיה למכירה היא לא מהלך של מה בכך. לכן, חשוב לעשות אותו תוך קבלת ליווי צמוד מאנשים שמבינים.</li>
          </ul>
          <p>מניבים ישראל מציעה מגוון גדול של מבני תעשיה למכירה בכל חלקי הארץ, והיועצים שלנו ישמחו מאוד לסייע לכם לבחור את המבנה המתאים באופן מדויק להעדפות ולתקציב שלכם.</p>`
      },
      storage: {
        label: 'מחסנים',
        title: 'מחסנים למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מחסנים למכירה',
        description: 'אם אתם מחפשים מחסנים למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מחסנים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מחסן למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      plot: {
        label: 'מגרשים',
        title: 'מגרשים למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מגרשים למכירה',
        description: 'אם אתם מחפשים מגרשים למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מגרשים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מגרש למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: `<h2>מגרשים למכירה מוצאים במניבים</h2>
        <p>מחפשים מגרשים למכירה? אתם בדיוק במקום הנכון. מניבים מציעה לכם מבחר גדול של מגרשים למכירה ברחבי הארץ, בהם תוכלו לבנות נכסים מניבים שיספקו לכם הכנסה יפה למשך שנים.</p>
        <p>כך למשל תוכלו למצוא כאן מגרשים בראשון לציון, רמת גן, עפולה, נתניה, נצרת עילית, אשדוד, כפר יונה, שערי תקווה, טבריה, חיפה, פרדסיה, הרצליה, קריית אונו, באר שבע, בנימינה ועוד הרבה אחרים.</p>
        <h2>איך בוחרים מגרשים למכירה?</h2>
        <p>בחירת מגרש צריכה להיעשות בעיקר על פי התשואה שתוכלו להפיק ממנו, ותשואה זאת היא פונקציה של כמה וכמה פרמטרים חשובים:</p>
        <ul>
          <li><b>האזור</b> – מומלץ לבחור מגרשים למכירה הנמצאים באזורים מבוקשים, כאלה שאנשים יהיו מוכנים לשלם לא מעט כסף על מנת להחזיק בהם נכס.</li>
          <li><b>סוג הנכס</b> – גם סוג הנכס שייבנה על המגרש הוא פרמטר חשוב בקביעת התשואה. כך למשל, מגרש שעליו ייבנה בעתיד בית מלון – יניב, כנראה, תשואה הרבה יותר גבוהה ממגרש שעליו ייבנה בניין מגורים.</li>
          <li><b>גודל הנכס</b> – נכס גדול יותר הוא נכס שבעבורו ניתן לבקש דמי שכירות גבוהים יותר. כמובן שפרמטר זה חייב להילקח בחשבון ביחד עם שאר הפרמטרים, והשקלול של כולם הוא זה שיכריע בסופו של דבר את הכף.</li>
        </ul>
        <p>מניבים ישראל מציעה לכם מגוון גדול של מגרשים למכירה. אתם מוזמנים לשוטט באתר ולבחור את המגרש שמתאים ביותר להעדפות ולתקציב שלכם. מתלבטים? צרו עמנו קשר ונשמח לסייע לכם בבחירת מגרש למכירה שמתאים לכם.</p>`
      },
      agriculturalPlot: {
        label: 'מגרשים חקלאיים',
        title: 'מגרשים למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מגרשים חקלאיים למכירה',
        description: 'אם אתם מחפשים מגרשים למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מגרשים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מגרש למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: `<h2>מגרשים למכירה מוצאים במניבים</h2>
        <p>מחפשים מגרשים למכירה? אתם בדיוק במקום הנכון. מניבים מציעה לכם מבחר גדול של מגרשים למכירה ברחבי הארץ, בהם תוכלו לבנות נכסים מניבים שיספקו לכם הכנסה יפה למשך שנים.</p>
        <p>כך למשל תוכלו למצוא כאן מגרשים בראשון לציון, רמת גן, עפולה, נתניה, נצרת עילית, אשדוד, כפר יונה, שערי תקווה, טבריה, חיפה, פרדסיה, הרצליה, קריית אונו, באר שבע, בנימינה ועוד הרבה אחרים.</p>
        <h2>איך בוחרים מגרשים למכירה?</h2>
        <p>בחירת מגרש צריכה להיעשות בעיקר על פי התשואה שתוכלו להפיק ממנו, ותשואה זאת היא פונקציה של כמה וכמה פרמטרים חשובים:</p>
        <ul>
          <li><b>האזור</b> – מומלץ לבחור מגרשים למכירה הנמצאים באזורים מבוקשים, כאלה שאנשים יהיו מוכנים לשלם לא מעט כסף על מנת להחזיק בהם נכס.</li>
          <li><b>סוג הנכס</b> – גם סוג הנכס שייבנה על המגרש הוא פרמטר חשוב בקביעת התשואה. כך למשל, מגרש שעליו ייבנה בעתיד בית מלון – יניב, כנראה, תשואה הרבה יותר גבוהה ממגרש שעליו ייבנה בניין מגורים.</li>
          <li><b>גודל הנכס</b> – נכס גדול יותר הוא נכס שבעבורו ניתן לבקש דמי שכירות גבוהים יותר. כמובן שפרמטר זה חייב להילקח בחשבון ביחד עם שאר הפרמטרים, והשקלול של כולם הוא זה שיכריע בסופו של דבר את הכף.</li>
        </ul>
        <p>מניבים ישראל מציעה לכם מגוון גדול של מגרשים למכירה. אתם מוזמנים לשוטט באתר ולבחור את המגרש שמתאים ביותר להעדפות ולתקציב שלכם. מתלבטים? צרו עמנו קשר ונשמח לסייע לכם בבחירת מגרש למכירה שמתאים לכם.</p>`
      },
      constructionPlot: {
        label: 'מגרשים לבניה',
        title: 'מגרשים למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מגרשים לבניה למכירה',
        description: 'אם אתם מחפשים מגרשים למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מגרשים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מגרש למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: `<h2>מגרשים למכירה מוצאים במניבים</h2>
        <p>מחפשים מגרשים למכירה? אתם בדיוק במקום הנכון. מניבים מציעה לכם מבחר גדול של מגרשים למכירה ברחבי הארץ, בהם תוכלו לבנות נכסים מניבים שיספקו לכם הכנסה יפה למשך שנים.</p>
        <p>כך למשל תוכלו למצוא כאן מגרשים בראשון לציון, רמת גן, עפולה, נתניה, נצרת עילית, אשדוד, כפר יונה, שערי תקווה, טבריה, חיפה, פרדסיה, הרצליה, קריית אונו, באר שבע, בנימינה ועוד הרבה אחרים.</p>
        <h2>איך בוחרים מגרשים למכירה?</h2>
        <p>בחירת מגרש צריכה להיעשות בעיקר על פי התשואה שתוכלו להפיק ממנו, ותשואה זאת היא פונקציה של כמה וכמה פרמטרים חשובים:</p>
        <ul>
          <li><b>האזור</b> – מומלץ לבחור מגרשים למכירה הנמצאים באזורים מבוקשים, כאלה שאנשים יהיו מוכנים לשלם לא מעט כסף על מנת להחזיק בהם נכס.</li>
          <li><b>סוג הנכס</b> – גם סוג הנכס שייבנה על המגרש הוא פרמטר חשוב בקביעת התשואה. כך למשל, מגרש שעליו ייבנה בעתיד בית מלון – יניב, כנראה, תשואה הרבה יותר גבוהה ממגרש שעליו ייבנה בניין מגורים.</li>
          <li><b>גודל הנכס</b> – נכס גדול יותר הוא נכס שבעבורו ניתן לבקש דמי שכירות גבוהים יותר. כמובן שפרמטר זה חייב להילקח בחשבון ביחד עם שאר הפרמטרים, והשקלול של כולם הוא זה שיכריע בסופו של דבר את הכף.</li>
        </ul>
        <p>מניבים ישראל מציעה לכם מגוון גדול של מגרשים למכירה. אתם מוזמנים לשוטט באתר ולבחור את המגרש שמתאים ביותר להעדפות ולתקציב שלכם. מתלבטים? צרו עמנו קשר ונשמח לסייע לכם בבחירת מגרש למכירה שמתאים לכם.</p>`
      },
      clinics: {
        label: 'קליניקות',
        title: 'קליניקות למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'קליניקות למכירה',
        description: 'אם אתם מחפשים קליניקות למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מגרשים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותה קליניקה למכירה שמתאימה בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ``
      },
      businessesForSale: {
        label: 'עסקים',
        title: 'עסקים למכירה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'עסקים למכירה',
        description: 'אם אתם מחפשים עסקים למכירה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של עסקים למכירה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו עסק למכירה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ``
      },


    },
    forRentList: {
      store: {
        label: 'חנויות',
        title: 'חנויות להשכרה בכל חלקי הארץ. אלפי נכסים באתר! – מניבים ישראל נכסים ונדל"ן מניב.',
        h1: 'חנויות להשכרה',
        description: 'מחפשים חנויות להשכרה? הגעתם למקום הנכון. מניבים ישראל מציעה מגוון של חנויות להשכרה בכל חלקי הארץ, חנויות במיקומים מעולים! לפרטים לחצו >>>',
        content: `<h2>חנויות להשכרה – יופי של הכנסה נוספת</h2>
        <p>אם אתם מחפשים דרך טובה לקבל הכנסה נוספת בלי שתצטרכו לעבוד הרבה בשבילה, רכישת חנויות להשכרה היא התשובה האופטימלית עבורכם. מה זה בדיוק אומר ואיך תוכלו לראות את התוצאות הטובות ביותר? כל הפרטים – במאמר שלפניכם.</p>
        <h2>חנויות להשכרה כנכסים מניבים?</h2>
        <p>נכסים מניבים בהגדרתם הינם כל נכס אשר נרכש במטרה להניב כסף לבעליו. משמעות הדבר היא, שהנכס יכול להיות דירה, הוא יכול להיות משרד והוא יכול להיות חנות, כל עוד אתם רוכשים אותו ומשכירים אותו לאחרים. כמובן שאם אתם משכירים אותו אתם מקבלים דמי שכירות, ולכן – אם תבחרו אותו נכון – תוכלו לקבל מדי חודש סכום כסף יפה לחשבון הבנק שלכם.</p>
        <h2>אז למה דווקא חנות?</h2>
        <p>כי חנות היא בעצמה נכס שמניב למי שמשתמש בו לא מעט כסף. לכן, ניתן להשכיר אותו במחיר הרבה יותר גבוה מדירה באותו גודל, למשל, וכך בהשקעה לא גדולה – להשיג הכנסה נוספת יפה. כל מה שצריך לעשות הוא:</p>
        <ul>
          <li><b>לבחור חנות במיקום הנכון</b> – כי יש מקומות שבהם דמי השכירות הרבה יותר גבוהים ובהם כדאי לבחור.</li>
          <li><b>לבחור חנות גדולה יחסית ככל שהתקציב מרשה</b> – כי חנות גדולה יותר תוכלו להשכיר ביותר כסף.</li>
          <li><b>והכי חשוב</b> – להיוועץ באיש מקצוע מנוסה אשר ידע להמליץ לכם על החנויות הנכונות מתוך מאגר חנויות להשכרה שברשותו.</li>
        </ul>
        <p>מניבים ישראל מציעים מגוון גדול של חנויות להשכרה כנכסים מניבים. צרו עמנו קשר ונשמח לסייע לכם בבחירת החנות שתאפשר לכם ליהנות מדמי השכירות הגבוהים ביותר שאתם יכולים לקבל.</p>`
      },
      storage: {
        label: 'מחסנים',
        title: 'מחסנים להשכרה בכל חלקי הארץ. אלפי נכסים באתר! – מניבים ישראל נכסים ונדל"ן מניב.',
        h1: 'מחסנים להשכרה',
        description: 'מחפשים מחסנים להשכרה? הגעתם למקום הנכון. מניבים ישראל מציעה מגוון של מחסנים להשכרה בכל חלקי הארץ, מחסנים במיקומים מעולים! לפרטים לחצו >>>',
        content: `<h2>מחסנים להשכרה – תשואה טובה לאורך זמן</h2>
        <p>אם אתם מחפשים דרך להרוויח כסף מבלי שתצטרכו להשקיע הרבה זמן – מחסנים להשכרה היא הדרך האולטימטיבית עבורכם. המהלך הוא פשוט יחסית: אתם רוכשים מחסן להשכרה ומשכירים אותו למי שמחפש מחסן לתקופה ארוכה. בדרך זאת אתם מבטיחים לעצמכם הכנסה חודשית יפה, ומכיוון שמדובר במחסן ולא בנכס למגורים – גם לא תצטרכו לפנות עבור השירות הזה שאתם מספקים הרבה מאוד זמן.</p>
        <h2>איך בוחרים מחסנים להשכרה?</h2>
        <p>בחירת מחסנים להשכרה צריכה להיעשות על פי רשימת הפרמטרים הבאים:</p>
        <ul>
          <li><b>מיקום המחסן </b> – בישראל ישנם מקומות בהם דמי השכירות גבוהים בהרבה מאשר במקומות אחרים. לכן, אם אתם רוצים לרכוש מחסן ולהשכיר אותו, חשוב מאוד שתבצעו סקר שוק מקדים ותבחרו במחסן שנמצא במיקום שמאפשר לכם לרכוש אותו בתנאים האופטימליים ולהרוויח ממנו כמה שיותר.</li>
          <li><b>גודל המחסן</b> – כמובן שככל שהמחסן שתבחרו יהיה גדול יותר – תוכלו לבקש דמי שכירות גבוהים יותר. למרות זאת – לפני שאתם ממהרים לרכוש את המחסן הגדול ביותר שמוצע להשכרה, חשוב לשאול האם יהיה ביקוש למחסן בגודל כזה, או שאולי עדיף בכל זאת לרכוש מחסן יותר קטן.</li>
          <li><b>ליווי צמוד בבחירה</b> – לפני שמבצעים רכישה של מחסנים להשכרה, כדאי מאוד להיוועץ עם אנשי מקצוע מנוסים, אשר ידריכו אתכם ויגדילו את הסיכויים שתרכשו מחסן להשכרה שיניב את התשואה הגדולה ביותר האפשרית עבורכם.</li>
        </ul>
        <p>מניבים ישראל מציעה מגוון מחסנים להשכרה ביניהם תוכלו למצוא את המחסן שעונה בצורה מדויקת על הדרישות, ההעדפות והיכולות התקציביות שלכם.</p>`
      },
      office: {
        label: 'משרדים',
        title: 'משרדים להשכרה בכל חלקי הארץ. אלפי נכסים באתר! – מניבים ישראל נכסים ונדל"ן מניב.',
        h1: 'משרדים להשכרה',
        description: 'מחפשים משרדים להשכרה? הגעתם למקום הנכון. מניבים ישראל מציעה מגוון של משרדים להשכרה בכל חלקי הארץ, משרדים מניבים עם תשואה מעולה! לפרטים לחצו >>>',
        content: `<h2>משרדים להשכרה – נכסים שמניבים לכם יותר</h2>
        <p>אם אתם מחפשים בנרות דרך שתאפשר לכם להרוויח כסף מבלי שתצטרכו לעשות יותר מדיי, התחילו לחפש משרדים להשכרה. לא, אנחנו לא מתכוונים לכך שתשכרו משרד ותתחילו לעבוד בו, אלא שתמשיכו לעבוד במקום שבו אתם עובדים עכשיו (בהנחה שטוב לכם), ותרכשו משרד שאותו תוכלו להשכיר לאחרים. אם תבחרו משרד להשכרה על פי הפרמטרים הנכונים – תוכל להיות לכם תוספת יפה להכנסה השנתית.</p>
        <h2>איך זה עובד?</h2>
        <p>פשוט מאוד. מניבים ישראל מציעה מגוון רחב של משרדים להשכרה כנכסים מניבים. כל מה שאתם צריכים לעשות הוא לפעול על פי הפרמטרים שמייד נציין כאן, לרכוש משרד שמתאים ליכולות שלכם ושעל פי כל הבדיקות אתם יכולים להשכיר אותו במחיר יפה לאחרים, וכל מה שתצטרכו לעשות זה פשוט להמשיך בחייכם (ולפעמים לדאוג לבצע תיקונים בנכס, אם צריך), ולראות מדי חודש את הכסף שנכנס לחשבון הבנק שלכם.</p>
        <h2>אז איך בוחרים משרדים להשכרה?</h2>
        <p>משרד להשכרה בוחרים על פי שלושה פרמטרים חשובים:</p>
        <ul>
          <li><b>מיקום </b> – מומלץ מאוד לבחור משרד הממוקם באזור שבו מחירי ההשכרה גבוהים</li>
          <li><b>גודל</b> – ככל שהמשרד גדול יותר, כך תוכלו להשכיר אותו בסכום כספי גבוה יותר.</li>
          <li><b>ליווי צמוד בבחירה</b> – לפני שבוחרים משרד כדאי מאוד לקבל ייעוץ מאיש מקצוע מנוסה, אשר יסייע לכם לבחור את המשרד המתאים.</li>
        </ul>
        <p>מניבים ישראל עומדת לשירותכם ומציעה לכם ליווי צמוד לאורך כל הדרך ומאגר גדול של משרדים להשכרה, כך שבסוף הדרך תוכלו ליהנות מהכנסה יפה.</p>`
      },
      industry: {
        label: 'מבני תעשיה',
        title: 'מבני תעשיה להשכרה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מבני תעשיה להשכרה',
        description: 'אם אתם מחפשים מבנה תעשיה להשכרה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מבני תעשיה להשכרה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מבנה תעשיה להשכרה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      commerce: {
        label: 'מסחרי',
        title: 'נדלן מסחרי להשכרה זה מניבים ישראל (כרגע באתר למעלה מ 300 נכסים).',
        h1: 'נדל"ן מסחרי להשכרה',
        description: 'נדלן מסחרי להשכרה מחפשים ומוצאים במניבים ישראל. מבחר גדול ביותר של נכסים מניבים, לרבות נדלן מסחרי להשכרה שיוכל להניב לכם יותר. היכנסו עכשיו למנוע החיפוש באתר ותוכלו למצוא בתוך שניות את אותם נכסים מסחריים מניבים שמתאימים בדיוק לצרכים ולהעדפות האישיים שלכם.',
        content: ''
      },
      parking: {
        label: 'חניונים',
        title: 'חניונים להשכרה בכל הארץ מוצאים במניבים ישראל (אינדקס מתעדכן)',
        h1: 'חניונים להשכרה',
        description: 'כשאתם מחפשים חניונים להשכרה אתם צריכים אינדקס מעודכן בו תוכלו למצוא את הפרטים אודות חניונים להשכרה שמתאימים לצרכים ולהעדפות שלכם. מניבים ישראל הוא אינדקס מתעדכן בו תוכלו למצוא מידע מפורט אודות כל חניון להשכרה שמופיע באתר, וכך לאתר את החניונים הרלוונטיים בקלות ובמהירות. היכנסו עכשיו ומצאו את החניון הבא שלכם >>>',
        content: ''
      },
      plot: {
        label: 'מגרשים',
        title: 'מגרשים להשכרה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'מגרשים להשכרה',
        description: 'אם אתם מחפשים מגרשים להשכרה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של מגרשים להשכרה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את אותו מגרש להשכרה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      workSpace: {
        label: 'חלל עבודה',
        title: 'חללי עבודה משותפים להשכרה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'חללי עבודה משותפים להשכרה',
        description: 'אם אתם מחפשים חללי עבודה משותפים להשכרה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של חללי עבודה משותפים להשכרה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר את חלל העבודה להשכרה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      hall: {
        label: 'אולמות',
        title: 'אולמות להשכרה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'אולמות להשכרה',
        description: 'אם אתם מחפשים אולמות להשכרה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של אולמות להשכרה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר אולם להשכרה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      clinics: {
        label: 'קליניקות',
        title: 'קליניקות להשכרה בכל חלקי הארץ עכשיו במניבים ישראל (2024)',
        h1: 'קליניקות להשכרה',
        description: 'אם אתם מחפשים קליניקות להשכרה אתם בדיוק במקום הנכון. מניבים ישראל מביא לכם רשימה מעודכנת של קליניקות להשכרה בכל חלקי הארץ, ובאמצעות חיפוש פשוט במנוע החיפוש הפנימי תוכלו לאתר קליניקה להשכרה שמתאים בדיוק לצרכים ולהעדפות שלכם. היכנסו עכשיו לאתר וצפו בנכסים שמתאימים לכם >>>',
        content: ''
      },
      gasStation: {
        label: 'תחנות דלק',
        title: 'תחנות דלק להשכרה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'תחנות דלק להשכרה',
        description: 'מחפשים תחנות דלק להשכרה? אתם בדיוק במקום הנכון. מניבים ישראל הוא אינדקס נכסים בכל חלקי הארץ לרבות תחנות דלק להשכרה, המביא לכם את הנכסים המעודכנים ביותר ומאפשר לכם למצוא את התחנת דלק שתתאים באופן מדויק לצרכים ולהעדפות שלכם. היכנסו עכשיו אל מניבים ישראל בקטגוריית תחנות דלק להשכרה ותוכלו להתרשם באופן מיידי מההיצע >>>',
        content: ''
      },
      vacation: {
        label: 'בתי מלון',
        title: 'בתי מלון להשכרה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'בתי מלון להשכרה',
        description: 'מחפשים בתי מלון להשכרה אשר ישיאו לכם תשואה יפה? אתם בדיוק במקום הנכון. מניבים ישראל מציעה מגוון של בתי מלון להשכרה בכל חלקי הארץ, אשר יאפשרו לכם ליהנות מהכנסה נוספת יפה. פרטים נוספים באתר >>>',
        content: ''
      },
      general: {
        label: 'מבנים כלליים',
        title: 'מבנים כלליים להשכרה בכל חלקי הארץ זה מניבים ישראל (מעודכן 2024)',
        h1: 'מבנים כלליים להשכרה',
        description: '',
        content: ''
      },
    },
    // cityCategorySideNav: {
    //   'tel-aviv': 'תל אביב',
    //   'jerusalem': 'ירושלים',
    //   'haifa': 'חיפה',
    //   'petah-tikva': 'פתח תקווה',
    //   'beer-sheva': 'באר שבע',
    //   'ashdod': 'אשדוד',
    //   'ramat-gan': 'רמת גן',
    //   'bnei-brak': 'בני ברק',
    //   'bet-shemesh': 'בית שמש',
    //   'bat-yam': 'בת ים',
    //   'ashkelon': 'אשקלון',
    //   'tzfat': 'צפת',
    // },
    districtCategorySideNav: {
      'northern-district':'צפון',
      'tel-aviv-district':'אזור תל אביב',
      'central-district':'מרכז',
      'southern-district':'דרום',
      'haifa-district':'אזור חיפה',
      'jerusalem-district':'אזור ירושלים',
    },
  
    cityCategoryTopNav: {
      'jerusalem': 'ירושלים',
      'tel-aviv': 'תל אביב',
      'haifa': 'חיפה',
      'rishon-lezion': 'ראשון לציון',
      'petah-tikva': 'פתח תקווה',
      'netanya': 'נתניה',
      'ashdod': 'אשדוד',
      'bnei-brak': 'בני ברק',
      'beer-sheva': 'באר שבע',
      'holon': 'חולון',
      'ramat-gan': 'רמת גן',
      'ashkelon': 'אשקלון',
      'rehovot': 'רחובות',
      'bet-shemesh': 'בית שמש',
      'bat-yam': 'בת ים',
      'herzliya': 'הרצליה',
      'kfar-saba': 'כפר סבא',
      'hadera': 'חדרה',
      'modiin-maccabim-reut': 'מודיעין מכבים רעות',
      'lod': 'לוד',
      'modiin-ilit': 'מודיעין עילית',
      'raanana': 'רעננה',
      'nazareth': 'נצרת',
      'ramla': 'רמלה',
      'rosh-haayin': 'ראש העין',
      'hod-hasharon': 'הוד השרון',
      'betar-illit': 'ביתר עילית',
      'nahariyya': 'נהריה',
      'givatayim': 'גבעתיים',
      'kiryat-gat': 'קרית גת',
      'kiryat-ata': 'קרית אתא',
      'afula': 'עפולה',
      'yavne': 'יבנה',
      'eilat': 'אילת',
      'ness-ziona': 'נס ציונה',
      'akko': 'עכו',
      'elad': 'אלעד',
      'ramat-hasharon': 'רמת השרון',
      'tiberias': 'טבריה',
      'kiryat-motzkin': 'קרית מוצקין',
      'karmiel': 'כרמיאל',
      'kiryat-bialik': 'קריית ביאליק',
      'nof-hagalil': 'נוף הגליל',
      'netivot': 'נתיבות',
      'kiryat-ono': 'קרית אונו',
      'kiryat-yam': 'קרית ים',
      'maale-adumim': 'מעלה אדומים',
      'tzfat': 'צפת'
    },

    cityCategorySideNav: {
      'jerusalem': 'ירושלים',
      'tel-aviv': 'תל אביב',
      'haifa': 'חיפה',
      'rishon-lezion': 'ראשון לציון',
      'petah-tikva': 'פתח תקווה',
      'netanya': 'נתניה',
      'ashdod': 'אשדוד',
      'bnei-brak': 'בני ברק',
      'beer-sheva': 'באר שבע',
      'holon': 'חולון',
      'ramat-gan': 'רמת גן',
      'ashkelon': 'אשקלון',
      'rehovot': 'רחובות',
      'bet-shemesh': 'בית שמש',
      'bat-yam': 'בת ים',
      'herzliya': 'הרצליה',
      'kfar-saba': 'כפר סבא',
      'hadera': 'חדרה',
      'modiin-maccabim-reut': 'מודיעין מכבים רעות',
      'lod': 'לוד',
      'modiin-ilit': 'מודיעין עילית',
      'raanana': 'רעננה',
      'nazareth': 'נצרת',
      'ramla': 'רמלה',
      'rosh-haayin': 'ראש העין',
      'hod-hasharon': 'הוד השרון',
      'betar-illit': 'ביתר עילית',
      'nahariyya': 'נהריה',
      'givatayim': 'גבעתיים',
      'kiryat-gat': 'קרית גת',
      'kiryat-ata': 'קרית אתא',
      'afula': 'עפולה',
      'yavne': 'יבנה',
      'eilat': 'אילת',
      'ness-ziona': 'נס ציונה',
      'akko': 'עכו',
      'elad': 'אלעד',
      'ramat-hasharon': 'רמת השרון',
      'tiberias': 'טבריה',
      'kiryat-motzkin': 'קרית מוצקין',
      'karmiel': 'כרמיאל',
      'kiryat-bialik': 'קריית ביאליק',
      'nof-hagalil': 'נוף הגליל',
      'netivot': 'נתיבות',
      'kiryat-ono': 'קרית אונו',
      'kiryat-yam': 'קרית ים',
      'maale-adumim': 'מעלה אדומים',
      'tzfat': 'צפת',
      'or-yehuda': 'אור יהודה',
      'dimona': 'דימונה',
      'ofakim': 'אופקים',
      'sderot': 'שדרות',
      'beer-yaakov': 'באר יעקב',
      'harish': 'חריש',
      'givat-shmuel': 'גבעת שמואל',
      'kfar-yona': 'כפר יונה',
      'arad': 'ערד',
      'tirat-carmel': 'טירת כרמל',
      'migdal-haemek': 'מגדל העמק',
      'kiryat-malakhi': 'קרית מלאכי',
      'yokneam-illit': 'יוקנעם עילית',
      'nesher': 'נשר',
      'qiryat-shemona': 'קרית שמונה',
      'maalot-tarshiha': 'מעלות תרשיחא',
      'or-akiva': 'אור עקיבא',
      'ariel': 'אריאל',
      'beit-shean': 'בית שאן',
      'zichron-yaakov': 'זכרון יעקב',
      'hazor-haglilit': 'חצור הגלילית',
      'Pardes-Hanna-Karkur': 'פרדס חנה כרכור',
      'rosh-pina': 'ראש פינה'
    },
  };
}

const categoryServiceModule = angular.module('categoryService', [])
  .service('categoryService', categoryService)
  .name;

export default categoryServiceModule;
