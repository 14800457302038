import _ from 'lodash';
import isUrl from 'is-url';

export default function ($rootScope, propertyService, planService, paymentsService, utils, $state, $timeout, eventsService) {
  let vm;

  class PlanChooserController {
    constructor () {
      vm = this;
    }

    $onInit () {
      // if ($state.params.new) {
        // vm.pop = true
      // }
    }

    ClosePop () {
      vm.pop = false
    }

    // loadCards () {
    //   return planService.getOrgCards()
    //     .then((res) => {
    //       if (res.length > 0) {
    //         vm.IScard = true;
    //         vm.Premium = "plan-monthly";
    //       } else { vm.IScard = false; if (!$rootScope.org.block) { vm.Premium = "plan-vip" } }
    //     });
    // }

    SendEmailJoinTrack (planDescription) {
      let body = { orgID: $rootScope.org.id, planDescription: planDescription }
      return planService.SendEmailJoinTrack(body)
    }

    async setPlan (planName) {
      if (!planName && vm.isNotSelected) {
        vm.SendEmailJoinTrack(null);
        this.showMessageFreePlan();
        return;
      }

      if (!planName && !await vm.confirmCancelPlan()) {
        return;
      }
      const result = await planService.setPlan($rootScope.org.id, { name: planName });

      if (_.get(result, 'status') !== 'success') {
        return vm.alertError(_.get(result, 'data'));
      }

      const cards = await paymentsService.getOrgCards();
      cards.status === 'error' && console.error(cards.data);
      const hasValidCard = cards.find((card) => card.active);
      if (!planName) {
        vm.SendEmailJoinTrack(null);
        vm.alertSuccess('מסלול פרימיום בוטל');
      } else if (hasValidCard) {
        vm.SendEmailJoinTrack(result.data.planDescription);
        vm.alertSuccess('המסלול עודכן בהצלחה!');
      }
      //  else 
      // if (planName === 'perProperty') {
      //   vm.alertNoCard();
      // } 
      else {
        const transactionDetails = {
          monthlyPlan: true,
          description: `עבור פרסום במניבים-לוח נדל"ן מסחרי : ${result.data.planDescription}`,
          price: result.data.planPrice
        };
        let returnTo = '/dashboard/payments/plans';
        if ($state.current.name === 'choosePlan') {
          returnTo = '/dashboard/my-properties';
        }
        paymentsService.getOrgChargeUrl(transactionDetails, returnTo)
          .then((res) => {
            if (isUrl(res)) {
              $timeout(() => {
                if (result) {
                }
                window.open(res, '_self');
              });
            }
          });
      }
    }

    // alertNoCard () {
    //   const modalParams = {
    //     windowClass: 'hmodal-danger',
    //     title: 'לא הוגדר אמצעי תשלום',
    //     message: 'האם ברצונך להגדיר עכשיו אמצעי תשלום?',
    //     buttons: [{
    //       label: 'בטל',
    //       btnClass: 'btn-default',
    //       onClick ($uibModalInstance) {
    //         $uibModalInstance.dismiss('cancel');
    //       }
    //     },
    //     {
    //       label: 'כן, קח אותי',
    //       btnClass: 'btn-primary',
    //       onClick ($uibModalInstance) {
    //         let returnTo = '/dashboard/payments/plans';
    //         if ($state.current.name === 'choosePlan') {
    //           returnTo = '/dashboard/my-properties';
    //         }
    //         paymentsService.getOrgAddCardUrl($rootScope.org.id, returnTo)
    //           .then((res) => {
    //             if (isUrl(res)) {
    //               $timeout(() => {
    //                 window.open(res, '_self');
    //               });
    //             }
    //           });
    //       }
    //     }
    //     ]
    //   };
    //   utils.modalAlert(modalParams);
    // }

    alertError (message) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'שגיאה',
        message: 'message',
        buttons: [{
          label: 'cool!',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        }]
      };
      utils.modalAlert(modalParams);
    }

    alertSuccess (message) {
      const modalParams = {
        windowClass: 'hmodal-info',
        title: message,
        buttons: [{
          label: 'אישור',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        }]
      };
      utils.modalAlert(modalParams);
    }

    confirmCancelPlan () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם לבטל את מסלול החיוב?',
        message: 'מודעות שפורסמו לא יחודשו לאחר תום תקופת הפרסום',
        buttons: [{
          label: 'לא',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'ביטול מסלול',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            return $uibModalInstance.close();
          }
        }]
      };
      return utils.modalAlert(modalParams);
    }

    showMessageFreePlan () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'הצטרפת למסלול נסיון חינם בהצלחה!',
        message: 'הנכס יפורסם בהקדם לאחר בדיקה ואישור של נציגי השירות.',
        buttons: [
          {
            label: 'אישור',
            btnClass: 'btn-primary',
            onClick ($uibModalInstance) {
              $uibModalInstance.close();

              $state.transitionTo('myProperties');
            }
          }]
      };
      return utils.modalAlert(modalParams);
    }

    getinfoMenivimKidum () {
      const modalParams = {
        windowClass: 'hmodal-danger get-info-menivim-kidum',
        title: 'מניבים - קידום דיגיטל',
        message: `נציגי הקידום של מניבים יקדמו את הנכס שלך בגוגל ופייסבוק כדי להגדיל את רמת החשיפה של הנכס, ולהגביר את כמות הלידים,
        הנכסים מקודמים לפי הפרמטרים החכמים של פילוח הקהלים של מניבים
        לחצו ונציגי שירות הלקוחות יחזרו אליכם בהקדם
        `,
        buttons: [
          {
            label: 'אני רוצה לשמוע פרטים',
            btnClass: 'btn-primary',
            onClick ($uibModalInstance) {
              $uibModalInstance.close();

              eventsService.create('getinfoMenivimKidum', 'PlanChooser');
            }
          }]
      };
      return utils.modalAlert(modalParams);
    }
  }

  return new PlanChooserController();
}
