import angular from 'angular';
import _ from 'lodash';

import template from './form-lead.html';
import './form-lead.scss';

const formLeadModule = angular.module('formLead', [])
  .directive('formLead', [function () {
    return {
      restrict: 'EA',
      controllerAs: 'vm',
      bindToController: true,
      replace: true,
      scope: {
        type: '@',
        typeId: '@'
      },
      template,
      controller: ['$scope', 'sweetAlert', 'eventsService', function ($scope, sweetAlert, eventsService) {
        const vm = this;

        vm.data = {
          name: '',
          phone: ''
        };

        vm.submit = () => {
          if (vm.data.name === '') {
            alert('שדה שם מלא חובה');
            return;
          }
          if (vm.data.phone === '') {
            alert('שדה טלפון חובה');
            return;
          }

          eventsService.create('projectSendMessageLead', vm.typeId, vm.data);
         
          window.dataLayer.push({ 'event': 'submit_form' });

          sweetAlert.swal('', 'ההודעה נשלחה למפרסם בהצלחה', 'success', {
            buttons: false,
            timer: 3000
          });
        };
      }]

    };
  }])
  .name;

export default formLeadModule;
