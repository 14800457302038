import template from './manage-plan.html';
import controller from './manage-plan.controller';
import './manage-plan.scss';

const managePlanComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['planService', 'paymentsService', '$timeout', 'manageService', 'profileService'];

export default managePlanComponent;
