import template from './my-properties.html';
import controller from './my-properties.controller';
import './my-properties.scss';

const myPropertiesComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout','savedPropertiesService', 'propertyService', 'utils', 'paymentsService', '$uibModal', 'profileService'];

export default myPropertiesComponent;
