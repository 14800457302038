import template from './saved-properties.html';
import controller from './saved-properties.controller';
import './saved-properties.scss';

const savedPropertiesComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$uibModal', 'savedPropertiesService', 'utils'];

export default savedPropertiesComponent;
