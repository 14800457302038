// import _ from 'lodash';
// import isUrl from 'is-url';

import options from '../../../common/options';

export default function ($scope, $rootScope, $state, $stateParams, $timeout, bannerService, userService, utils) {
  let vm;

  class BannerEditController {
    constructor () {
      vm = this;

      vm.modelObject = {
        name: '',
        status: 'draft',
        urlLink: ''
      };
      vm.bannerStatusOptions = options.bannerStatusOptions;
    }

    $onInit () {
      if ($stateParams.id) {
        bannerService.getBanner($stateParams.id)
          .then((banner) => {
            vm.modelObject = banner;
          });
      }

      $scope.$watchGroup(['vm.modelObject.files', 'vm.filesToUpload'], () => {
        if (vm.modelObject.status !== 'published') {
          return;
        }
        if (!vm.filesToUpload.length &&
          !(vm.modelObject.files || []).some((file) => !file.deleted)) {
          $timeout(() => {
            $scope.$apply(() => {
              vm.modelObject.status = 'draft';
            });
          });
        }
      });
    }

    saveBanner () {
      if (vm.saveProgress) {
        return;
      }

      utils.setFormFieldsDirty(vm.bannerEditForm);
      if (vm.bannerEditForm.$invalid) {
        document.getElementById('banner-edit-form').reportValidity();
        return;
      }

      vm.saveProgress = true;
      const deleteFileList = (vm.modelObject.files || []).filter((file) => file.deleted);
      const query = {
        banner: JSON.stringify(vm.modelObject),
        filesToUpload: vm.filesToUpload,
        filesToDelete: JSON.stringify(deleteFileList)
      };

      return bannerService.saveBanner(vm.modelObject.id, query)
        .then((response) => {
          $rootScope.$broadcast('bannerSaved');
          $state.go('manageBanners', { status: 'active' });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    statusOptionsDisabled (status) {
      if (status === 'published' &&
        !vm.filesToUpload.length &&
        !(vm.modelObject.files || []).some((file) => !file.deleted)) {
        return true;
      }
      return false;
    }
  }

  return new BannerEditController();
}
