import template from './property-edit.html';
import controller from './property-edit.controller';
import './property-edit.scss';

const propertyEditComponent = {
  bindings: {
    property: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', 'propertyService', 'projectService', 'userService', 'utils', 'profileService', '$uibModal'];

export default propertyEditComponent;
