import template from './header.html';
import controller from './header.controller';
import './header.scss';

const headerComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$uibModal', 'mailingListService' ,'messagesService', 'categoryService'];

export default headerComponent;
