import template from './messages.html';
import controller from './messages.controller';
import './messages.scss';

const messagesComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$location', '$timeout', 'messagesService', 'utils', '$uibModal'];

export default messagesComponent;
