import _ from 'lodash';

export default function ($scope, $rootScope, $state, $timeout, sweetAlert, eventsService, propertyService, projectService, manageService, utils, $uibModal, $stateParams) {
  let vm;

  class LeadsMainController {
    constructor () {
      vm = this;

      vm.paging = {
        orderBy: 'publishedAt',
        orderDesc: true,
        limit: 50,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.modelObject = {
        property: _.get($stateParams, 'propertyId') || '',
        interest: _.get($stateParams, 'interest') || '',
        userType: _.get($stateParams, 'userType') || '',
        limit: vm.paging.limit,
        offset: vm.paging.offset
      };

      vm.properties = [];

      vm.interests = [
        {
          key: 'Show',
          name: 'צפה'
        },
        {
          key: 'SendMessageForm',
          name: 'שלח הודעה'
        },
        {
          key: 'Phone',
          name: 'התקשר'
        }
      ];

      vm.usersTypes = [
        {
          key: 'lender',
          name: 'משקיעים'
        },
        {
          key: 'buyer',
          name: 'רוכשים'
        },
        {
          key: 'broker',
          name: 'מתווכים'
        }
      ];
      vm.users = [];
      vm.count = 0;
      vm.lead = {};

      vm.defaultProfileImage = 'assets/images/user.png';

      vm.SERVER_URL = window.SERVER_URL;
    }

    $onInit () {
      vm.getMyProperties();

      vm.getLeadsUsers();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.users.length;
      vm.paging.count = vm.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.modelObject.limit = vm.paging.limit;
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.modelObject.offset = vm.paging.offset;
      vm.getLeadsUsers();
    }

    // on change filter
    changeFilter () {
      vm.getLeadsUsers();
    }

    getMyProperties () {
      const isAdmin = $rootScope.isAdmin();
      vm.properties = [];
      if ($rootScope.isAdmin()) {
        manageService.getManageProjects('', '', '')
          .then((res) => {
            if (res.rows) {
              res.rows.forEach((project) => {
                project.title = `-- ${project.entrepreneur} --`;
              });
              vm.properties.push(...res.rows);
            }
          });
      } else {
        projectService.getMyProjects()
          .then((res) => {
            if (res.rows) {
              res.rows.forEach((project) => {
                project.title = `-- ${project.entrepreneur} --`;
              });
              vm.properties.push(...res.rows);
            }
          });
      }

      propertyService.getMyProperties(isAdmin)
        .then((list) => {
          if (list.rows) {
            vm.properties.push(...list.rows);
          }
        });
    }

    getLeadsUsers () {
      eventsService.getLeadsUsers({ filter: vm.modelObject })
        .then((list) => {
          if (list.users) {
            vm.users = list.users;
            vm.count = list.count;
            vm.setPagingFlags();
          } else {
            vm.users = 0;
            vm.count = 0;
          }
        });
    }

    showLeadUser (lead) {
      // if is current user
      if (lead['user.id'] === _.get(vm.lead, 'user.id')) {
        vm.lead = {};
        return;
      }

      // reset events
      vm.lead.events = [];

      eventsService.getUserLeads(lead['user.id'])
        .then((leadInfo) => {
          if (vm.lead && leadInfo.events) {
            vm.lead.events = leadInfo.events;
          }
        });
      vm.lead = lead;
    }

    closeLead () {
      vm.lead = {};
    }

    alertUpgrade (type = 'message') {
      const modalParams = {
        windowClass: 'hmodal-info',
        title: `${type === 'message' ? 'שליחת ההודעות' : 'צפייה בפרטי הליד'} פעילה למנויי פרימיום VIP בלבד`,
        buttons: [{
          label: 'ביטול',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        }, {
          label: 'הצטרף כעת למסלול פרימיום VIP',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');

            $state.transitionTo('payments.plans');
          }
        }]
      };
      utils.modalAlert(modalParams);
    }

    async SendMessage (lead) {
      // if (!$rootScope.org.planName || $rootScope.org.planName !== 'monthlyPlus') {
      // if (!$rootScope.org.planName || $rootScope.org.planName !== 'vip') {
      //   vm.alertUpgrade();
      //   return;
      // }
      $uibModal.open({
        component: 'leadSendMessage',
        resolve: {
          userLead: () => lead
        },
        windowClass: 'lead-send-message-modal',
        size: 'md'
      });
    }

    getIconEvent (type) {
      let eventIcon = '';
      switch (type) {
        case 'propertyShowPhone':
        case 'projectShowPhone':
          eventIcon = 'ion-android-call';
          break;
        case 'propertySendMessageForm':
        case 'projectSendMessageForm':
        case 'projectSendMessage':
        case 'projectSendMessageLead':
          eventIcon = 'ion-chatbubbles';
          break;
        case 'propertyShow':
        case 'projectShow':
          eventIcon = 'ion-eye';
          break;
      }

      return eventIcon;
    }

    getActionEvent (type) {
      let eventText = '';
      switch (type) {
        case 'propertyShowPhone':
        case 'projectShowPhone':
          eventText = 'התקשר';
          break;
        case 'propertySendMessageForm':
        case 'projectSendMessageForm':
          eventText = 'יצר קשר';
          break;
        case 'propertyShow':
        case 'projectShow':
          eventText = 'צפה בדף';
          break;
      }

      return eventText;
    }
  }

  return new LeadsMainController();
}
