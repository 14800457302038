import angular from 'angular';
import ManageMailingList from './manage-mailing-list/manage-mailing-list';
import ManageProperties from './manage-properties/manage-properties';
import manageProjects from './manage-projects/manage-projects';
import ManageUserList from './manage-user-list/manage-user-list';
import ManageProjectsLeads from './manage-projects-leads/manage-projects-leads';
import ManagePlugins from './manage-plugins/manage-plugins';
import ManageService from './manage.service';
import ManagePlan from './manage-plan/manage-plan';
import ManagePlugin from './manage-plugin/manage-plugin';
import manageBanners from './manage-banners/manage-banners';
import bannerEdit from './banner-edit/banner-edit';
import ManageAddress from './manage-address/manage-address';

const manageModule = angular.module('manage', [
  ManageMailingList,
  ManageProperties,
  manageProjects,
  ManageUserList,
  ManagePlugins,
  ManageService,
  ManagePlan,
  ManagePlugin,
  ManageProjectsLeads,
  manageBanners,
  bannerEdit,
  ManageAddress
])
  .name;

export default manageModule;
