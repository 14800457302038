import template from './terms.html';
import controller from './terms.controller';
import './terms.scss';

const termsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils'];

export default termsComponent;
