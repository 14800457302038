const defaultTitle = 'מניבים - לוח הנדל"ן המסחרי המוביל בישראל';
const defaultDescription = 'נכסים מניבים מוצאים במניבים ישראל! באתר שלנו תמצאו אלפי נכסים מניבים, לרבות משרדים, קניונים, חניונים, דירות מניבות, בניינים ועוד ותוכלו לאתר בקלות את אלה שמתאימים לצרכים האישיים שלכם. המקום היחיד שמרכז מגוון רחב של נכסים מניבים ומאפשר לכם למצוא את כל מה שאתם מחפשים. אז היכנסו עכשיו ותוכלו להפסיק לחפש >>>';

function pageService () {
  return function (title = defaultTitle, description = defaultDescription, canonicalTag = '') {
    document.title = title;
    $('[property="og:title"]').attr('content', title);
    $('[name="twitter:title"]').attr('content', title);
    $('[name="description"]').attr('content', description);
    $('[name="twitter:description"]').attr('content', description);
    $('[property="og:description"]').attr('content', description);
    
    $('[rel="canonical"]').remove();
    if(canonicalTag != ''){
        let link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        link.setAttribute('href', canonicalTag); 
    }

    return title;
  };
}

// pageService.$inject = [];

const pageServiceModule = angular.module('pageService', [])
  // .run(['$transitions', '$window', '$location', function ($transitions, $window, $location) {
  //   $transitions.onStart({}, function (trans) {
  //     trans.promise.finally(() => {
  //       $window.dataLayer.push({ event: 'pageView', pageUrl: $location.$$url });
  //     });
  //   });
  // }])
  .factory('pageService', pageService)
  .name;

export default pageServiceModule;
