import template from './project-edit.html';
import controller from './project-edit.controller';
import './project-edit.scss';

const projectEditComponent = {
  bindings: {
    project: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'projectService', 'userService', 'utils', 'orgService'];

export default projectEditComponent;
