import _ from 'lodash';
import options from '../../../common/options';
import XLSX from 'xlsx';

export default function ($scope, $rootScope, $state, $timeout, savedPropertiesService, propertyService, utils, paymentsService, $uibModal, profileService) {
  let vm;

  class MyPropertiesController {
    constructor () {
      vm = this;
      vm.propertyService = propertyService;
      vm.paymentsService = paymentsService;
      vm.orderBy = '-publishedAt';
      vm.orderByOptions = [
        { name: '-publishedAt', label: 'עודכן לאחרונה' },
        { name: 'price', label: 'מחיר (מהנמוך לגבוה)' },
        { name: '-price', label: 'מחיר (מהגבוה לנמוך)' }
      ];
      vm.savedProperties;
      vm.properties = [];
      vm.allProperties = [];
      vm.typeOptions = options.propertyTypeOptions;
      vm.statusOptions = options.propertyStatusOptions;
      vm.statusFilter = 'publish';
      vm.statusFilterOptions = [
        { value: '', label: 'הכל' },
        { value: '!canceled', label: 'הכל ללא מבוטלים' },
        { value: 'draft', label: 'טיוטות' },
        { value: 'pending', label: 'ממתינים לאישור' },
        { value: 'publish', label: 'פורסמו' },
        { value: 'paused', label: 'מושהים' },
        { value: 'closed', label: 'נמכרו' },
        { value: 'canceled', label: 'מבוטלים' },
        { value: 'expired', label: 'פג תוקף תשלום' }
      ];
    }

    $onInit () {
      vm.getMyProperties();
      savedPropertiesService.getSavedProperties({})
        .then((res) => {
          vm.savedPropertie = res.count;
        })
      // if ($rootScope.user.permission !== "user") {
      profileService.getEmployeesList($rootScope.org.id)
        .then((employees) => {
          if (employees.length > 1) {
            vm.users = employees
          }
        });
      // }
    }

    getMyProperties () {
      vm.propertyService.getMyProperties()
        .then((list) => {
          vm.list = list;
          vm.rows = vm.list.rows
          vm.allProperties = list.rows;
          vm.statusNum();
          vm.properties = list.rows.filter(i => i.status === "publish")
          // if ($state.params.new && $state.params.card === "no") {
          //   vm.pop = true;
          //   vm.plan = 'null'
          // }
          // if ($scope.$root.org.planName === "Basic" && $state.params.new && $state.params.card === "yes" && vm.properties.length > 5) {
          //   vm.pop = true;
          //   vm.popText = ""
          //   vm.plan = 'basic'
          // }
        });
    }

    exportToExcel () {
      vm.exportTable = vm.rows;
      setTimeout(function () {
        let data = document.getElementById('exportTable');
        let Properties = XLSX.utils.table_to_sheet(data);
        let wb = {
          Workbook: {
            Views: [
              { RTL: true }
            ]
          },
          Sheets: { Properties },
          SheetNames: ["Properties"]
        }
        XLSX.utils.book_append_sheet(wb);
        XLSX.writeFile(wb, "מניבים הנכסים שלי.xlsx");
      }, 1500);
    }

    ClosePop () {
      vm.pop = false
    }

    goToChoosePlan () {
      $state.go('choosePlan');
    }

    doOrder (value) {
      if (vm.orderBy === value) {
        if (value[0] === '-') {
          vm.orderBy = value.substr(1);
        } else {
          vm.orderBy = '-' + value;
        }
      } else {
        vm.orderBy = value;
      }
    }

    copy (p) {
      let pObject = structuredClone(p);
      pObject.files = [];
      pObject.title = "העתק של" + " " + pObject.title;
      delete pObject.status;
      const query = {
        property: JSON.stringify(pObject),
        filesToUpload: [],
        filesToDelete: JSON.stringify([])
      };
      return propertyService.saveProperty(null, query)
        .then((response) => {
          alert("הנכס הועתק בהצלחה ונמצא בקטגוריית טיוטות" + ` בשם: ${pObject.title}`)
          location.reload();
        })
    }

    filterStatus (v, dice) {
      vm.statusFilter = v
      if (dice) {
        let all = document.getElementById('statusDice').children;
        for (let index = 0; index < all.length; index++) {
          const element = all[index];
          element.setAttribute("style", "color: #2e8ddd;background-color: inherit;")
          element.querySelector('.statusName').style.color = '#333';
        }
        document.getElementById([dice]).style.backgroundColor = '#2e8ddd';
        document.getElementById([dice]).style.color = '#ffffff';
        document.getElementById([dice]).querySelector('.statusName').style.color = '#ffffff';
      }

    }

    getusersFilterValue () {
      if (vm.usersFilter) {
        return vm.usersFilter.firstName + " " + vm.usersFilter.lastName
      }
    }

    checkUsersFilter (user) {
      vm.usersFilter = user;
      if (user) {
        vm.rows = vm.list.rows.filter(p => p.companyEmployees ? JSON.parse(p.companyEmployees).includes(vm.usersFilter.id) : null)
      }
      else {
        vm.rows = vm.list.rows
      }
    }

    statusNum () {
      vm.publish = vm.getNum('publish')
      vm.pending = vm.getNum('pending');
      vm.closed = vm.getNum('closed');
      vm.paused = vm.getNum('paused');
      vm.draft = vm.getNum('draft');
    }

    getNum (val) {
      let properties = vm.allProperties;
      let num = properties.filter(i => i.status === val).length;
      return num > 0 ? num : null;
    }

    getStatusFilterValue () {
      return _.get(vm.statusFilterOptions.find((item) => item.value === vm.statusFilter), 'label');
    }

    getNumPropertys () {
      return propertyService.getNumPropertys($rootScope.org.id).then(result => {
        return result
      });
    }

    async saveStatus (propertyId, status) {
      if (status === 'publish') {
        let numPropertys = await vm.getNumPropertys();
        let cards = await propertyService.getOrgCards();
        if (cards.length === 0 && numPropertys < 1) {
          const query = {
            property: JSON.stringify({ status })
          };
          return vm.propertyService.saveProperty(propertyId, query)
            .then(() => {
              vm.getMyProperties();
            });
        }
        let MaxProperties = $rootScope.org.planMaxAllProperties;
        if (numPropertys >= MaxProperties && (MaxProperties !== 0 || MaxProperties !== null)) {
          return $uibModal.open({
            component: 'popupExceedingAssets',
            resolve: {
              numPropertys: () => numPropertys
            }
          });
        }
      }
      const query = {
        property: JSON.stringify({ status })
      };

      return vm.propertyService.saveProperty(propertyId, query)
        .then(() => {
          vm.getMyProperties();
        });
    }

    addPayment (propertyId) {
      this.getOrgChargePage(propertyId, '', false, false);
    }
  }

  return new MyPropertiesController();
}
