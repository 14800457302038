export default function () {
  let vm;

  class P404Controller {
    constructor () {
      vm = this;
      vm.name = 'p404';
    }

    $onInit () {
      $('head').append('<meta name="prerender-status-code" content="404">');
    }

    $onDestroy () {
      $('[name="prerender-status-code"][content="404"]').remove();
    }
  }

  return new P404Controller();
}
