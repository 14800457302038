import _ from 'lodash';

export default function ($scope, $state, $location, $timeout, loginService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class RestorePasswordCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      $timeout(() => {
        $('#restore-form').on('keydown keypress', (event) => {
          if (event.which === KEY_EVENT_ENTER) {
            vm.send();
            event.preventDefault();
          }
        });
        $('#restore-email').focus();
      });
    }

    send () {
      if (vm.restoreForm.$invalid) {
        $('#restore-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.restoreForm);
        return;
      }

      vm.error = false;
      vm.sendProgress = true;
      const email = (vm.email || '').replace(/[\u{0080}-\u{FFFF}]/gu, '');
      loginService.restorePassword(email, vm.password)
        .then((res) => {
          if (!res) {
            return Promise.reject(new Error(''));
          }
          vm.successMessage = true;
          return res;
        })
        .catch((error) => {
          if (_.get(error, 'data.data') === 'Error: Email address not found') {
            vm.errorMessage = 'דוא"ל לא רשום במערכת.';
          } else {
            if (_.get(error, 'data.data') === 'Error: login with google') {
              vm.errorMessage = 'אין צורך לאפס סיסמא, נרשמת למערכת עם כניסת גוגל - אנא התחבר לחשבון והיכנס מחדש';
            } else {
            vm.errorMessage = 'שגיאה.';
          }}
          vm.sendProgress = false;
          vm.error = true;

          return error;
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }
  }

  return new RestorePasswordCtrl();
}
