import angular from 'angular';

import BannerService from './banner.service';
import BannerShow from './banner-show/banner-show';

const BannerModule = angular.module('banner', [
  BannerService,
  BannerShow
])
  .name;

export default BannerModule;
