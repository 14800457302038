export default function ($rootScope, $state, $uibModal, mailingListService, messagesService, categoryService) {
  let vm;

  class HeaderController {
    constructor () {
      vm = this;
      vm.name = 'header';
      vm.messagesService = messagesService
      vm.getmessages = true;
      vm.categorySaleList = categoryService.forSaleListHeader;
      vm.categoryRentList = categoryService.forRentListHeader;
      vm.cityCategory = categoryService.cityCategoryTopNav;
      vm.district = categoryService.districtCategorySideNav;

    }

    $onInit () {
      vm.cityCategorylimit = 13;
      vm.stateName = $state.current.name;
      const bodyElement = document.querySelector('body');
      window.addEventListener('scroll', (n) => {
        checkOffset(bodyElement);
      });

      checkOffset(bodyElement);
    }

    getUnreadPosts (id) {
      if (vm.getmessages) {
        vm.getMessages(id);
        vm.getmessages = false
      }
    }

    // districtLink (district, type) {
    //   let d = { district: district };
    //   let t = type ? 'for-sale' : 'for-rent';
    //   let url = '/' + t + '/' + '?category=' + vm.category + '&query=' + district + '&column=' + JSON.stringify(d);
    //   return url
    // }

    changeCityMenu (key, label) {
      vm.category = key
      vm.categoryLabel = label
      vm.ProducingCity = false;
    }

    changeProducingCityMenu () {
      vm.ProducingCity = true;
    }

    getMessages (id) {
      if (id && !vm.unreadPosts) {
        messagesService.getMessageList('inbox', {})
          .then((res) => {
            vm.unreadPosts = 0
            res.rows.map((message) => {
              vm.unreadPosts = vm.unreadPosts + message.posts.filter((post) => post.to === id && !post.read).length;
            })
          });
      }
    }


    getUserProfileImage () {
      if (!$rootScope.user.profileImageUrl) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + $rootScope.user.profileImageUrl;
    }

    toggleSideMenu () {
      $('body').toggleClass('show-sidebar');
    }

    minimizeSideMenu () {
      $('body').removeClass('show-sidebar');
    }

    scrollTop () {
      if ($state.current.name === 'home') {
        $('html, body').animate({ scrollTop: 0 }, 600);
      }
    }

    login () {
      $uibModal.open({
        component: 'loginContainer',
        windowClass: 'login-modal',
        size: 'sm'
      });
    }

    signUp () {
      $uibModal.open({
        component: 'loginContainer',
        resolve: {
          state: () => 'signUp'
        },
        windowClass: 'login-modal',
        size: 'sm'
      });
    }

    isAdmin () {
      return $rootScope.isAdmin();
    }

    openMailingListForm () {
      mailingListService.openForm();
    }
  }

  return new HeaderController();
}

function checkOffset (bodyElement) {
  window.scrollY ? bodyElement.classList.add('header-top') : bodyElement.classList.remove('header-top');
}
