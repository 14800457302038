let $rootScope;
let request;
let fileRequest;

class bannerService {
  constructor (_$rootScope, _request, _fileRequest) {
    $rootScope = _$rootScope;
    request = _request;
    fileRequest = _fileRequest;
  }

  getBanners (statusFilter, queryParams) {
    if (!$rootScope.isAdmin()) {
      return Promise.reject(new Error('No permission'));
    }

    return request('/banner?' + jQuery.param(queryParams) + (statusFilter ? '&status=' + statusFilter : ''))
      .then((res) => res.data.data);
  }

  getBanner (id) {
    return request('/banner/' + id)
      .then((res) => res.data.data);
  }

  saveBanner (bannerId, bannerObject) {
    return fileRequest('/banner/' + (bannerId || ''), bannerObject, (bannerId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  getRandomBanner () {
    return request('/banner/random')
      .then((res) => res.data.data);
  }

  bannerClicked (bannerId, bannerObject) {
    return request(`/banner/${bannerId}/clicked`, {}, 'POST')
      .then((res) => res.data.data);
  }
}

bannerService.$inject = ['$rootScope', 'request', 'fileRequest'];

const bannerServiceModule = angular.module('bannerService', [])
  .service('bannerService', bannerService)
  .name;

export default bannerServiceModule;
