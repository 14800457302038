import moment from 'moment';
moment.locale('he');
import XLSX from 'xlsx';

export default function ($scope, $rootScope, $state, $timeout, manageService, utils, $uibModal) {
  let vm;

  class ManageUserListController {
    constructor () {
      vm = this;
      vm.test1 = ""

      vm.orgKindOptions = {
        broker: 'מתווך',
        lender: 'בעל נכס',
        investor: 'משקיע',
        buyer: 'רוכש',
        other: 'אחר'
      };
      vm.orderBy = '-createdAt';
    }

    $onInit () {
      vm.paging = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 50,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.getUserList();
      $scope.$on('plan-changed', (event, args) => {
        vm.list.rows.filter((user) => user.org.id === args.orgId)
          .forEach((user) => {
            user.org.planName = args.planDetails.name;
            user.org.planDescription = args.planDetails.description;
            user.org.planType = args.planDetails.type;
            user.org.planExpiry = args.planDetails.expiry;
            user.org.planMaxProperties = args.planDetails.maxProperties;
            user.org.planMaxAllProperties = args.planDetails.maxAllProperties,
            user.org.planPrice = args.planDetails.price;
            user.planInfo = this.getOrgPlanInfo(user.org);
          });
      });
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    getUserList () {
      manageService.getUserList(vm.searchValue, {
        limit: vm.paging.limit,
        offset: vm.paging.offset,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      })
        .then((res) => {
          res.rows.forEach((user) => {
            user.planInfo = this.getOrgPlanInfo(user.org);
            user.InvoiceName = user.org.invoiceName || user.org.name || [_.get(user, 'firstName'), _.get(user, 'lastName')].join(' ') || '';
          });
          vm.list = res;
          vm.setPagingFlags();
        });
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getUserList();
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getUserProfileImage (user) {
      if (!user.profileImageUrl) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + user.profileImageUrl;
    }

    getOrgPlanInfo (org) {
      const properties = org.properties.reduce((properties, property) => {
        if (property.status === 'publish') {
          if (
            !org.planName ||
            (org.planType === 'perProperty' && this.shouldExpire(property))
          ) {
            properties.expired.push(property);
          } else {
            properties.published.push(property);
          }
        } else properties.inactive.push(property);
        return properties;
      }, {
        expired: [],
        inactive: [],
        published: []
      });
      const status = [];
      let okProperties, excessProperties;
      if (org.planType === 'monthly') {
        okProperties = Math.min(org.planMaxProperties, properties.published.length);
        excessProperties = Math.max(properties.published.length - org.planMaxProperties, 0);
        if (moment().isAfter(moment(org.planExpiry))) {
          status.push({
            type: 'fail',
            text: 'פג תוקף המסלול'
          });
        }
        if (excessProperties) {
          status.push({
            type: 'warn',
            text: `מספר הנכסים חורג מהמסלול שהוגדר (${properties.published.length}/${org.planMaxProperties})`
          });
        }
      } else {
        okProperties = properties.published.length;
        excessProperties = properties.expired.length;
        if (properties.expired.length) {
          status.push({
            type: 'warn',
            text: 'קיימים נכסים שפג תוקפם'
          });
        }
      }
      if (!org.orgCreditCardTokens.length) {
        status.push({
          type: 'warn',
          text: 'לא מוגדר אמצעי תשלום'
        });
      } else if (!(org.orgCreditCardTokens.find((card) => card.active))) {
        status.push({
          type: 'warn',
          text: `לא נמצא אמצעי תשלום פעיל מתוך ${org.orgCreditCardTokens.length}`
        });
      }
      return {
        status, okProperties, excessProperties, inactiveProperties: properties.inactive.length
      };
    }

    shouldExpire (property) {
      return (moment().isAfter(moment(property.expiry).add(1, 'day')) || !moment(property.expiry).isValid());
    }

    editPlan (user) {
      console.log(user);
      manageService.getUserList(user.email)
        .then((res) => {
          let User = res.rows.find(i => i.email === user.email)
          User.planInfo = this.getOrgPlanInfo(User.org);
          $uibModal.open({
            component: 'managePlan',
            windowClass: '',
            size: 'sm',
            resolve: {
              user: () => User
            }
          }).result.then(function () { vm.getUserList(); }, function (res) { });
        });
    }
    export (num) {
      manageService.getUserList(vm.searchValue)
        .then((res) => {
          res.rows.forEach((user) => {
            let views = 0;
            let phoneShown = 0;
            let propertyPrinted = 0;
            let messages = 0;
            if (user.org) {
              if (user.org.properties) {
                user.org.properties.map((prop) => {
                  if (prop.views) {
                    views += prop.views
                  }
                  if (prop.phoneShown) {
                    phoneShown += prop.phoneShown
                  }
                  if (prop.propertyPrinted) {
                    propertyPrinted += prop.propertyPrinted
                  }
                  if (prop.messages.length > 0) {
                    messages += prop.messages.length
                  }
                });
              }
              user.planInfo = this.getOrgPlanInfo(user.org);
              user.views = views;
              user.phoneShown = phoneShown;
              user.propertyPrinted = propertyPrinted;
              user.messages = messages;
              user.InvoiceName = user.org.invoiceName || user.org.name || [_.get(user, 'firstName'), _.get(user, 'lastName')].join(' ') || '';
            }
          });
          vm.AllList = res;
          setTimeout(function () {
            if (num === 1) { vm.export1() }
            else { vm.export2() }
          }, 2000);
        });
    }
    export2 () {
      let data = document.getElementById('exportTable');
      // if (typeof XLSX == 'undefined') XLSX = require('xlsx');
      let People = XLSX.utils.table_to_sheet(data);
      let wb = {
        Workbook: {
          Views: [
            { RTL: true }
          ]
        },
        Sheets: { People },
        SheetNames: ["People"]
      }
      XLSX.utils.book_append_sheet(wb);
      XLSX.writeFile(wb, "Users2.xlsx");
    }


    export1 () {
      let htmls = "";
      let uri = 'data:application/vnd.ms-excel;base64,';
      let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayRightToLeft/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
      let base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      };
      let format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        })
      };
      htmls = document.getElementById("exportTable").outerHTML
      let ctx = {
        worksheet: 'Worksheet',
        table: htmls
      }
      let link = document.createElement("a");
      link.download = "Users1.xls";
      link.href = uri + base64(format(template, ctx));
      link.click();
    }

    test () {
      // return manageService.test(vm.test1).then((resUser) => {
      return manageService.test().then((resUser) => {
        // console.log(resUser);
      })
        .catch(() => {
          // console.log(resUser);
        });
    }

    deleteLockFile () {
      return manageService.deleteLockFile()
        .then()
        .catch(() => {
        });
    }

    cLockFile () {
      return manageService.cLockFile()
        .then()
        .catch(() => {
        });
    }

    phoneBlockedChange (blocked, user) {
      return manageService.toggleUserPhoneBlocked(user, blocked)
        .then((resUser) => {
          user.phoneBlocked = resUser.phoneBlocked;
        })
        .catch(() => {
          user.phoneBlocked = !user.phoneBlocked;
        });
    }

    userShow (user) {

    }
  }

  return new ManageUserListController();
}
