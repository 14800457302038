import angular from 'angular';
import projectFilesEdit from './project-files-edit/project-files-edit';
import projectEditComponent from './project-edit.component';

const projectEditModule = angular.module('projectEdit', [
  projectFilesEdit
])
  .component('projectEdit', projectEditComponent)
  .name;

export default projectEditModule;
