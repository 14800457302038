import angular from 'angular';
import propertyFilesEdit from './property-files-edit/property-files-edit';
import propertyEditComponent from './property-edit.component';
import popupExceedingAssets from '../../popup-exceeding-assets/popup-exceeding-assets';
const propertyEditModule = angular.module('propertyEdit', [
  propertyFilesEdit,
  popupExceedingAssets
])
  .component('propertyEdit', propertyEditComponent)
  .name;

export default propertyEditModule;
