import _ from 'lodash';

export default function ($scope, $rootScope, $state, sweetAlert, messagesService, utils, eventsService) {
  let vm;

  class LeadSendMessageCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.model = {};
      vm.userLead = vm.resolve.userLead || {};
      vm.messageModel = `היי ${vm.userLead['user.firstName']}`;
    }

    sendReport () {
      utils.setFormFieldsDirty(vm.leadSendMessageForm);

      if (vm.leadSendMessageForm.$invalid) {
        $('#lead-send-message-form')[0].reportValidity();
        return;
      }
      vm.sendProgress = true;

      if (!vm.userLead['user.id']) {
        return;
      }

      return messagesService.sendLeadMessage(vm.messageModel, vm.userLead['user.id'])
        .then(() => {
          sweetAlert.swal('', 'ההודעה נשלחה בהצלחה', 'success', {
            buttons: false,
            timer: 3000
          });
          vm.modalInstance.close();
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }
  }

  return new LeadSendMessageCtrl();
}
