import angular from 'angular';

import template from './projects-keys.html';
import './projects-keys.scss';

const projectsKeysModule = angular.module('projectsKeys', ['manageService'])
  .directive('projectsKeys', [function () {
    return {
      restrict: 'E',
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        model: '=?',
        projects: '=?'
      },
      template,
      controller () {
        const vm = this;
        vm.$onInit = function () {
          console.log('vm.model', vm.model);
          vm.model = vm.model || [{
            projectID: '',
            zapierUrl: ''
          }];
        };

        vm.addNewProject = function () {
          vm.model.push({
            projectID: '',
            zapierUrl: ''
          });
        };

        vm.deleteProject = function (index) {
          vm.model.splice(index, 1);
        };
      }
    };
  }])
  .name;

export default projectsKeysModule;
