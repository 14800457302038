import _ from 'lodash';

let request;

class userService {
  constructor (_$rootScope, _request) {
    request = _request;
  }

  getUserList (type) {
    return request('/user' + (type ? '?type=' + type : ''))
      .then((res) => {
        return _.get(res, 'data.data');
      });
  }

  getUser (userId) {
    return request('/user/' + userId)
      .then((res) => {
        return _.get(res, 'data.data');
      })
      .catch((error) => {
        console.error('GetUser failed', error);
        return Promise.reject(error);
      });
  }
}

userService.$inject = ['$rootScope', 'request'];

const userServiceModule = angular.module('userService', [])
  .service('userService', userService)
  .name;

export default userServiceModule;
