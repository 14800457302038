import _ from 'lodash';

export default function ($scope, $state, $location, $timeout, loginService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class LoginCtrl {
    constructor () {
      vm = this;
      vm.serverUrl = window.SERVER_URL;
    }

    $onInit () {
      $('#login-form').on('keydown keypress', (event) => {
        if (event.which === KEY_EVENT_ENTER) {
          vm.send();
          event.preventDefault();
        }
      });

      const urlEmail = $location.search().email;
      if (urlEmail && urlEmail !== true) {
        vm.email = $location.search().email;
      }
      $('#login-email').focus();
    }

    send () {
      if (vm.loginForm.$invalid) {
        $('#login-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.loginForm);
        return;
      }

      vm.error = false;
      vm.sendProgress = true;
      $timeout(() => {
        vm.isTimeout = true;
        if (vm.isError) {
          vm.showErrorMessage();
        }
      }, 1500);
      loginService.login(vm.email, vm.password)
        .then((res) => {
          if (!res) {
            return Promise.reject(new Error(''));
          }
          const user = _.get(res, 'data.data');
          vm.loginContainerCtrl.closeModalBox(user);
          if ($state.current.name === 'newPassword') {
            $state.go('home');
          }
          return res;
        })
        .catch((error) => {
          vm.isError = true;
          if (vm.isTimeout) {
            vm.showErrorMessage();
          }
          return error;
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    showErrorMessage () {
      vm.sendProgress = false;
      vm.error = true;
      vm.isError = false;
      vm.isTimeout = false;
    }
  }

  return new LoginCtrl();
}
