import swal from 'sweetalert';
let service;

function sweetAlertService ($timeout, $window) {
  service = {
    swal (title = '', message = '', className = '', args = {}) {
      $timeout(function () {
        // if (typeof (message) === 'function') {
        //   swal(title, function (isConfirm) {
        //     $timeout(function () {
        //       message(isConfirm);
        //     });
        //   }, className, {
        //     buttons: {
        //       cancel: 'ביטול',
        //       confirm: 'אישור'
        //     }
        //   });
        // } else {
        swal(title, message, className, { ...args });
        // }
      });
    },
    success (title, message) {
      service.swal(title, message, 'success', { button: 'אישור' });
    },
    error (title, message) {
      service.swal(title, message, 'error', { button: 'אישור' });
    },
    warning (title, message) {
      service.swal(title, message, 'warning', { button: 'אישור' });
    },
    info (title, message) {
      service.swal(title, message, 'info', { button: 'אישור' });
    }
  };

  return service;
}

sweetAlertService.$inject = ['$timeout', '$window'];

const sweetAlertServiceModule = angular.module('sweetAlertService', [])
  .service('sweetAlert', sweetAlertService)
  .name;

export default sweetAlertServiceModule;
