import template from './profile-edit.html';
import controller from './profile-edit.controller';
import './profile-edit.scss';

const profileEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'profileService', 'utils'];

export default profileEditComponent;
