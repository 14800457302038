import template from './dashboard.html';
import controller from './dashboard.controller';
import './dashboard.scss';

const dashboardComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'utils'];

export default dashboardComponent;
