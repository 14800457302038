import _ from 'lodash';
import options from '../../../common/options';
import moment from 'moment';
moment.locale('he');
import XLSX from 'xlsx';

export default function ($scope, $rootScope, $state, $timeout, propertyService, userService, manageService, utils, $uibModal) {
  let vm;

  class ManagePropertiesController {
    constructor () {
      vm = this;
      vm.AllList = [];
      vm.orderByOptions = [
        { name: 'publishedAt', desc: true, label: 'פורסם לאחרונה' },
        { name: 'price', desc: false, label: 'מחיר (מהנמוך לגבוה)' },
        { name: 'price', desc: true, label: 'מחיר (מהגבוה לנמוך)' }
      ];

      vm.typeOptions = options.propertyTypeOptions;
      vm.statusOptions = options.propertyStatusOptions;
      vm.tabsOptions = {
        pending: 'נכסים ממתינים לאישור',
        publish: 'נכסים שפורסמו',
        other: 'שאר הנכסים',
        closed: 'נכסים שנמכרו',
        canceled: 'נכסים מבוטלים'
      };
    }

    $onInit () {
      vm.paging = {
        orderBy: 'publishedAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.searchType = 'property';
      vm.selectedTab = $state.params.status || localStorage.getItem('manageSelectedTab');
      if (!vm.selectedTab || !Object.keys(vm.tabsOptions).includes(vm.selectedTab)) {
        vm.selectedTab = 'pending';
      }
      localStorage.setItem('manageSelectedTab', vm.selectedTab);

      if (vm.selectedTab === 'other') {
        vm.statusFilter = 'draft|paused|expired';
      } else {
        vm.statusFilter = vm.selectedTab;
      }
      vm.getManageProperties();

    }


    getpartproperties (offset, limit) {
      let finish = new Promise((resolve, reject) => {
        manageService.getManageProperties(vm.statusFilter, "", "", {
          limit: limit,
          offset: offset,
          order: 'publishedAt',
          desc: true
        })
          .then((res) => {
            res.rows.forEach((property) => {
              if (property.org) {
                property.planInfo = this.getOrgPlanInfo(property.org);
              }
            });
            vm.AllList = vm.AllList.concat(res.rows);
            resolve();
          })
      })
      return finish;
    }

    copy (property) {
      let pObject = structuredClone(property);
      pObject.files = [];
      pObject.title = "העתק של" + " " + pObject.title;
      delete pObject.status;
      const query = {
        property: JSON.stringify(pObject),
        filesToUpload: [],
        filesToDelete: JSON.stringify([])
      };
      return propertyService.saveProperty(null, query)
        .then((response) => {
          alert("הנכס הועתק בהצלחה ונמצא בקטגוריית טיוטות" + ` בשם: ${pObject.title}`)
          location.reload();
        })
    }

    getAllManageProperties () {
      manageService.getAllManageProperties(vm.statusFilter, "", "", {
        limit: vm.paging.limit,
        offset: vm.paging.offset,
        order: 'publishedAt',
        desc: true
      })
        .then((res) => {
          vm.AllList = res;
          setTimeout(function () {
            vm.export()
            console.log(vm.AllList, 'rows1');
          }, 3000);
          // function getParts (sum, n, length) {
          //   let left, right;
          //   if (n * length === sum) return Array.from({ length }, (_) => n);
          //   if (n * length < sum) {
          //     left = Math.floor(sum / n);
          //   } else {
          //     left = n;
          //     n = Math.floor(sum / left);
          //   }
          //   right = (sum - left * n) / (length - n);
          //   return Array.from({ length }, (_, i) => (i < n ? left : right));
          // }

          // if (res.count > 99) {
          //   let partsaraay = getParts(res.count, 10, 10);
          //   let start = partsaraay[0];
          //   let requestdetails = [{ offset: 0, limit: partsaraay[0] }];
          //   for (let i = 1; i <= 9; i++) {
          //     requestdetails.push({ offset: start, limit: partsaraay[i] });
          //     start += partsaraay[i];
          //   }

          //   return Promise.all([
          //     vm.getpartproperties(requestdetails[0].offset, requestdetails[0].limit),
          //     vm.getpartproperties(requestdetails[1].offset, requestdetails[1].limit),
          //     vm.getpartproperties(requestdetails[2].offset, requestdetails[2].limit),
          //     vm.getpartproperties(requestdetails[3].offset, requestdetails[3].limit),
          //     vm.getpartproperties(requestdetails[4].offset, requestdetails[4].limit),
          //     vm.getpartproperties(requestdetails[5].offset, requestdetails[5].limit),
          //     vm.getpartproperties(requestdetails[6].offset, requestdetails[6].limit),
          //     vm.getpartproperties(requestdetails[7].offset, requestdetails[7].limit),
          //     vm.getpartproperties(requestdetails[8].offset, requestdetails[8].limit),
          //     vm.getpartproperties(requestdetails[9].offset, requestdetails[9].limit + 50)
          //   ]).finally(() => {
          //     setTimeout(function () {
          //       vm.export()
          // console.log(vm.AllList, 'rows1');

          //     }, 3000);
          //   })
          // }
          // else {
          //   return Promise.all([
          //     vm.getpartproperties(0, 100),
          //   ]).finally(() => {
          //     setTimeout(function () {
          //       vm.export()
          // console.log(vm.AllList, 'rows2');

          //     }, 3000);
          //   })
          // }
        })
    }

    export () {
      let data = document.getElementById('exportTable');
      let Properties = XLSX.utils.table_to_sheet(data);
      let wb = {
        Workbook: {
          Views: [
            { RTL: true }
          ]
        },
        Sheets: { Properties },
        SheetNames: ["Properties"]
      }
      XLSX.utils.book_append_sheet(wb);
      XLSX.writeFile(wb, "properties.xlsx");
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getManageProperties();
    }

    doOrder (value, desc) {
      if (angular.isUndefined(desc)) {
        if (value === vm.paging.orderBy) {
          desc = !vm.paging.orderDesc;
        } else {
          desc = true;
        }
      }
      vm.paging.orderBy = value;
      vm.paging.orderDesc = desc;
      vm.doPaging(1);
    }

    getManageProperties () {
      vm.loadProgress = true;
      let searchValue = '';
      let userSearchValue = '';
      if (vm.searchType === 'property') {
        searchValue = vm.searchValue;
      } else if (vm.searchType === 'user') {
        userSearchValue = vm.searchValue;
      }

      return Promise.all([
        manageService.getManageProperties(vm.statusFilter, searchValue, userSearchValue, {
          limit: vm.paging.limit,
          offset: vm.paging.offset,
          order: vm.paging.orderBy,
          desc: vm.paging.orderDesc
        })
          .then((res) => {
            res.rows.forEach((property) => {
              if (property.org) {
                property.planInfo = this.getOrgPlanInfo(property.org);
              }
            });
            vm.list = res;
            vm.setPagingFlags();
          }),
        manageService.getManagePropertiesCounts()
          .then((counts) => {
            vm.counts = counts || {};
            _.set(vm.counts, 'all', Object.values(vm.counts).reduce((previous, current) => {
              return current + previous;
            }, 0));
          })
      ])
        .finally(() => {
          vm.loadProgress = false;
        });
    }

    changeSearchType (type) {
      vm.searchType = type || 'property';
      if (vm.searchValue.length) {
        vm.doPaging(1);
      }
    }

    saveStatus (propertyId, status, publishOneSignal, withoutTelegramnot, withoutTwitter) {
      if (vm.saveProgress) {
        return;
      }
      const query = {
        property: JSON.stringify({ status })
      };

      vm.saveProgress = true;
      return propertyService.saveProperty(propertyId, query, publishOneSignal, withoutTelegramnot, withoutTwitter)
        .then(() => {
          vm.getManageProperties();
          if (status === 'publish') {
            manageService.collectDeals(propertyId).then()
              .finally(e => console.log(e))
          }
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    getApproverName (property) {
      return _.get(property, 'approver.firstName') + ' ' + _.get(property, 'approver.lastName');
    }

    getOrgPlanInfo (org) {
      const properties = org.properties.reduce((properties, property) => {
        if (property.status === 'publish') {
          if (
            !org.planName ||
            (org.planType === 'perProperty' && this.shouldExpire(org, property))
          ) {
            properties.expired.push(property);
          } else {
            properties.published.push(property);
          }
        } else properties.inactive.push(property);
        return properties;
      }, {
        expired: [],
        inactive: [],
        published: []
      });
      const status = [];
      let okProperties, excessProperties;
      if (org.planType === 'monthly') {
        okProperties = Math.min(org.planMaxProperties, properties.published.length);
        excessProperties = Math.max(properties.published.length - org.planMaxProperties, 0);
        if (moment().isAfter(moment(org.planExpiry))) {
          status.push({
            type: 'fail',
            text: 'פג תוקף המסלול'
          });
        }
        if (excessProperties) {
          status.push({
            type: 'warn',
            text: `מספר הנכסים חורג מהמסלול שהוגדר (${properties.published.length}/${org.planMaxProperties})`
          });
        } else if (okProperties === org.planMaxProperties) {
          status.push({
            type: 'warn',
            text: 'הוספת נכס יגרום לחריגה'
          });
        }
      } else {
        okProperties = properties.published.length;
        excessProperties = properties.expired.length;
        if (properties.expired.length) {
          status.push({
            type: 'warn',
            text: 'קיימים נכסים שפג תוקפם'
          });
        }
      }
      if (!org.orgCreditCardTokens.length) {
        status.push({
          type: 'warn',
          text: 'לא מוגדר אמצעי תשלום'
        });
      } else if (!(org.orgCreditCardTokens.find((card) => card.active))) {
        status.push({
          type: 'warn',
          text: `לא נמצא אמצעי תשלום פעיל מתוך ${org.orgCreditCardTokens.length}`
        });
      }
      return {
        status, okProperties, excessProperties, inactiveProperties: properties.inactive.length
      };
    }

    shouldExpire (org, property) {
      return org.planType === 'perProperty' &&
        (moment().isAfter(moment(property.expiry).add(1, 'day')) || !moment(property.expiry).isValid());
    }

    editPlan (user) {
      $uibModal.open({
        component: 'managePlan',
        windowClass: '',
        size: 'sm',
        resolve: {
          user: () => user
        }
      });
    }

    promotingBlockedChanged (blocked, property) {
      return manageService.togglePropertyPromotingBlocked(property, blocked)
        .then((resProperty) => {
          property.promotingBlocked = resProperty.promotingBlocked;
        })
        .catch(() => {
          property.promotingBlocked = !property.promotingBlocked;
        });
    }
  }

  return new ManagePropertiesController();
}
