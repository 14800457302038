import template from './deleteItem.html';
import controller from './deleteItem.controller';
import './deleteItem.scss';

const deleteItemComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$timeout', 'request'];

export default deleteItemComponent;
