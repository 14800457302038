import angular from 'angular';
import planChooserComponent from './plan-chooser.component';
import planService from './plan.service';

const planChooserModule = angular.module('planChooser', [
  planService
])
  .component('planChooser', planChooserComponent)
  .name;

export default planChooserModule;
