import _ from 'lodash';

export default function ($scope, $state, orgService) {
  let vm;

  class OrgShowController {
    constructor () {
      vm = this;

      vm.filters = {};
    }

    async $onInit () {
      vm.filters = {
        orgId: $state.params.orgId
      };
      vm.orgUser = _.get(vm, 'org.users.0');
      const pageLink = 'https://menivim.net/org/' + vm.org.id + '/';
      const orgUserName = [vm.orgUser.firstName, vm.orgUser.lastName].join(' ').trim();
      let pageTitle = [(vm.org.name || orgUserName), 'מניבים לוח נדל"ן מסחרי'].filter((value) => !!value).join(' | ');
      pageTitle = encodeURIComponent(pageTitle);
      vm.socialLinks = {
        whatsapp: 'https://wa.me/?text=' + pageTitle + '+ +' + pageLink,
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=' + pageLink + '&title=' + pageTitle + '&quote=' + pageTitle,
        twitter: 'https://twitter.com/intent/tweet?url=' + pageLink + '&text=' + pageTitle,
        telegram: 'https://telegram.me/share/url?url=' + pageLink + '&text=' + pageTitle,
        copy: pageLink
      };
    }

    getOrgLogoUrl () {
      if (!vm.org) {
        return '';
      }
      if (!_.get(vm, 'org.logoUrl')) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + vm.org.logoUrl;
    }

    getUserLogoUrl () {
      if (!vm.org) {
        return '';
      }
      if (!_.get(vm, 'orgUser.profileImageUrl')) {
        return 'assets/images/user.png';
      }
      return window.SERVER_URL + vm.orgUser.profileImageUrl;
    }

    share (media) {
      if (media === 'copy') {
        navigator.clipboard.writeText(vm.socialLinks[media]).then(function () {
            let copy = document.getElementById('copy')
            copy.setAttribute('class', 'copied')
            copy.innerHTML = "<p>הקישור הועתק</p>"
        });
      }
      else {
        window.open(vm.socialLinks[media], '_blank', 'width=600,height=509');
      }
    }
  }

  return new OrgShowController();
}
