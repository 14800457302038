import _ from 'lodash';

export default function ($scope, $rootScope, $state, $timeout, manageService, utils, $uibModal) {
  let vm;

  class ManagePluginsController {
    constructor () {
      vm = this;

      vm.typesOfPlugins = {
        zapier_hooks_leads: 'Zapper ',
        project_email: 'שליחת אמייל של ליד פרויקט'
      };
    }

    $onInit () {
      vm.paging = {
        limit: 50,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        offset: 0,
        count: 0,
        page: 0
      };

      vm.getPlugins();
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit) + 1;
      vm.paging.current.offset = vm.paging.offset;

      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 1; index++) {
        if (index > 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    getPlugins (reload = false) {
      manageService.getPlugins(vm.searchValue, {
        limit: vm.paging.limit,
        offset: vm.paging.offset
      }, reload)
        .then((res) => {
          vm.list = res;
          vm.setPagingFlags();
        });
    }

    doPaging (page) {
      if (Number(page) < 1 || Number(page) > vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * (Number(page) - 1);
      vm.getPlugins();
    }

    editPlugin (plugin) {
      $uibModal.open({
        component: 'managePlugin',
        windowClass: '',
        size: 'sm',
        resolve: {
          pluginDetails: () => plugin
        }
      }).closed.then(function () {
        vm.getPlugins(true);
      });
    }

    pluginAdd () {
      $uibModal.open({
        component: 'managePlugin',
        windowClass: '',
        size: 'sm',
        resolve: {
          pluginDetails: () => { }
        }
      }).closed.then(function () {
        vm.getPlugins(true);
      });
    }
  }

  return new ManagePluginsController();
}
