import _ from 'lodash';

let service;
let $rootScope;
let $uibModal;
let request;

class loginService {
  constructor (_$rootScope, _$uibModal, _request) {
    service = this;
    $rootScope = _$rootScope;
    $uibModal = _$uibModal;
    request = _request;
  }

  login (email, password) {
    email = (email || '').replace(/[\u{0080}-\u{FFFF}]/gu, '');
    return request('/login', { email, password })
      .then(async (res) => {
        const user = _.get(res, 'data.data');
        $rootScope.user = user;
        $rootScope.org = await getOrg(user.orgId);
        $rootScope.$broadcast('userLoggedIn', user);
        if ($rootScope.org && !$rootScope.org.kind) {
          service.completeSignUp();
        }
        return res;
      });
  }

  async isAuthenticated () {
    return request('/is-authenticated?' + Math.floor(1000 + Math.random() * 9000), null, 'GET', { ignoreLoadingBar: true })
      .then(async (res) => {
        const user = _.get(res, 'data.data');
        $rootScope.$broadcast('userAuthenticated', user);
        if (!$rootScope.user) {
          $rootScope.user = user;
        }
        if (!$rootScope.org) {
          await getOrg(user.orgId)
            .then((org) => {
              $rootScope.org = org;
              $rootScope.$broadcast('orgSaved', org);
            });
        }
        if ($rootScope.org && !$rootScope.org.kind) {
          service.completeSignUp();
        }
        return res;
      });
  }

  checkUser () {
    request('/check-user?' + new Date().getTime(), null, 'GET', { ignoreLoadingBar: true })
      .then(async (res) => {
        const user = _.get(res, 'data.data');
        if (user) {
          if (!$rootScope.user) {
            $rootScope.user = user;
          }
          if (!$rootScope.org) {
            await getOrg(user.orgId)
              .then((org) => {
                $rootScope.org = org;
                $rootScope.$broadcast('orgSaved', org);
              });
          }
        } else {
          delete $rootScope.user;
          delete $rootScope.org;
        }
        if ($rootScope.org && !$rootScope.org.kind) {
          service.completeSignUp();
        }
      });
    // .catch (() => {
    //   delete $rootScope.user;
    //   delete $rootScope.org;
    // });
    return ($rootScope.user || {});
  }

  isUserLoggedIn () {
    return request('/check-user?' + new Date().getTime(), null, 'GET', { ignoreLoadingBar: true })
      .then((res) => {
        const user = _.get(res, 'data.data');
        if (!user) {
          return Promise.reject(new Error('user is not logged in'));
        }
        return user;
      });
  }

  logout () {
    return request('/logout')
      .then((res) => {
        if (res.data === 'disconnected') {
          $rootScope.$broadcast('userLoggedOut');
        }

        delete $rootScope.user;
        delete $rootScope.org;
        return res;
      });
  }

  restorePassword (email) {
    return request('/user/restore-password', { email }, 'POST');
  }

  checkToken (token) {
    return request('/user/check-restore-token', { token }, 'POST', { ignoreLoadingBar: true });
  }

  newPassword (token, password) {
    return request('/user/new-password', { token, password }, 'POST', { ignoreLoadingBar: true });
  }

  completeSignUp () {
    $uibModal.open({
      component: 'completeSignUp',
      windowClass: 'complete-sign-up-modal login-modal',
      size: 'sm',
      backdrop: false,
      keyboard: false
    });
  }

  openLoginForm () {
    $uibModal.open({
      component: 'loginContainer',
      windowClass: 'login-modal',
      size: 'sm'
    });
  }

  openSignUpForm () {
    $uibModal.open({
      component: 'loginContainer',
      resolve: {
        state: () => 'signUp'
      },
      windowClass: 'login-modal',
      size: 'sm'
    });
  }
}

function getOrg (orgId) {
  return request('/org/' + orgId)
    .then((result) => {
      const org = _.get(result, 'data.data');
      return org;
    });
}

loginService.$inject = ['$rootScope', '$uibModal', 'request'];

const loginServiceModule = angular.module('loginService', [])
  .service('loginService', loginService)
  .name;

export default loginServiceModule;
