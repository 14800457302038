let $rootScope;
let $uibModal;
let request;

class propertyMistakeReportService {
  constructor (_$rootScope, _$uibModal, _request) {
    $rootScope = _$rootScope;
    $uibModal = _$uibModal;
    request = _request;
  }

  async openForm (property) {
    const user = $rootScope.user || await $uibModal.open({
      component: 'loginContainer',
      resolve: {
        loginTitle: () => 'לדווח על מודעה'
      },
      windowClass: 'login-modal',
      size: 'sm'
    })
      .result;

    if (!user) { return; }

    $uibModal.open({
      component: 'propertyMistakeReport',
      resolve: {
        property: () => property
      },
      windowClass: 'mistake-report-modal',
      size: 'md'
    });
  }

  sendReport (requestObject, propertyId) {
    return request('/property-report/' + propertyId, { report: requestObject })
      .then((res) => res.data.data);
  }
}

propertyMistakeReportService.$inject = ['$rootScope', '$uibModal', 'request'];

const propertyMistakeReportServiceModule = angular.module('propertyMistakeReportService', [])
  .service('propertyMistakeReportService', propertyMistakeReportService)
  .name;

export default propertyMistakeReportServiceModule;
