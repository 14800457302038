export default function (paymentsService) {
  let vm;

  class PaymentsTransactionsController {
    constructor () {
      vm = this;
      vm.orderByOptions = [
        { name: '-createdAt', label: 'תאריך' },
        { name: 'property.title', label: 'נכס' }
      ];
      vm.orderBy = '-createdAt'
    }

    $onInit () {
      this.getTransactionList()
        .then((results) => {
          vm.transactionList = results;
        });
    }

    getOrderByValue () {
      return _.get(vm.orderByOptions.find((item) => item.name === vm.orderBy), 'label');
    }

    getTransactionList () {
      return paymentsService.getTransactionList();
    }
  }

  return new PaymentsTransactionsController();
}
