import _ from 'lodash';

export default function ($scope, $rootScope, $state, sweetAlert, messagesService, utils, eventsService) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class MistakeReportCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.model = {};
      vm.property = vm.resolve.property;
      vm.propertyUser = vm.resolve.propertyUser;
      let user = $rootScope.user;
      vm.messageModel = `היי, הייתי רוצה לשמוע פרטים נוספים על הנכס ${vm.property.address ? 'ב' + vm.property.address : ''}. \n\n${user.firstName + ' ' + user.lastName}\n${user.phone} \nתודה!`;
    }

    sendReport () {
      utils.setFormFieldsDirty(vm.propertySendMessageForm);

      if (vm.propertySendMessageForm.$invalid) {
        $('#property-send-message-form')[0].reportValidity();
        return;
      }
      vm.sendProgress = true;

      return messagesService.sendPropertyMessage(vm.messageModel, vm.property.id, vm.propertyUser)
        .then(() => {
         
          window.dataLayer.push({ 'event': 'submit_form' });
          sweetAlert.swal('', 'ההודעה נשלחה למפרסם בהצלחה', 'success', {
            buttons: false,
            timer: 3000
          });
          vm.modalInstance.close();

          eventsService.create('propertySendMessageForm', vm.property.id);
        })
       
        .catch(() => {
          alert("חרגת מהמכסה! \n מצטערים לא הצלחנו לשלוח את ההודעה מפני שחרגת מהמכסה היומית, יש לנסות ולשלוח שוב מחר.");
        })
        .finally((e) => {
          vm.modalInstance.close();
          vm.sendProgress = false;
        });
    }

    cancelModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#property-send-message-form'))
        .bind('keydown keypress', (event) => {
          if (event.ctrlKey && event.which === KEY_EVENT_ENTER) {
            vm.sendReport();
            event.preventDefault();
          }
        });
    }
  }

  return new MistakeReportCtrl();
}
