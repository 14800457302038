export default function () {
  let vm;

  class PropertyNotAvailableController {
    constructor () {
      vm = this;
      vm.name = 'propertyNotAvailable';
    }
  }

  return new PropertyNotAvailableController();
}
