import template from './properties-map.html';
import controller from './properties-map.controller';
import './properties-map.scss';

const propertiesMapComponent = {
  bindings: {
    type: '<?',
    filters: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'propertyService', 'NgMap'];

export default propertiesMapComponent;
