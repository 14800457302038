import angular from 'angular';
import choosePlan from './choose-plan/choose-plan';
import messages from './messages/messages';
import myProperties from './my-properties/my-properties';
import payments from './payments/payments';
import profile from './profile/profile';
import savedProperties from './saved-properties/saved-properties';
import savedSearches from './saved-searches/saved-searches';
import events from './events/events';
import dashboardComponent from './dashboard.component';

const dashboardModule = angular.module('dashboard', [
  choosePlan,
  messages,
  myProperties,
  payments,
  profile,
  savedProperties,
  savedSearches,
  events
])
  .component('dashboard', dashboardComponent)
  .name;

export default dashboardModule;
