import angular from 'angular';

import template from './input-image.html';
import './input-image.scss';

const inputImageModule = angular.module('inputImage', [])
  .directive('inputImage', [function () {
    return {
      restrict: 'A',
      controllerAs: 'vm',
      bindToController: true,
      replace: true,
      scope: {
        model: '=?',
        imageUrl: '@',
        name: '@'
      },
      template,

      controller: ['$scope', '$timeout', '$uibModal', function ($scope, $timeout, $uibModal) {
        const vm = this;
        vm.$timeout = $timeout;
        vm.$uibModal = $uibModal;

        vm.$onInit = function () {
          vm.image = vm.model;
          if (!(vm.model instanceof File) && vm.imageUrl) {
            vm.$timeout(() => {
              $scope.$apply(() => {
                vm.image = vm.imageUrl;
              });
            });
          }
          $scope.$watch('vm.model', (newVal, oldVal) => {
            if (!vm.model) {
              vm.deleteImage();
            }
          });
        };
      }],

      link (scope, element, attrs, vm) {
        const inputElement = element.find('input');
        const progressElement = element.find('.input-image-loader');

        inputElement.on('change', (e) => {
          const files = inputElement[0].files;
          if (files && files[0]) {
            vm.addImage(files);
          } else {
            vm.deleteImage();
          }
        });

        vm.$onChanges = function (changes) {
          vm.image = vm.model;
          if (vm.model instanceof File) {
            inputElement[0].value = vm.model;
            inputElement.trigger('change');
          } else if (vm.imageUrl) {
            vm.$timeout(() => {
              scope.$apply(() => {
                vm.image = vm.imageUrl;
              });
            });
          }
        };

        vm.addImage = function (imageFileList) {
          vm.image = undefined;
          vm.model = imageFileList;

          const reader = new FileReader();
          reader.onload = function (e) {
            scope.$apply(() => {
              vm.image = e.target.result;
              $(progressElement).hide();
            });
          };
          $(progressElement).show();
          vm.$timeout(() => {
            reader.readAsDataURL(imageFileList[0]);
          });
        };

        vm.deleteImage = function () {
          vm.image = undefined;
          vm.model = null;

          inputElement[0].value = '';
          vm.$timeout(() => {
            scope.$apply(() => { });
          });
        };

        vm.zoomImage = function () {
          vm.$uibModal.open({
            template: `<img ng-src="${vm.image}"/>`,
            windowClass: 'input-image-zoom-view',
            size: 'lg'
          });
        };
      }
    };
  }])
  .name;

export default inputImageModule;
