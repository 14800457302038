import template from './manage-banners.html';
import controller from './manage-banners.controller';
import './manage-banners.scss';

const ManageBannersComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'bannerService', 'userService', 'utils', '$uibModal'];

export default ManageBannersComponent;
