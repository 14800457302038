import template from './manage-user-list.html';
import controller from './manage-user-list.controller';
import './manage-user-list.scss';

const ManageUserListComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'manageService', 'utils', '$uibModal'];

export default ManageUserListComponent;
