import angular from 'angular';
import propertyMistakeReportComponent from './property-mistake-report.component';
import propertyMistakeReportService from './property-mistake-report.service';

const propertyMistakeReportModule = angular.module('propertyMistakeReport', [
  propertyMistakeReportService
])
  .component('propertyMistakeReport', propertyMistakeReportComponent)
  .name;

export default propertyMistakeReportModule;
